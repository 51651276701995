<template>
    <activix-select
        :autosize="true"
        :options="divisions"
        :placeholder="$t('multiselect.selectOption')"
        :value="value"
        @input="$emit('input', $event)"
    >
        <el-option
            :value="division.value"
            :label="division.label"
            :key="division.value"
            v-for="division in divisions"
        />
    </activix-select>
</template>

<script>
    import Division from '@/entities/Division.js';

    export default {
        props: {
            value: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                divisions: [
                    {
                        value: Division.NEW,
                        label: this.$t('divisions.new'),
                    },
                    {
                        value: Division.USED,
                        label: this.$t('divisions.used'),
                    },
                    {
                        value: Division.SERVICE,
                        label: this.$t('divisions.service'),
                    },
                ],
            };
        },
    };
</script>
