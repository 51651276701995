<template>
    <activix-modal
        size="sm"
        :opened="opened"
        portal="modal-2"
        @close="close"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('accounts.edit.facebookMessenger') }}
            </h4>
        </template>
        <template slot="body">
            <p class="text-center mb-4">
                {{ $t('accounts.edit.selectFabebookPages') }} {{ contextAccount.name }} ?
            </p>
            <div class="text-center mt-1" :key="page.id" v-for="page in pages">
                <activix-radio name="facebook_page_id" :value="page.id" v-model="pageId">
                    {{ page.name }}
                </activix-radio>
            </div>
        </template>
        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.close') }}
            </activix-button>
            <activix-button type="primary" :disabled="!selectedPage" @click="save">
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapState } from 'pinia';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            pages: {
                required: true,
            },
        },

        data() {
            return {
                pageId: '',
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            selectedPage() {
                return this.pages.find(page => {
                    return page.id == this.pageId;
                });
            },
        },

        methods: {
            close() {
                this.$emit('update:opened', false);
            },

            save() {
                this.$emit('save', this.selectedPage);
                this.close();
            },
        },
    };
</script>
