<template>
    <div class="box">
        <div class="box-header flex items-center justify-between">
            <h4 class="box-title">
                {{ $t('accounts.edit.businessHours') }}
            </h4>
            <select-business-hour-division v-model="divisionId" />
        </div>
        <div class="box-body | p-0">
            <table class="table table-bordered | border-t-2">
                <thead>
                    <tr>
                        <th>{{ $t('accounts.edit.day') }}</th>
                        <th class="text-center">
                            {{ $t('accounts.edit.open') }}
                        </th>
                        <th class="text-center">
                            {{ $t('accounts.edit.opening') }}
                        </th>
                        <th class="text-center">
                            {{ $t('accounts.edit.closing') }}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        is="business-hours-row"
                        :weekday-hours="weekdayHours"
                        :index="index"
                        :division-id="divisionId"
                        :key="index"
                        v-for="(weekdayHours, index) in businessHours"
                    />
                </tbody>
            </table>

            <hr class="mt-6" />

            <div class="row p-6">
                <div class="col-xs-6">
                    <label class="flex items-center">
                        {{ $t('accounts.edit.businessHoursDivision') }}
                        <activix-tooltip :content="$t('accounts.edit.businessHoursDivisionTooltip')">
                            <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                        </activix-tooltip>
                    </label>

                    <activix-select
                        :options="businessHoursDivisions"
                        :placeholder="$t('multiselect.selectOption')"
                        :value="currentForm.business_hours_division_id"
                        @change="updateDefaultDivision"
                    >
                        <el-option
                            :value="division.id"
                            :label="division.label"
                            :key="division.id"
                            v-for="division in businessHoursDivisions"
                        />
                    </activix-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Division from '@/entities/Division.js';
    import BusinessHoursRow from '@/components/accounts/BusinessHoursRow.vue';
    import SelectBusinessHourDivision from '@/components/accounts/SelectBusinessHourDivision.vue';
    import { mapState } from 'pinia';
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';

    export default {
        components: {
            BusinessHoursRow,
            SelectBusinessHourDivision,
        },

        data: () => ({
            divisionId: Division.NEW,
        }),

        computed: {
            ...mapState(useAccountCardStore, {
                currentForm: 'currentForm',
                businessHours: store => store.currentForm.business_hours,
            }),

            businessHoursDivisions() {
                return Division.selectOptions({ service: true });
            },
        },

        methods: {
            updateDefaultDivision(newValue) {
                this.updateAccountProperties({ business_hours_division_id: newValue });
            },
        },
    };
</script>
