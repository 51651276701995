<template>
    <activix-modal
        size="xs"
        :opened="opened"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('accounts.edit.activationDate') }}
            </h4>
        </template>

        <template slot="body">
            <date-time-picker :date-only="true" v-model="date" />
        </template>

        <template slot="footer">
            <activix-button @click="closeWithoutSubmitting">
                {{ $t('general.cancel') }}
            </activix-button>
            <activix-button type="primary" :disabled="!isValid" @click="submitted = true">
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import DateTimePicker from '../inputs/DateTimePicker.vue';
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';

    export default {
        components: {
            DateTimePicker,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                submitted: false,
                date: '',
            };
        },

        computed: {
            ...mapState(useAccountCardStore, {
                activationDate: store => store.currentForm.activation_date,
            }),

            isValid() {
                return !!this.date;
            },
        },

        watch: {
            activationDate() {
                this.date = this.activationDate;
            },

            submitted(value) {
                if (value) {
                    this.close();
                }
            },
        },

        methods: {
            ...mapActions(useAccountCardStore, ['updateAccountProperties']),

            closeWithoutSubmitting() {
                this.submitted = false;
                this.close();
            },

            close() {
                this.$emit('update:opened', false);
            },

            onClosed() {
                if (this.submitted) {
                    this.updateAccountProperties({ activation_date: this.date });
                }

                this.submitted = false;
            },
        },

        async mounted() {
            await this.$nextTick();
            this.date = this.activationDate;
        },
    };
</script>
