<template>
    <component
        :is="component"
        identifier="id"
        label="label"
        :name="field.field"
        :disabled="disabled"
        :options="fieldSelectPickerOptions"
        :selected="castedValue"
        :value="castedValue"
        :multiple="true"
        :allow-empty="true"
        :start-view="2"
        :searchable="true"
        :taggable="true"
        :limit-selected-visible="1"
        :mask-type="maskType"
        :select-all="true"
        :maxlength="maxlength"
        :type="field.type"
        v-on="inputListeners"
    />
</template>

<script>
    import { parseCustomField } from '../value-objects/CustomField.js';

    import ActivixInput from './elements/ActivixInput.vue';
    import ActivixTextarea from './elements/ActivixTextarea.vue';
    import ActivixMaskedInput from './elements/ActivixMaskedInput.vue';
    import ActivixMultiselect from './elements/ActivixMultiselect.vue';
    import ActivixCheckbox from './elements/ActivixCheckbox.vue';
    import DateTimePicker from './inputs/DateTimePicker.vue';

    export default {
        components: {
            ActivixInput,
            ActivixTextarea,
            ActivixMaskedInput,
            ActivixMultiselect,
            ActivixCheckbox,
            DateTimePicker,
        },

        props: {
            field: {
                type: Object,
                required: true,
            },
            value: {
                type: [Array, Boolean],
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            inputListeners() {
                return {
                    ...this.$listeners,
                    ...{
                        input: this.onInput,
                        update: this.onUpdateSelect,
                    },
                };
            },

            component() {
                switch (this.field.type) {
                    case 'string':
                    case 'url':
                        return 'activix-input';
                    case 'array':
                        return 'activix-multiselect';
                    case 'currency':
                        return 'activix-masked-input';
                    case 'datetime':
                        return 'date-time-picker';
                    case 'boolean':
                        return 'activix-checkbox';
                    case 'textarea':
                        return 'activix-textarea';
                }

                return '';
            },

            maxlength() {
                if (this.field.type == 'textarea') {
                    return false;
                }

                return 255;
            },

            maskType() {
                if (this.field.type == 'currency') {
                    return 'money';
                }

                return '';
            },

            castedValue() {
                return parseCustomField.toEdit(this.value, this.field.type);
            },

            fieldSelectPickerOptions() {
                return this.field.select_picker_options
                    .map(item => {
                        return {
                            id: item,
                            label: item,
                        };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label));
            },
        },

        methods: {
            onInput(value) {
                this.$emit('input', parseCustomField.toSave(value, this.field.type));
            },

            onUpdateSelect(filter) {
                const value = filter.filter(f => f.value != 'all').map(f => f.id);
                this.$emit('input', value);
            },
        },
    };
</script>
