<template>
    <activix-modal
        size="sm"
        :closable="false"
        :opened="opened"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                <span>{{ $t('accounts.edit.renewalOptions.equityBaseOptions') }}</span>
            </h4>
        </template>

        <template slot="body">
            <div class="flex items-center">
                <label class="w-24 mb-0">
                    {{ $t('clientCard.vehicles.make') }}
                </label>
                <div class="flex-1 px-6">
                    <activix-multiselect
                        multiple
                        select-all
                        label="label"
                        identifier="value"
                        :searchable="false"
                        :options="filteredMakes"
                        :placeholder="$t('multiselect.selectOption')"
                        :selected="selectedMakes"
                        @update="onUpdateMakes"
                    />
                </div>
            </div>

            <div class="flex items-center mt-6">
                <label class="w-24 mb-0">
                    {{ $t('clientCard.vehicles.model') }}
                </label>
                <div class="flex-1 px-6">
                    <activix-tooltip :content="isModelsDisabled ? $t('accounts.edit.renewalOptions.tooltipModelsDisabled') : ''">
                        <activix-multiselect
                            multiple
                            clearable
                            select-all
                            label="label"
                            identifier="value"
                            :searchable="false"
                            :options="filteredModels"
                            :placeholder="$t('multiselect.selectOption')"
                            :selected="selectedModels"
                            :disabled="isModelsDisabled"
                            @update="onUpdateModels"
                        />
                    </activix-tooltip>
                </div>
            </div>
            <div class="flex items-center mt-6">
                <label class="w-24 mb-0">
                    {{ $t('accounts.edit.renewalOptions.year') }}
                </label>
                <div class="flex flex-1 flex-row px-6">
                    <activix-multiselect
                        class="pr-3"
                        label="label"
                        identifier="value"
                        :searchable="false"
                        :options="operators"
                        :placeholder="$t('multiselect.selectOption')"
                        :selected="selectedOperator"
                        @update="onUpdateOperator"
                    />
                    <activix-multiselect
                        label="label"
                        identifier="value"
                        :searchable="false"
                        :options="yearsOptions"
                        :placeholder="$t('multiselect.selectOption')"
                        :selected="selectedYear"
                        @update="onUpdateYear"
                    />
                </div>
            </div>

            <div class="flex items-center mt-6">
                <label class="w-24 mb-0">
                    {{ $t('accounts.edit.renewalOptions.equityBaseCondition') }}
                </label>
                <div class="flex-1 px-6">
                    <activix-multiselect
                        label="label"
                        identifier="value"
                        :searchable="false"
                        :options="conditions"
                        :placeholder="$t('multiselect.selectOption')"
                        :selected="selectedCondition"
                        @update="onUpdateCondition"
                    />
                </div>
            </div>
        </template>

        <template slot="footer">
            <div class="flex justify-center">
                <activix-button @click="cancel">
                    {{ $t('general.cancel') }}
                </activix-button>
                <activix-tooltip :content="disabled ? $t('accounts.edit.renewalOptions.tooltipSaveButtonDisabled') : ''">
                    <activix-button
                        class="ml-2"
                        type="primary"
                        :disabled="disabled"
                        @click="save"
                    >
                        {{ $t('general.save') }}
                    </activix-button>
                </activix-tooltip>
            </div>
        </template>
    </activix-modal>
</template>

<script>

    import { mapActions, mapState, mapWritableState } from 'pinia';
    import range from 'lodash/range';
    import { useGlobalStore } from '@/store/store';
    import { useClientCardStore } from '@/store/modules/clientCard/store';
    import LeadVehicleCondition from '@/entities/LeadVehicleCondition';
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';
    import { sort } from '../../utils/index.js';
    import Moment from '../../value-objects/Moment.js';

    export default {

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            setting: {
                type: Object,
                required: false,
                default: () => {
                    return {};
                },
            },
        },

        data() {
            const accountCardStore = mapState(useAccountCardStore, ['currentForm']);

            return {
                vehicleModels: [],
                operators: [
                    {
                        value: '=',
                        label: this.$t('accounts.edit.renewalOptions.operators.equals'),
                    },
                    {
                        value: '<',
                        label: this.$t('accounts.edit.renewalOptions.operators.lessThan'),
                    },
                    {
                        value: '>',
                        label: this.$t('accounts.edit.renewalOptions.operators.moreThan'),
                    },
                ],
                conditions: LeadVehicleCondition.selectOptions('value', 'label'),
                makes: [],
                models: [],
                year: null,
                operator: '=',
                condition: accountCardStore.currentForm().renewal_alert_options.equity_base,
            };
        },

        computed: {
            ...mapWritableState(useClientCardStore, ['vehicleMakes']),
            ...mapState(useAccountCardStore, {
                currentForm: 'currentForm',
            }),
            ...mapState(useGlobalStore, ['configs']),

            defaultCondition() {
                return this.currentForm.renewal_alert_options.equity_base;
            },

            filteredMakes() {
                return this.vehicleMakes.map(make => {
                    return {
                        value: make.name,
                        label: make.name,
                    };
                });
            },

            selectedMakes() {
                return this.makes.map(make => {
                    return {
                        value: make,
                        label: make,
                    };
                });
            },

            selectedModels() {
                return this.models.map(model => {
                    return {
                        value: model,
                        label: model,
                    };
                });
            },

            selectedYear() {
                return {
                    value: this.year,
                    label: this.year,
                };
            },

            selectedOperator() {
                return {
                    value: this.operator,
                    label: this.operator,
                };
            },

            selectedCondition() {
                return {
                    value: this.condition,
                    label: this.condition,
                };
            },

            filteredModels() {
                const displayLocal = this.configs.locale == 'fr' ? 'display_name_fr' : 'display_name_en';
                const models = this.vehicleModels.map(model => {
                    const modelName = model[displayLocal] || model.name;
                    return {
                        value: modelName,
                        label: modelName,
                    };
                });
                return sort(models, 'label');
            },

            yearsOptions() {
                const fromYear = 1899; // fromYear is exclusive
                const toYear = Moment.now().year() + 3;
                const years = range(toYear, fromYear, -1);

                return years.map(year => {
                    return {
                        value: year,
                        label: year.toString(),
                    };
                });
            },

            disabled() {
                return this.makes.length == 0 && !this.year;
            },

            isModelsDisabled() {
                return this.makes.length != 1;
            },
        },

        watch: {
            makes(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }
                if (this.makes.length == 1) {
                    this.triggerFetchVehicleModels();
                }
            },
            setting: {
                deep: true,
                handler() {
                    this.makes = this.getMakes(this.setting.criterion);
                    this.models = this.getModels(this.setting.criterion);
                    this.year = this.getYear(this.setting.criterion);
                    this.operator = this.getOperator(this.setting.criterion);
                    this.condition = this.setting.condition;
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, [
                'fetchVehicleModels',
            ]),
            ...mapActions(useClientCardStore, ['setVehicleMakes']),

            async triggerFetchVehicleModels() {
                const selectedMakeId = this.vehicleMakes.filter(make => this.makes.includes(make.name)).map(make => make.id);
                this.vehicleModels = await this.fetchVehicleModels(selectedMakeId[0]);
            },

            onUpdateMakes(makes) {
                this.makes = makes.map(make => make.value);

                if (this.isModelsDisabled) {
                    this.models = [];
                }
            },

            onUpdateModels(models) {
                this.models = this.isModelsDisabled ? [] : models.map(model => model.value);
            },

            onUpdateYear(year) {
                this.year = year.value;
            },

            onUpdateOperator(operator) {
                this.operator = operator.value;
            },

            onUpdateCondition(condition) {
                this.condition = condition.value;
            },

            close() {
                this.makes = [];
                this.models = [];
                this.year = null;
                this.operator = '=';
                this.condition = this.defaultCondition;

                this.$emit('update:opened', false);
            },

            onClosed() {
                this.$emit('closed');
            },

            cancel() {
                this.close();
            },

            async save() {
                const setting = {
                    criterion: [],
                    condition: this.condition,
                };

                if (this.makes.length) {
                    setting.criterion.push({
                        property: 'exchangeVehicles.make',
                        operator: '=',
                        value: this.makes,
                    });
                }

                if (this.models.length) {
                    setting.criterion.push({
                        property: 'exchangeVehicles.model',
                        operator: '=',
                        value: this.models,
                    });
                }

                if (this.year) {
                    setting.criterion.push({
                        property: 'exchangeVehicles.year',
                        operator: this.operator,
                        value: [this.year],
                    });
                }

                this.$emit('saved', setting);

                this.close();
            },

            getMakes(criterion) {
                const criteria = criterion.find(criteria => criteria.property == 'exchangeVehicles.make');

                return criteria?.value ?? [];
            },

            getModels(criterion) {
                const criteria = criterion.find(criteria => criteria.property == 'exchangeVehicles.model');

                return criteria?.value ?? [];
            },

            getYear(criterion) {
                const criteria = criterion.find(criteria => criteria.property == 'exchangeVehicles.year');

                return criteria?.value ? criteria.value[0] : null;
            },

            getOperator(criterion) {
                const criteria = criterion.find(criteria => criteria.property == 'exchangeVehicles.year');

                return criteria?.operator ?? null;
            },

        },
        mounted() {
            this.setVehicleMakes();
        },
    };
</script>
