<template>
    <div class="flex space-x-4">
        <activix-input
            class="flex-1"
            :value="domain"
            placeholder="example.com"
            @keydown.enter="$emit('add')"
            @input="$emit('update', $event)"
        />
        <button class="link-grey-light | hover:text-red-500" @click="$emit('delete')">
            <icon :name="$icons.trash" />
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            domain: {
                type: String,
                default: '',
            },
        },
    };
</script>
