import { set } from 'lodash-es';

export default {
    updateAccountProperties(properties) {
        Object.keys(properties).forEach(path => {
            set(this.currentForm, path, properties[path]);
        });
    },
    updateAccountBusinessHours(index, value) {
        Object.assign(this.currentForm.business_hours[index], value);
    },
};
