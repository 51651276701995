<template>
    <div>
        <div class="flex | xl:mb-6">
            <business-hours class="w-full lg:w-1/2 mr-6" />
            <div class="box | w-full lg:w-1/2">
                <div class="box-header | border-b">
                    <h4 class="box-title">
                        {{ $t('accounts.edit.generalOptions') }}
                    </h4>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col-xs-6 | -mb-4">
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.vehicle_text"
                                    @input="updateAccountProperties({ vehicle_text: $event })"
                                >
                                    {{ $t('accounts.edit.makeModelText') }}
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.vehicle_model_text"
                                    @input="updateAccountProperties({ vehicle_model_text: $event })"
                                >
                                    {{ $t('accounts.edit.onlyModelText') }}
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.assigned_lead"
                                    @input="updateAccountProperties({ assigned_lead: $event })"
                                >
                                    {{ $t('accounts.edit.assignedLead') }}
                                    <activix-tooltip :content="$t('accounts.edit.assignedLeadDesc')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.leads_other_division"
                                    @input="
                                        updateAccountProperties({ leads_other_division: $event })
                                    "
                                >
                                    {{ $t('accounts.edit.leadsOtherDivision') }}
                                    <activix-tooltip :content="$t('accounts.edit.leadsOtherDivisionDesc')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.access_all_leads_communications"
                                    @input="updateAccountProperties({ access_all_leads_communications: $event })"
                                >
                                    {{ $t('accounts.edit.access_all_leads_communications') }}
                                    <activix-tooltip :content="$t('accounts.edit.access_all_leads_communications_tool_tip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.manually_status"
                                    @input="updateAccountProperties({ manually_status: $event })"
                                >
                                    {{ $t('accounts.edit.manuallyStatus') }}
                                    <activix-tooltip :content="$t('accounts.edit.manuallyStatusDesc')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.limited_audio_access"
                                    @input="
                                        updateAccountProperties({ limited_audio_access: $event })
                                    "
                                >
                                    {{ $t('accounts.edit.limitedAudioAccess') }}
                                    <activix-tooltip :content="$t('accounts.edit.limitedAudioAccessDesc')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.restricted_notifications"
                                    :disabled="!authUser.isAdmin()"
                                    @input="
                                        updateAccountProperties({
                                            restricted_notifications: $event,
                                        })
                                    "
                                >
                                    {{ $t('accounts.edit.restrictedNotifications') }}
                                    <activix-tooltip :content="$t('accounts.edit.restrictedNotificationsTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.stock_required_for_sale"
                                    @input="
                                        updateAccountProperties({
                                            stock_required_for_sale: $event,
                                        })
                                    "
                                >
                                    {{ stockRequiredOptionTitle }}
                                    <activix-tooltip :content="stockRequiredTooltip">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                        </div>
                        <div class="col-xs-6 | -mb-4">
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.take_over_director"
                                    :disabled="authUser.isAdvisor()"
                                    @input="updateAccountProperties({ take_over_director: $event })"
                                >
                                    {{ $t('accounts.edit.takeOverDirector') }}
                                    <activix-tooltip :content="$t('accounts.edit.takeOverDirectorTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.unrestricted_assignment"
                                    @input="
                                        updateAccountProperties({
                                            unrestricted_assignment: $event,
                                        })
                                    "
                                >
                                    {{ $t('accounts.edit.unrestrictedAssignment') }}
                                    <activix-tooltip :content="$t('accounts.edit.unrestrictedAssignmentTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.sale_by_phone"
                                    @input="updateAccountProperties({ sale_by_phone: $event })"
                                >
                                    {{ $t('accounts.edit.saleByPhone') }}
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.verified_sale"
                                    @input="updateAccountProperties({ verified_sale: $event })"
                                >
                                    {{ $t('accounts.edit.verifiedSale') }}
                                    <activix-tooltip :content="$t('accounts.edit.verifiedSaleTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.mandatory_coordinate"
                                    @input="
                                        updateAccountProperties({
                                            mandatory_coordinate: $event,
                                        })
                                    "
                                >
                                    {{ $t('accounts.edit.mandatoryCoordinate') }}
                                    <activix-tooltip :content="$t('accounts.edit.mandatoryCoordinateTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.auto_assign_associate"
                                    @input="
                                        updateAccountProperties({ auto_assign_associate: $event })
                                    "
                                >
                                    {{ $t('accounts.edit.autoAssignAssociate') }}
                                    <activix-tooltip :content="$t('accounts.edit.autoAssignAssociateTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.display_approved_sources"
                                    @input="
                                        updateAccountProperties({ display_approved_sources: $event })
                                    "
                                >
                                    {{ $t('accounts.edit.displayApprovedSources') }}
                                    <activix-tooltip :content="$t('accounts.edit.displayApprovedSourcesTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.disable_manual_duplicate"
                                    @input="updateAccountProperties({ disable_manual_duplicate: $event })"
                                >
                                    {{ $t('accounts.edit.disabledManualDuplicate') }}
                                    <activix-tooltip :content="$t('accounts.edit.disabledManualDuplicateTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>

                            <div v-if="hasVehicleExport">
                                <activix-switcher
                                    class="mb-4"
                                    :value="editVAutoUsername"
                                    @input="displayVAutoFallbackUsername"
                                >
                                    {{ $t('accounts.edit.vAutoFallback') }}
                                    <activix-tooltip :content="$t('accounts.edit.vAutoFallbackTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>

                                <div class="ml-12 mb-4" v-if="editVAutoUsername">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t('accounts.edit.vAutoFallbackPlaceHolder')"
                                        :value="currentForm.vauto_fallback_email"
                                        @blur="
                                            updateAccountProperties({
                                                vauto_fallback_email: $event.target.value,
                                            })
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="my-8" />

                    <div class="row">
                        <div class="col-xs-6">
                            <label class="flex items-center">
                                {{ $t('accounts.edit.mergeRule') }}
                                <activix-tooltip :content="$t('accounts.edit.mandatoryCoordinateTooltip')">
                                    <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                </activix-tooltip>
                            </label>

                            <activix-multiselect
                                label="value"
                                :searchable="false"
                                :allow-empty="false"
                                :selected="{
                                    id: currentForm.merge_rule,
                                    value: $t(`accounts.edit.${currentForm.merge_rule}`),
                                }"
                                :options="[
                                    { id: 'fill', value: $t('accounts.edit.fill') },
                                    { id: 'overwrite', value: $t('accounts.edit.overwrite') },
                                ]"
                                @update="updateMergeRule"
                            />
                        </div>

                        <div class="col-xs-6" v-if="!empty(currentForm.inbound_suppliers)">
                            <label class="flex items-center">
                                {{ $t('accounts.edit.importRule') }}
                                <activix-tooltip :content="$t('accounts.edit.importRuleTooltip')">
                                    <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                </activix-tooltip>
                            </label>

                            <activix-multiselect
                                label="value"
                                :allow-empty="false"
                                :searchable="false"
                                :selected="{
                                    id: currentForm.import_rule,
                                    value: $t(`accounts.edit.${currentForm.import_rule}`),
                                }"
                                :options="[
                                    { id: 'fill', value: $t('accounts.edit.fill') },
                                    { id: 'overwrite', value: $t('accounts.edit.overwrite') },
                                ]"
                                @update="updateImportRule"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex | mb-6">
            <div class="flex flex-col w-full">
                <div class="box | flex-1">
                    <div class="box-header | border-b">
                        <h3 class="box-title text-primary">
                            {{ $t('accounts.edit.calendarOptions') }}
                        </h3>
                    </div>
                    <div class="box-body">
                        <div class="row">
                            <div class="col-xs-4">
                                <div class="flex flex-col">
                                    <label>
                                        {{ $t('accounts.edit.confirmationAppt') }}
                                        <activix-tooltip :content="$t('accounts.edit.confirmationApptTooltip')">
                                            <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                        </activix-tooltip>
                                    </label>
                                    <div class="flex">
                                        <activix-switcher
                                            :value="currentForm.confirmation_appt_sale"
                                            :disabled="!authUser.isAdmin() || !currentForm.niotext"
                                            @input="
                                                updateAccountProperties({
                                                    confirmation_appt_sale: $event,
                                                })
                                            "
                                        >
                                            {{ $t('accounts.edit.sale') }}
                                        </activix-switcher>
                                        <activix-switcher
                                            class="ml-6"
                                            :value="currentForm.confirmation_appt_service"
                                            :disabled="!authUser.isAdmin() || !currentForm.niotext"
                                            @input="onUpdateApptConfirmationService"
                                        >
                                            {{ $t('accounts.edit.service') }}
                                        </activix-switcher>
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <label>{{ $t('accounts.edit.deliveryTimeframe') }}</label>
                                    <activix-multiselect
                                        class="w-full"
                                        label="value"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :selected="selectedDeliveryTimeframe"
                                        :options="deliveryTimeframeOptions"
                                        @update="updateDeliveryTimeframe"
                                    />
                                </div>

                                <template v-if="authUser.isAdmin() || authUser.isDirector()">
                                    <div class="mt-4">
                                        <label>
                                            {{ $t('accounts.edit.defaultDeliveryman') }}
                                            <activix-tooltip :content="$t('accounts.edit.defaultDeliverymanTooltip')">
                                                <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                            </activix-tooltip>
                                        </label>

                                        <activix-multiselect
                                            label="value"
                                            :searchable="true"
                                            :clearable="true"
                                            :selected="{ id: currentForm.default_deliveryman_user_id }"
                                            :options="formatedActiveUsers"
                                            @update="updateDefaultDeliverymanUserId"
                                        />
                                    </div>
                                    <div class="mt-4">
                                        <label>
                                            {{ $t('accounts.edit.deliveryGuestGroup') }}
                                            <activix-tooltip :content="$t('accounts.edit.deliveryGuestGroupTooltip')">
                                                <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                            </activix-tooltip>
                                        </label>

                                        <activix-multiselect
                                            label="value"
                                            searchable
                                            clearable
                                            :selected="{ id: currentForm.delivery_guest_group_id }"
                                            :options="formattedGuestGroups"
                                            @update="updateDeliveryGuestGroupId"
                                        />
                                    </div>
                                </template>
                            </div>
                            <div class="col-xs-4">
                                <div>
                                    <label>
                                        {{ $t('accounts.edit.csi') }} ({{ $t('accounts.edit.csiDelaysOption') }})
                                        <activix-tooltip :content="csiTooltip" v-if="csiTooltip">
                                            <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                        </activix-tooltip>
                                    </label>
                                </div>
                                <div class="flex">
                                    <div class="w-1/2 pr-1">
                                        <activix-switcher
                                            :value="currentForm.csi"
                                            @input="updateAccountProperties({ csi: $event })"
                                        >
                                            {{ $t('accounts.edit.csiNew') }}
                                        </activix-switcher>

                                        <activix-multiselect
                                            identifier="value"
                                            label="name"
                                            multiple="multiple"
                                            class="mt-2"
                                            :selected="selectedCsiTaskDelays"
                                            :options="csiDelayOptions"
                                            :searchable="false"
                                            :allow-empty="false"
                                            :taggable="true"
                                            @update="updateCsiTaskDelays"
                                            v-if="currentForm.csi"
                                        />
                                    </div>

                                    <div class="w-1/2 pl-1">
                                        <activix-switcher
                                            :value="currentForm.csi_used"
                                            @input="updateAccountProperties({ csi_used: $event })"
                                        >
                                            {{ $t('accounts.edit.csiUsed') }}
                                        </activix-switcher>

                                        <activix-multiselect
                                            identifier="value"
                                            label="name"
                                            multiple="multiple"
                                            class="mt-2"
                                            :selected="selectedCsiUsedTaskDelays"
                                            :options="csiUsedDelayOptions"
                                            :searchable="false"
                                            :allow-empty="false"
                                            :taggable="true"
                                            @update="updateCsiUsedTaskDelays"
                                            v-if="currentForm.csi_used"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-4">
                                <div>
                                    <activix-switcher
                                        :value="currentForm.delivery_guest_advisor"
                                        @input="
                                            updateAccountProperties({
                                                delivery_guest_advisor: $event,
                                            })
                                        "
                                    >
                                        {{ $t('accounts.edit.deliveryGuestAdvisor') }}
                                        <activix-tooltip :content="$t('accounts.edit.deliveryGuestAdvisorTooltip')">
                                            <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                        </activix-tooltip>
                                    </activix-switcher>
                                </div>
                                <div class="mt-2">
                                    <label class="flex items-center">
                                        {{ $t('accounts.edit.guestGroup.title') }}
                                        <activix-tooltip
                                            :content="
                                                canCreateGuestGroup
                                                    ? $t('accounts.edit.guestGroup.add')
                                                    : $t('accounts.edit.guestGroup.cannotAdd')
                                            "
                                        >
                                            <icon
                                                name="bold/add"
                                                class="link-grey-light text-sm | ml-2"
                                                :class="{ disabled: !canCreateGuestGroup }"
                                                @click="addGuestGroup"
                                            />
                                        </activix-tooltip>
                                    </label>
                                    <div
                                        class="flex items-center justify-between border border-grey-150 rounded px-4 py-2 group"
                                        :class="{ 'border-b-0': index < contextAccount.guest_groups.length - 1 }"
                                        :key="item.id"
                                        v-for="(item, index) in contextAccount.guest_groups"
                                    >
                                        <div>{{ item.name }}</div>
                                        <div
                                            class="transition-opacity duration-150 opacity-0 | group-hover:opacity-100"
                                        >
                                            <icon
                                                name="regular/pencil-1"
                                                class="link-grey-light text-sm"
                                                @click="editGuestGroup(item.id)"
                                            />
                                            <icon
                                                :name="$icons.trash"
                                                class="link-grey-light text-sm ml-2 | hover:text-red-500"
                                                @click="onDeleteGuestGroup(item.id)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex | mb-6">
            <div class="box | mr-3">
                <div class="box-header | border-b">
                    <h4 class="flex justify-between | box-title">
                        <span>{{ $t('accounts.edit.renewalOptions.title') }}</span>
                    </h4>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col-xs-6 ">
                            <div>
                                <activix-switcher
                                    class="mb-2"
                                    :disabled-tooltip="$t('accounts.edit.renewalRequired')"
                                    :value="renewalAlertEndLocationSelected.length > 0"
                                    :disabled="!currentForm.renewal"
                                    @input="toggleLocationAlert"
                                >
                                    {{ $t('accounts.edit.renewalOptions.endLocation') }}
                                    <activix-tooltip :content="$t('accounts.edit.renewalOptions.tooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                                <activix-tooltip :content="renewalAlertLocationTooltip">
                                    <activix-multiselect
                                        identifier="value"
                                        label="name"
                                        multiple="multiple"
                                        :selected="renewalAlertEndLocationSelected"
                                        :options="renewalAlertNotificationsOptions"
                                        :searchable="false"
                                        :allow-empty="true"
                                        :taggable="true"
                                        :disabled="
                                            !currentForm.renewal || renewalAlertLocationRequiredFields.length != 0
                                        "
                                        @update="
                                            updateRenewalAlertProperty(
                                                'end_location_notifications',
                                                $event.map(delay => delay.value),
                                            )
                                        "
                                    />
                                </activix-tooltip>
                            </div>
                        </div>
                        <div class="col-xs-6">
                            <div>
                                <activix-switcher
                                    class="mb-2"
                                    :disabled-tooltip="$t('accounts.edit.renewalRequired')"
                                    :value="renewalAlertEndWarrantySelected.length > 0"
                                    :disabled="!currentForm.renewal"
                                    @input="toggleWarrantyAlert"
                                >
                                    {{ $t('accounts.edit.renewalOptions.endWarranty') }}
                                    <activix-tooltip :content="$t('accounts.edit.renewalOptions.tooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>

                                <activix-tooltip :content="renewalAlertWarrantyTooltip">
                                    <activix-multiselect
                                        identifier="value"
                                        label="name"
                                        multiple="multiple"
                                        :selected="renewalAlertEndWarrantySelected"
                                        :options="renewalAlertNotificationsOptions"
                                        :searchable="false"
                                        :allow-empty="true"
                                        :taggable="true"
                                        :disabled="
                                            !currentForm.renewal || renewalAlertWarrantyRequiredFields.length != 0
                                        "
                                        @update="
                                            updateRenewalAlertProperty(
                                                'end_warranty_notifications',
                                                $event.map(delay => delay.value),
                                            )
                                        "
                                    />
                                </activix-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-xs-6">
                            <activix-switcher
                                class="mb-2"
                                :disabled-tooltip="$t('accounts.edit.renewalRequired')"
                                :value="currentForm.auto_renewal"
                                :disabled="!currentForm.renewal"
                                @input="updateAccountProperties({ auto_renewal: $event })"
                            >
                                {{ $t('accounts.edit.autoRenewal') }}
                                <activix-tooltip :content="$t('accounts.edit.autoRenewalDescription')">
                                    <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                </activix-tooltip>
                            </activix-switcher>

                            <div class="flex items-center ml-12 mb-4" v-if="currentForm.auto_renewal">
                                <activix-checkbox
                                    size="sm"
                                    class="mr-4"
                                    :value="currentForm.auto_renewal_new"
                                    :disabled="(!hasAutoRenewalUsed && hasAutoRenewalNew)"
                                    @input="
                                        updateAccountProperties({
                                            auto_renewal_new: $event,
                                        })
                                    "
                                >
                                    {{ $t('accounts.edit.new') }}
                                </activix-checkbox>

                                <activix-checkbox
                                    size="sm"
                                    :value="currentForm.auto_renewal_used"
                                    :disabled="!authUser.isProgrammer() || (!hasAutoRenewalNew && hasAutoRenewalUsed)"
                                    @input="
                                        updateAccountProperties({
                                            auto_renewal_used: $event,
                                        })
                                    "
                                >
                                    {{ $t('accounts.edit.used') }}
                                </activix-checkbox>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row mt-4">
                        <div class="col-xs-6">
                            <span>{{ $t('accounts.edit.renewalOptions.equityBaseOptions') }}</span>
                        </div>
                        <div class="col-xs-6 flex justify-end">
                            <activix-tooltip :content="equityBaseOptionTooltip">
                                <button
                                    :disabled="equityDisabled"
                                    @click="addRenewalCondition"
                                >
                                    <icon
                                        :disabled="equityDisabled"
                                        name="bold/add"
                                        class="link-grey-light text-sm | ml-2"
                                    />
                                </button>
                            </activix-tooltip>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-xs-6">
                            <activix-switcher
                                class="mb-2"
                                :disabled-tooltip="$t('accounts.edit.equityRequired')"
                                :value="!empty(currentForm.renewal_alert_options.equity_base)"
                                :disabled="equityDisabled"
                                @input="toggleEquityAlert"
                            >
                                {{ $t('accounts.edit.renewalOptions.equityBase') }}
                                <activix-tooltip
                                    :content="$t('accounts.edit.renewalOptions.equityBaseDescription')"
                                >
                                    <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                </activix-tooltip>
                            </activix-switcher>
                            <activix-tooltip :content="renewalAlertEquityTooltip">
                                <activix-multiselect
                                    label="value"
                                    :searchable="false"
                                    :allow-empty="true"
                                    :clearable="true"
                                    :selected="
                                        !empty(currentForm.renewal_alert_options.equity_base)
                                            ? {
                                                id: currentForm.renewal_alert_options.equity_base,
                                                value: $t(`accounts.edit.renewalOptions.equity.${currentForm.renewal_alert_options.equity_base}`),
                                            }
                                            : null
                                    "
                                    :options="renewalAlertEquityOptions"
                                    :disabled="equityDisabled || renewalAlertEquityRequiredFields.length !== 0"
                                    @update="updateRenewalAlertProperty('equity_base', $event ? $event.id : false)"
                                />
                            </activix-tooltip>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <renewal-settings-table
                            :renewal-alert-options="currentForm.renewal_alert_options.settings"
                            @save="onRenewalSettingSaved"
                            @delete="onRenewalSettingDelete"
                        />
                    </div>
                </div>
            </div>

            <div class="box | ml-3">
                <div class="box-header | border-b">
                    <h4 class="box-title">
                        {{ $t('accounts.edit.salesChartOptions') }}
                    </h4>
                </div>
                <div class="box-body">
                    <div class="flex row">
                        <div class="flex flex-col w-1/2 px-4 space-y-4">
                            <div>
                                <activix-switcher
                                    :value="saleTableOptions.accessory_column"
                                    :disabled="!hasSaleTable"
                                    @input="updateSaleOptionsItem('accessory_column', $event)"
                                >
                                    {{ $t('accounts.edit.tireAccessoriesColumn') }}
                                </activix-switcher>
                            </div>
                            <div v-if="currentForm.commercial">
                                <activix-switcher
                                    :value="saleTableOptions.commercial_column"
                                    :disabled="!hasSaleTable"
                                    @input="updateSaleOptionsItem('commercial_column', $event)"
                                >
                                    {{ $t('accounts.edit.commercialColumn') }}
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    :value="currentForm.auto_recorded_date"
                                    @input="updateAccountProperties({ auto_recorded_date: $event })"
                                >
                                    {{ $t('accounts.edit.autoRecordedDate') }}
                                    <activix-tooltip :content="$t('accounts.edit.autoRecordedDateTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    :value="currentForm.sale_table_badge_active"
                                    @input="
                                        updateAccountProperties({
                                            sale_table_badge_active: $event,
                                        })
                                    "
                                >
                                    {{ $t('accounts.edit.saleTableBadge') }}
                                    <activix-tooltip :content="$t('accounts.edit.saleTableBadgeTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                                <activix-multiselect
                                    class="mt-2"
                                    label="value"
                                    :searchable="false"
                                    :clearable="true"
                                    :selected="selectedSaleTableBadgeField"
                                    :options="saleTableBadgeOptions"
                                    @update="updateSaleTableBadgeField"
                                />
                            </div>
                            <div>
                                <div>
                                    {{ $t('accounts.edit.fiscalYear') }}
                                    <activix-tooltip :content="$t('accounts.edit.fiscalYearTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                                <date-time-picker
                                    class="mt-2"
                                    :date-only="true"
                                    :disabled="!hasSaleTable"
                                    :value="contextAccount.fiscal_year_date"
                                    @input="updateAccountProperties({ fiscal_year_date: $event })"
                                />
                            </div>
                        </div>
                        <div class="flex flex-col w-1/2 px-4 space-y-4">
                            <div>
                                <activix-switcher
                                    :value="saleTableOptions.division_grouped_total_column"
                                    :disabled="!hasSaleTable"
                                    @input="updateSaleOptionsItem('division_grouped_total_column', $event)"
                                >
                                    {{ $t('accounts.edit.divisionGroupedTotalColumn') }}
                                    <activix-tooltip :content="$t('accounts.edit.divisionGroupedTotalColumnTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    :value="saleTableOptions.hide_profit"
                                    :disabled="!hasSaleTable"
                                    @input="updateSaleOptionsItem('hide_profit', $event)"
                                >
                                    {{ $t('accounts.edit.confidentialProfit') }}
                                    <activix-tooltip :content="$t('accounts.edit.confidentialProfitTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                            </div>
                            <div>
                                <activix-switcher
                                    :value="currentForm.waiting_sale_option"
                                    :disabled="!hasSaleTable"
                                    @input="updateAccountProperties({ waiting_sale_option: $event })"
                                >
                                    {{ $t('accounts.edit.waitingSale') }}
                                    <activix-tooltip :content="$t('accounts.edit.waitingSaleTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-switcher>
                                <date-time-picker
                                    class="mt-2"
                                    :date-only="true"
                                    :disabled="!hasSaleTable"
                                    :start-date="currentForm.activation_date"
                                    :value="currentForm.waiting_sale_date"
                                    @input="updateAccountProperties({ waiting_sale_date: $event })"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex mb-6">
            <div class="box | mr-3">
                <div class="box-header | border-b">
                    <h4 class="box-title">
                        {{ $t('accounts.edit.leadXpressOptions') }}
                    </h4>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <activix-tooltip
                                :content="$t('accounts.edit.unassignUnansweredLeadTooltip')"
                            >
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.leadxpress_option.unassignUnansweredLead"
                                    :disabled="!hasLeadXpress"
                                    @input="updateUnassignUnansweredLead"
                                >
                                    {{ $t('accounts.edit.unassignUnansweredLead') }}
                                </activix-switcher>
                            </activix-tooltip>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-4">
                            <div class="form-group">
                                <label>{{ $t('accounts.edit.timeLimitToAnswer') }}</label>
                                <activix-multiselect
                                    label="name"
                                    identifier="value"
                                    :selected="selectedTimeLimitToAnswer"
                                    :options="timeLimitToAnswer"
                                    :searchable="false"
                                    :allow-empty="false"
                                    :disabled="!hasLeadXpress || !hasUnassignUnansweredLead"
                                    @update="updateTimeLimitToAnswer"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">
                            <activix-tooltip
                                :content="$t('accounts.edit.untreatedLeadTooltip')"
                            >
                                <activix-switcher
                                    class="mb-4"
                                    :value="currentForm.untreated_lead"
                                    :disabled="!hasLeadXpress"
                                    @input="updateAccountProperties({ untreated_lead: $event })"
                                >
                                    {{ $t('accounts.edit.untreatedLeadAlert') }}
                                </activix-switcher>
                            </activix-tooltip>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-4">
                            <div class="form-group">
                                <label>{{ $t('accounts.edit.frequency') }}</label>
                                <activix-multiselect
                                    label="name"
                                    identifier="value"
                                    :selected="selectedReminderFrequency"
                                    :options="leadxpressOptions.frequency"
                                    :searchable="false"
                                    :allow-empty="false"
                                    :disabled="!hasLeadXpress || !hasUntreatedLead"
                                    @update="updateFrequency"
                                />
                            </div>
                        </div>
                        <div class="col-xs-4">
                            <div class="form-group">
                                <label>{{ $t('accounts.edit.repetition') }}</label>
                                <activix-multiselect
                                    :options="leadxpressOptions.recurrence"
                                    :searchable="false"
                                    :allow-empty="false"
                                    :selected="currentForm.leadxpress_option.reminder.recurrence"
                                    :disabled="!hasLeadXpress || !hasUntreatedLead"
                                    @update="updateRecurrence"
                                />
                            </div>
                        </div>
                        <div class="col-xs-4">
                            <div class="form-group">
                                <label>{{ $t('accounts.edit.schedule') }}</label>
                                <activix-multiselect
                                    :options="leadxpressOptions.schedule"
                                    :searchable="false"
                                    :selected="schedule"
                                    :allow-empty="false"
                                    :disabled="!hasLeadXpress || !hasUntreatedLead"
                                    @update="updateSchedule"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-4">
                            <div class="form-group">
                                <label>{{ $t('accounts.edit.leadxpressPriority') }}</label>
                                <activix-multiselect
                                    label="name"
                                    identifier="value"
                                    position="top"
                                    :selected="selectedLeadxpressPriority"
                                    :options="leadxpressOptions.priority"
                                    :searchable="false"
                                    :allow-empty="false"
                                    :disabled="!hasLeadXpress"
                                    @update="updateLeadxpressPriority"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box | ml-3">
                <div class="box-header | border-b">
                    <h4 class="box-title">
                        {{ $t('accounts.edit.inTurnOptions') }}
                    </h4>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col-xs-6">
                            <activix-switcher
                                class="mb-4"
                                :value="currentForm.in_turn_reset"
                                :disabled="!hasInTurn"
                                @input="updateAccountProperties({ in_turn_reset: $event })"
                            >
                                {{ $t('accounts.edit.resetEveryNight') }}
                            </activix-switcher>
                        </div>
                        <div class="col-xs-6">
                            <activix-switcher
                                class="mb-4"
                                :value="currentForm.desk_manager_in_turn"
                                :disabled="!hasInTurn"
                                @input="updateAccountProperties({ desk_manager_in_turn: $event })"
                            >
                                {{ $t('accounts.edit.deskManagerInTurn') }}
                                <activix-tooltip :content="$t('accounts.edit.deskManagerInTurnDescription')">
                                    <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                </activix-tooltip>
                            </activix-switcher>
                        </div>
                        <div class="col-xs-6">
                            <activix-switcher
                                class="mb-4"
                                :value="currentForm.gun_miss"
                                :disabled="!hasInTurn"
                                @input="updateAccountProperties({ gun_miss: $event })"
                            >
                                {{ $t('accounts.edit.gunMiss') }}
                                <activix-tooltip :content="$t('accounts.edit.gunMissDescription')">
                                    <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                </activix-tooltip>
                            </activix-switcher>
                        </div>
                        <div class="col-xs-6">
                            <activix-switcher
                                class="mb-4"
                                :value="currentForm.commercial_in_turn"
                                :disabled="!hasInTurn"
                                @input="updateAccountProperties({ commercial_in_turn: $event })"
                            >
                                {{ $t('accounts.edit.commercialInTurn') }}
                                <activix-tooltip :content="$t('accounts.edit.commercialInTurnDescription')">
                                    <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                </activix-tooltip>
                            </activix-switcher>
                        </div>
                        <div class="col-xs-6">
                            <activix-switcher
                                class="mb-4"
                                :value="currentForm.in_turn_planned_meeting"
                                :disabled="!hasInTurn"
                                @input="updateAccountProperties({ in_turn_planned_meeting: $event })"
                            >
                                {{ $t('accounts.edit.plannedMeeting') }}
                                <activix-tooltip :content="$t('accounts.edit.plannedMeetingDescription')">
                                    <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                </activix-tooltip>
                            </activix-switcher>
                        </div>
                        <div class="col-xs-6">
                            <activix-switcher
                                class="mb-4"
                                :value="currentForm.in_turn_director_management"
                                :disabled="!hasInTurn"
                                @input="
                                    updateAccountProperties({
                                        in_turn_director_management: $event,
                                    })
                                "
                            >
                                {{ $t('accounts.edit.directorManagement') }}
                                <activix-tooltip :content="$t('accounts.edit.directorManagementDescription')">
                                    <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                </activix-tooltip>
                            </activix-switcher>
                        </div>
                        <div class="col-xs-6">
                            <activix-switcher
                                class="mb-4"
                                :value="currentForm.in_turn_be_back"
                                :disabled="!hasInTurn"
                                @input="updateAccountProperties({ in_turn_be_back: $event })"
                            >
                                {{ $t('accounts.edit.inTurnBeBack') }}
                                <activix-tooltip :content="$t('accounts.edit.inTurnBeBackDescription')">
                                    <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                </activix-tooltip>
                            </activix-switcher>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <service-settings v-if="hasService" />

        <guest-group-modal
            :opened.sync="guestGroupModalOpened"
            :account-roles="relatedData.roles"
            :account-guest-group="accountGuestGroup"
            :account-posts="relatedData.posts"
            @saved="onGuestGroupSaved"
        />
        <renewal-setting-modal
            :opened.sync="renewalSettingModalOpened"
            @saved="onRenewalSettingSaved"
        />

        <activix-confirm-modal
            type="warning"
            :show-deny="false"
            :content="$t('accounts.edit.infinitePriorityWarning')"
            :approve-text="$t('general.ok')"
            :opened.sync="infiniteModalOpened"
        />

        <activix-confirm-modal
            type="warning"
            :title="$t('general.areYouSure')"
            :content="$t('accounts.edit.guestGroup.deleteConfirmation')"
            :approve-text="$t('accounts.edit.guestGroup.deleteButton')"
            :opened.sync="deleteGuestGroupModal.opened"
            @approve="deleteGuestGroup"
            @closed="deleteGuestGroupModal.id = null"
        />

        <activix-confirm-modal
            type="warning"
            :content="$t('clientCard.activateConfirmationApptService')"
            :opened.sync="confirmationApptServiceModalOpened"
            @deny="onDenyConfirmationApptService"
        />

        <activix-confirm-modal
            type="warning"
            :content="$t('accounts.edit.renewalOptions.missingFields', [renewalAlertMissingFieldsTooltip])"
            :opened.sync="activateRenewAlertMissingFieldsModalOpened"
            @approve="activateMissingRenewAlertFields(toggledAlert)"
            @deny="cancelRenewAlertActivation(toggledAlert)"
        />
    </div>
</template>

<script>
    import { cloneDeep, get, map, set } from 'lodash-es';

    import BusinessHours from '@/components/accounts/BusinessHours.vue';
    import { mapActions, mapState } from 'pinia';
    import ClientCardSection from '@/entities/ClientCardSection.js';
    import LeadVehicleCondition from '@/entities/LeadVehicleCondition';
    import AccountGuestGroup from '../../entities/AccountGuestGroup.js';
    import Supplier from '../../entities/Supplier.js';

    // Component
    import DateTimePicker from '../inputs/DateTimePicker.vue';
    import ServiceSettings from './ServiceSettings.vue';
    import DeliveryTimeframe from '../../entities/DeliveryTimeframe.js';
    import GuestGroupModal from '../modals/GuestGroupModal.vue';
    import RenewalSettingModal from '../modals/RenewalSetting.vue';
    import RenewalSettingsTable from '../RenewalSettingsTable.vue';

    // Pinia
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            RenewalSettingModal,
            RenewalSettingsTable,
            BusinessHours,
            DateTimePicker,
            ServiceSettings,
            GuestGroupModal,
        },

        props: {
            relatedData: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                toggledAlert: null,
                activateRenewAlertMissingFieldsModalOpened: false,
                infiniteModalOpened: false,
                editVAutoUsername: false,
                leadxpressOptions: {
                    frequency: [
                        {
                            value: '1',
                            name: '1m',
                        },
                        {
                            value: '2',
                            name: '2m',
                        },
                        {
                            value: '3',
                            name: '3m',
                        },
                        {
                            value: '4',
                            name: '4m',
                        },
                        {
                            value: '5',
                            name: '5m',
                        },
                        {
                            value: '10',
                            name: '10m',
                        },
                        {
                            value: '15',
                            name: '15m',
                        },
                        {
                            value: '20',
                            name: '20m',
                        },
                        {
                            value: '25',
                            name: '25m',
                        },
                        {
                            value: '30',
                            name: '30m',
                        },
                        {
                            value: '45',
                            name: '45m',
                        },
                        {
                            value: '60',
                            name: '1h',
                        },
                        {
                            value: '120',
                            name: '2h',
                        },
                        {
                            value: '180',
                            name: '3h',
                        },
                        {
                            value: '240',
                            name: '4h',
                        },
                        {
                            value: '300',
                            name: '5h',
                        },
                        {
                            value: '360',
                            name: '6h',
                        },
                        {
                            value: '420',
                            name: '7h',
                        },
                        {
                            value: '480',
                            name: '8h',
                        },
                    ],
                    recurrence: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '15', '20', '50', '100'],
                    schedule: [
                        this.$t('general.advisor'),
                        this.$t('general.dealer'),
                        `${this.$t('general.advisor')} & ${this.$t('general.dealer')}`,
                        this.$t('accounts.edit.allTime'),
                    ],
                    priority: [
                        {
                            value: '0',
                            name: this.$t('accounts.edit.disabled'),
                        },
                        {
                            value: '1',
                            name: '1m',
                        },
                        {
                            value: '2',
                            name: '2m',
                        },
                        {
                            value: '3',
                            name: '3m',
                        },
                        {
                            value: '4',
                            name: '4m',
                        },
                        {
                            value: '5',
                            name: '5m',
                        },
                        {
                            value: '10',
                            name: '10m',
                        },
                        {
                            value: '15',
                            name: '15m',
                        },
                        {
                            value: '20',
                            name: '20m',
                        },
                        {
                            value: '25',
                            name: '25m',
                        },
                        {
                            value: '30',
                            name: '30m',
                        },
                        {
                            value: '45',
                            name: '45m',
                        },
                        {
                            value: '60',
                            name: '1h',
                        },
                        {
                            value: '120',
                            name: '2h',
                        },
                        {
                            value: '9999999',
                            name: this.$t('delays.infinite'),
                        },
                    ],
                },
                timeLimitToAnswer: [
                    {
                        value: '60',
                        name: '1m',
                    },
                    {
                        value: '120',
                        name: '2m',
                    },
                    {
                        value: '180',
                        name: '3m',
                    },
                    {
                        value: '240',
                        name: '4m',
                    },
                    {
                        value: '300',
                        name: '5m',
                    },
                    {
                        value: '600',
                        name: '10m',
                    },
                    {
                        value: '900',
                        name: '15m',
                    },
                    {
                        value: '1200',
                        name: '20m',
                    },
                    {
                        value: '1500',
                        name: '25m',
                    },
                    {
                        value: '1800',
                        name: '30m',
                    },
                    {
                        value: '2700',
                        name: '45m',
                    },
                    {
                        value: '3600',
                        name: '1h',
                    },
                    {
                        value: '7200',
                        name: '2h',
                    },
                    {
                        value: '10800',
                        name: '3h',
                    },
                    {
                        value: '14400',
                        name: '4h',
                    },
                    {
                        value: '18000',
                        name: '5h',
                    },
                    {
                        value: '21600',
                        name: '6h',
                    },
                    {
                        value: '25200',
                        name: '7h',
                    },
                    {
                        value: '28800',
                        name: '8h',
                    },
                ],
                csiDelayOptions: [
                    {
                        value: 'PT4H',
                        name: '4h',
                    },
                    {
                        value: 'PT8H',
                        name: '8h',
                    },
                    {
                        value: 'PT24H',
                        name: '24h',
                    },
                    {
                        value: 'PT48H',
                        name: '48h',
                    },
                    {
                        value: 'PT72H',
                        name: '72h',
                    },
                    {
                        value: 'PT96H',
                        name: '96h',
                    },
                    {
                        value: 'P7D',
                        name: `1 ${this.$tc('delays.week', 1)}`,
                    },
                    {
                        value: 'P14D',
                        name: `2 ${this.$tc('delays.week', 2)}`,
                    },
                    {
                        value: 'P1M',
                        name: `1 ${this.$tc('delays.month', 1)}`,
                    },
                    {
                        value: 'P2M',
                        name: `2 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P3M',
                        name: `3 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P4M',
                        name: `4 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P5M',
                        name: `5 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P6M',
                        name: `6 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P8M',
                        name: `8 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P10M',
                        name: `10 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P12M',
                        name: `12 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P16M',
                        name: `16 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P18M',
                        name: `18 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P20M',
                        name: `20 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P1Y',
                        name: `1 ${this.$tc('delays.year', 1)}`,
                    },
                    {
                        value: 'P2Y',
                        name: `2 ${this.$tc('delays.year', 2)}`,
                    },
                    {
                        value: 'P3Y',
                        name: `3 ${this.$tc('delays.year', 2)}`,
                    },
                    {
                        value: 'P4Y',
                        name: `4 ${this.$tc('delays.year', 2)}`,
                    },
                    {
                        value: 'P5Y',
                        name: `5 ${this.$tc('delays.year', 2)}`,
                    },
                ],
                csiUsedDelayOptions: [
                    {
                        value: 'PT4H',
                        name: '4h',
                    },
                    {
                        value: 'PT8H',
                        name: '8h',
                    },
                    {
                        value: 'PT24H',
                        name: '24h',
                    },
                    {
                        value: 'PT48H',
                        name: '48h',
                    },
                    {
                        value: 'PT72H',
                        name: '72h',
                    },
                    {
                        value: 'PT96H',
                        name: '96h',
                    },
                    {
                        value: 'P7D',
                        name: `1 ${this.$tc('delays.week', 1)}`,
                    },
                    {
                        value: 'P14D',
                        name: `2 ${this.$tc('delays.week', 2)}`,
                    },
                    {
                        value: 'P1M',
                        name: `1 ${this.$tc('delays.month', 1)}`,
                    },
                    {
                        value: 'P2M',
                        name: `2 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P3M',
                        name: `3 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P4M',
                        name: `4 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P5M',
                        name: `5 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P6M',
                        name: `6 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P8M',
                        name: `8 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P10M',
                        name: `10 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P12M',
                        name: `12 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P16M',
                        name: `16 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P18M',
                        name: `18 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P20M',
                        name: `20 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P1Y',
                        name: `1 ${this.$tc('delays.year', 1)}`,
                    },
                    {
                        value: 'P2Y',
                        name: `2 ${this.$tc('delays.year', 2)}`,
                    },
                    {
                        value: 'P3Y',
                        name: `3 ${this.$tc('delays.year', 2)}`,
                    },
                    {
                        value: 'P4Y',
                        name: `4 ${this.$tc('delays.year', 2)}`,
                    },
                    {
                        value: 'P5Y',
                        name: `5 ${this.$tc('delays.year', 2)}`,
                    },
                ],
                renewalSettingModalOpened: false,
                guestGroupModalOpened: false,
                selectedGuestGroup: null,
                accountGuestGroup: new AccountGuestGroup(),
                deleteGuestGroupModal: {
                    opened: false,
                    id: null,
                },
                confirmationApptServiceModalOpened: false,
                renewalAlertOptionsDefault: {
                    equity_base: 'rough',
                    end_location_notifications: ['P1M', 'P3M', 'P6M', 'P12M'],
                    end_warranty_notifications: ['P1M', 'P3M', 'P6M', 'P12M'],
                    settings: [],
                },
                renewalAlertEquityOptions: LeadVehicleCondition.selectOptions('id', 'value'),
                renewalAlertNotificationsOptions: [
                    {
                        value: 'P1M',
                        name: `1 ${this.$tc('delays.month', 1)}`,
                    },
                    {
                        value: 'P2M',
                        name: `2 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P3M',
                        name: `3 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P4M',
                        name: `4 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P5M',
                        name: `5 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P6M',
                        name: `6 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P8M',
                        name: `8 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P10M',
                        name: `10 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P12M',
                        name: `12 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P16M',
                        name: `16 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P18M',
                        name: `18 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P20M',
                        name: `20 ${this.$tc('delays.month', 2)}`,
                    },
                    {
                        value: 'P24M',
                        name: `24 ${this.$tc('delays.month', 2)}`,
                    },
                ],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'activeUsers']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useAccountCardStore, {
                currentForm: 'currentForm',
                customFields: store => store.currentForm.custom_fields,
                hasService: store => store.currentForm.service,
                partnerId: store => store.currentForm.partner_id,
                hasAutoRenewalNew: store => store.currentForm.auto_renewal_new,
                hasAutoRenewalUsed: store => store.currentForm.auto_renewal_used,
                hasSaleTable: store => store.currentForm.sale_table,
                saleTableOptions: store => store.currentForm.sale_table_options,
                clientCardsFields: store => store.currentForm.client_card_fields,
                hasLeadXpress: store => store.currentForm.leadxpress,
                hasUntreatedLead: store => store.currentForm.untreated_lead,
                hasInTurn: store => store.currentForm.in_turn,
            }),

            equityDisabled() {
                return !this.currentForm.equity_alert || !this.authUser.hasAccessToEquity();
            },

            equityBaseOptionTooltip() {
                return this.equityDisabled
                    ? this.$t('accounts.edit.renewalOptions.tooltipAddRenewalSettingDisabled')
                    : this.$t('accounts.edit.renewalOptions.tooltipAddRenewalSetting');
            },

            hasUnassignUnansweredLead() {
                return this.currentForm.leadxpress_option.unassignUnansweredLead;
            },

            renewalAlertMissingFields() {
                return {
                    equity_base: this.renewalAlertEquityRequiredFields,
                    end_location_notifications: this.renewalAlertLocationRequiredFields,
                    end_warranty_notifications: this.renewalAlertWarrantyRequiredFields,
                };
            },

            hasVehicleExport() {
                return this.contextAccount.suppliers.some(supplier => {
                    return supplier.id == Supplier.VAUTO && supplier.outbound;
                });
            },

            importServiceSupplier() {
                return this.contextAccount.suppliers.some(supplier => {
                    return Supplier.isSupplierImportService(supplier.id) && supplier.inbound;
                });
            },

            canCreateGuestGroup() {
                return !!this.activeUsers.length;
            },

            schedule() {
                if (
                    this.currentForm.leadxpress_option.schedule.dealer &&
                    this.currentForm.leadxpress_option.schedule.advisor
                ) {
                    return `${this.$t('general.advisor')} & ${this.$t('general.dealer')}`;
                }

                if (this.currentForm.leadxpress_option.schedule.dealer) {
                    return this.$t('general.dealer');
                }

                if (this.currentForm.leadxpress_option.schedule.advisor) {
                    return this.$t('general.advisor');
                }

                return this.$t('accounts.edit.allTime');
            },

            deliveryTimeframeOptions() {
                return DeliveryTimeframe.selectOptions();
            },

            selectedDeliveryTimeframe() {
                return this.deliveryTimeframeOptions.find(value => {
                    return value.name == this.currentForm.delivery_timeframe;
                });
            },

            saleTableBadgeOptions() {
                return this.customFields
                    .filter(customField => {
                        const isNotVehicleField = ![ClientCardSection.VEHICLE_WANTED, ClientCardSection.VEHICLE_EXCHANGE].includes(customField.section);
                        const isSupportType = customField.type == 'boolean' || customField.type == 'datetime';

                        return (
                            customField.id &&
                            customField.visible &&
                            isNotVehicleField &&
                            isSupportType
                        );
                    })
                    .map(customField => {
                        return {
                            id: customField.id,
                            value: customField.name,
                        };
                    });
            },

            selectedSaleTableBadgeField() {
                return this.saleTableBadgeOptions.find(value => {
                    return value.id == this.currentForm.sale_table_badge_field;
                });
            },

            selectedReminderFrequency() {
                return this.leadxpressOptions.frequency.find(value => {
                    return value.value == this.currentForm.leadxpress_option.reminder.frequency;
                });
            },

            selectedLeadxpressPriority() {
                return this.leadxpressOptions.priority.find(value => {
                    return value.value == this.currentForm.leadxpress_option.priority;
                });
            },

            selectedCsiTaskDelays() {
                return this.csiDelayOptions.filter(delay => this.currentForm.csi_task_delays.includes(delay.value));
            },

            selectedCsiUsedTaskDelays() {
                return this.csiUsedDelayOptions.filter(delay => this.currentForm.csi_used_task_delays.includes(delay.value));
            },

            selectedTimeLimitToAnswer() {
                return this.timeLimitToAnswer.find(value => {
                    return value.value == this.currentForm.leadxpress_option.timeLimitToAnswer;
                });
            },

            csiTooltip() {
                if (this.currentForm.sale_table) {
                    return this.$t('accounts.edit.csiTooltip');
                }

                return '';
            },

            renewalAlertMissingFieldsTooltip() {
                return map(this.renewalAlertMissingFields[this.toggledAlert] || [], 'label').join(', ');
            },

            renewalAlertEndLocationSelected() {
                return this.renewalAlertNotificationsOptions.filter(delay => {
                    const selected = this.currentForm.renewal_alert_options.end_location_notifications;

                    if (!selected) {
                        return false;
                    }

                    return selected.includes(delay.value);
                });
            },

            renewalAlertEndWarrantySelected() {
                return this.renewalAlertNotificationsOptions.filter(delay => {
                    const selected = this.currentForm.renewal_alert_options.end_warranty_notifications;

                    if (!selected) {
                        return false;
                    }

                    return selected.includes(delay.value);
                });
            },

            hasLeadXpressPriority() {
                return !!this.currentForm.leadxpress_option.priority;
            },

            formatedActiveUsers() {
                const activeUsers = this.activeUsers.map(user => {
                    return {
                        id: user.id,
                        value: this.getFullName(user),
                    };
                });

                activeUsers.unshift({
                    id: null,
                    value: this.$t('clientCard.noSelection'),
                });

                return activeUsers;
            },

            formattedGuestGroups() {
                const guestGroups = this.contextAccount.guest_groups.map(guestGroup => {
                    return {
                        id: guestGroup.id,
                        value: guestGroup.name,
                    };
                });

                guestGroups.unshift({
                    id: null,
                    value: this.$t('clientCard.noSelection'),
                });

                return guestGroups;
            },

            stockRequiredOptionTitle() {
                if (this.currentForm.client_card_fields.wanted_vehicle.stock) {
                    return this.$t('accounts.edit.makeModelStockRequiredForSale');
                }

                return this.$t('accounts.edit.makeModelRequiredForSale');
            },

            stockRequiredTooltip() {
                if (this.currentForm.client_card_fields.wanted_vehicle.stock) {
                    return this.$t('accounts.edit.makeModelStockRequiredForSaleTooltip');
                }

                return this.$t('accounts.edit.makeModelRequiredForSaleTooltip');
            },

            renewalAlertEquityTooltip() {
                if (!this.authUser.hasAccessToEquity()) {
                    return this.$t('accounts.edit.renewalOptions.toolTipHasNotAccessEquity');
                }

                return this.renewalAlertEquityRequiredFields.length > 0
                    ? this.$t('accounts.edit.renewalOptions.tooltipRequiredFields') +
                        map(this.renewalAlertEquityRequiredFields, 'label').join(', ')
                    : '';
            },

            renewalAlertLocationTooltip() {
                return this.renewalAlertLocationRequiredFields.length > 0
                    ? this.$t('accounts.edit.renewalOptions.tooltipRequiredFields') +
                        map(this.renewalAlertLocationRequiredFields, 'label').join(', ')
                    : '';
            },

            renewalAlertWarrantyTooltip() {
                return this.renewalAlertWarrantyRequiredFields.length > 0
                    ? this.$t('accounts.edit.renewalOptions.tooltipRequiredFields') +
                        map(this.renewalAlertWarrantyRequiredFields, 'label').join(', ')
                    : '';
            },

            renewalAlertEquityRequiredFields() {
                const fields = [];

                if (!this.getClientCardsProperty('exchange_vehicle.value')) {
                    fields.push({
                        value: 'exchange_vehicle.value',
                        label: this.$t('accounts.edit.worth'),
                    });
                }

                if (!this.getClientCardsProperty('exchange_vehicle.mileage')) {
                    fields.push({
                        value: 'exchange_vehicle.mileage',
                        label: this.$t('accounts.edit.mileage'),
                    });
                }

                if (!this.getClientCardsProperty('exchange_vehicle.modality')) {
                    fields.push({
                        value: 'exchange_vehicle.modality',
                        label: this.$t('accounts.edit.modality'),
                    });
                }

                if (!this.getClientCardsProperty('exchange_vehicle.end_contract_date')) {
                    fields.push({
                        value: 'exchange_vehicle.end_contract_date',
                        label: this.$t('accounts.edit.contractEnd'),
                    });
                }

                if (!this.getClientCardsProperty('performance.refinanced')) {
                    fields.push({
                        value: 'performance.refinanced',
                        label: this.$t('accounts.edit.refinanced'),
                    });
                }

                if (!this.getClientCardsProperty('exchange_vehicle.balance')) {
                    fields.push({
                        value: 'exchange_vehicle.balance',
                        label: this.$t('accounts.edit.balance'),
                    });
                }

                return fields;
            },

            renewalAlertLocationRequiredFields() {
                const fields = [];

                if (!this.getClientCardsProperty('exchange_vehicle.modality')) {
                    fields.push({
                        value: 'exchange_vehicle.modality',
                        label: this.$t('accounts.edit.modality'),
                    });
                }

                if (!this.getClientCardsProperty('exchange_vehicle.end_contract_date')) {
                    fields.push({
                        value: 'exchange_vehicle.end_contract_date',
                        label: this.$t('accounts.edit.contractEnd'),
                    });
                }

                return fields;
            },

            renewalAlertWarrantyRequiredFields() {
                const fields = [];

                if (!this.getClientCardsProperty('exchange_vehicle.end_warranty_date')) {
                    fields.push({
                        value: 'exchange_vehicle.end_warranty_date',
                        label: this.$t('accounts.edit.endWarrantyDate'),
                    });
                }

                return fields;
            },
        },

        watch: {
            'currentForm.confirmation_appt_service'(newValue, oldValue) {
                if (newValue && !oldValue && this.importServiceSupplier) {
                    this.confirmationApptServiceModalOpened = true;
                }
            },

            'currentForm.vehicle_text'(newValue) {
                if (newValue) {
                    this.updateAccountProperties({ vehicle_model_text: false });
                }
            },

            'currentForm.vehicle_model_text'(newValue) {
                if (newValue) {
                    this.updateAccountProperties({ vehicle_text: false });
                }
            },

            'currentForm.waiting_sale_option'() {
                if (this.currentForm.waiting_sale_option && !this.currentForm.waiting_sale_date) {
                    this.updateAccountProperties({ waiting_sale_date: now_date() });
                }
            },

            'currentForm.recreative_special'(newValue) {
                if (newValue) {
                    this.updateAccountProperties({ vehicle_text: true });
                }
            },

            'currentForm.commercial': {
                handler() {
                    const saleTableOptions = this.saleTableOptions;
                    saleTableOptions.commercial_column = this.currentForm.commercial;

                    useAccountCardStore().currentForm.sale_table_options = saleTableOptions;
                },
                deep: true,
            },

            'currentForm.niotext'(newValue) {
                if (!newValue) {
                    this.updateAccountProperties({ confirmation_appt_sale: false });
                }
            },

            'currentForm.stock_required_for_sale'(newValue) {
                if (newValue) {
                    this.updateClientCardsProperty('wanted_vehicle.stock', newValue);
                }
            },
        },

        methods: {
            ...mapActions(useAccountCardStore, ['updateAccountProperties']),
            ...mapActions(useContextStore, ['reloadContextAccount']),

            addRenewalCondition() {
                this.renewalSettingModalOpened = true;
            },

            toggleEquityAlert(value) {
                this.toggleRenewalAlertOption(value, 'equity_base');
            },
            toggleLocationAlert(value) {
                this.toggleRenewalAlertOption(value, 'end_location_notifications');
            },
            toggleWarrantyAlert(value) {
                this.toggleRenewalAlertOption(value, 'end_warranty_notifications');
            },
            toggleRenewalAlertOption(value, alertName) {
                if (!value) {
                    this.updateRenewalAlertProperty(alertName, alertName == 'equity_base' ? null : []);
                } else {
                    this.toggledAlert = alertName;
                    this.checkForMissingRenewalFields(alertName);
                    this.updateRenewalAlertProperty(alertName, this.renewalAlertOptionsDefault[alertName]);
                }
            },
            cancelRenewAlertActivation(alertName) {
                this.toggleRenewalAlertOption(false, alertName);
            },

            checkForMissingRenewalFields(alertName) {
                if (this.renewalAlertMissingFields[alertName].length) {
                    this.activateRenewAlertMissingFieldsModalOpened = true;
                }
            },

            updateClientCardsProperty(property, value) {
                const fields = cloneDeep(this.clientCardsFields);

                set(fields, property, value);

                useAccountCardStore().currentForm.client_card_fields = fields;
            },

            activateMissingRenewAlertFields(alertName) {
                this.renewalAlertMissingFields[alertName].forEach(field => {
                    this.updateClientCardsProperty(field.value, true);
                });
            },

            getClientCardsProperty(property) {
                return get(this.clientCardsFields, property, false);
            },

            displayVAutoFallbackUsername(event) {
                this.editVAutoUsername = event;

                if (!event) {
                    this.currentForm.vauto_fallback_email = null;
                }
            },

            onUpdateApptConfirmationService(value) {
                this.updateAccountProperties({ confirmation_appt_service: value });
            },

            onDenyConfirmationApptService() {
                this.updateAccountProperties({ confirmation_appt_service: false });
            },

            updateFrequency(newValue) {
                const leadXpressOptions = cloneDeep(this.currentForm.leadxpress_option);
                leadXpressOptions.reminder.frequency = parseInt(newValue.value, 10);
                useAccountCardStore().currentForm.leadxpress_option = leadXpressOptions;
            },

            updateRenewalAlertProperty(property, newValue) {
                const renewalAlertOptions = cloneDeep(this.currentForm.renewal_alert_options);
                renewalAlertOptions[property] = newValue;
                useAccountCardStore().currentForm.renewal_alert_options = renewalAlertOptions;
            },

            updateMergeRule(newValue) {
                this.updateAccountProperties({ merge_rule: newValue.id });
            },

            updateDeliveryTimeframe(newValue) {
                this.updateAccountProperties({ delivery_timeframe: newValue.name });
            },

            updateSaleTableBadgeField(newValue) {
                this.updateAccountProperties({ sale_table_badge_active: !!newValue });
                this.updateAccountProperties({
                    sale_table_badge_field: newValue ? newValue.id : null,
                });
            },

            updateImportRule(newValue) {
                this.updateAccountProperties({ import_rule: newValue.id });
            },

            updateRecurrence(newValue) {
                const leadXpressOptions = cloneDeep(this.currentForm.leadxpress_option);
                leadXpressOptions.reminder.recurrence = parseInt(newValue, 10);
                useAccountCardStore().currentForm.leadxpress_option = leadXpressOptions;
            },

            updateSchedule(newValue) {
                const leadXpressOptions = cloneDeep(this.currentForm.leadxpress_option);
                if (newValue == this.$t('accounts.edit.allTime')) {
                    leadXpressOptions.schedule.dealer = false;
                    leadXpressOptions.schedule.advisor = false;
                } else if (newValue == this.$t('general.dealer')) {
                    leadXpressOptions.schedule.dealer = true;
                    leadXpressOptions.schedule.advisor = false;
                } else if (newValue == this.$t('general.advisor')) {
                    leadXpressOptions.schedule.dealer = false;
                    leadXpressOptions.schedule.advisor = true;
                } else {
                    leadXpressOptions.schedule.dealer = true;
                    leadXpressOptions.schedule.advisor = true;
                }
                useAccountCardStore().currentForm.leadxpress_option = leadXpressOptions;
            },

            updateLeadxpressPriority(newValue) {
                if (newValue.value == 9999999) {
                    this.infiniteModalOpened = true;
                }

                const leadXpressOptions = cloneDeep(this.currentForm.leadxpress_option);
                leadXpressOptions.priority = parseInt(newValue.value, 10);
                useAccountCardStore().currentForm.leadxpress_option = leadXpressOptions;
            },

            updateCsiTaskDelays(newValue) {
                this.updateAccountProperties({
                    csi_task_delays: newValue.map(delay => delay.value),
                });
            },

            updateCsiUsedTaskDelays(newValue) {
                this.updateAccountProperties({
                    csi_used_task_delays: newValue.map(delay => delay.value),
                });
            },

            updateSaleOptionsItem(property, value) {
                const saleOptions = cloneDeep(this.saleTableOptions);
                saleOptions[property] = value;
                this.updateAccountProperties({
                    sale_table_options: saleOptions,
                });
            },

            updateDefaultDeliverymanUserId(newValue) {
                this.updateAccountProperties({ default_deliveryman_user_id: newValue.id });
            },

            updateDeliveryGuestGroupId(newValue) {
                this.updateAccountProperties({ delivery_guest_group_id: newValue.id });
            },

            updateUnassignUnansweredLead(newValue) {
                const leadXpressOptions = cloneDeep(this.currentForm.leadxpress_option);
                leadXpressOptions.unassignUnansweredLead = newValue;
                useAccountCardStore().currentForm.leadxpress_option = leadXpressOptions;

                if (newValue && !this.currentForm.leadxpress_option.timeLimitToAnswer) {
                    this.updateTimeLimitToAnswer({ value: 900 });
                }
            },

            updateTimeLimitToAnswer(newValue) {
                const leadXpressOptions = cloneDeep(this.currentForm.leadxpress_option);
                leadXpressOptions.timeLimitToAnswer = parseInt(newValue.value, 10);
                useAccountCardStore().currentForm.leadxpress_option = leadXpressOptions;
            },

            addGuestGroup() {
                if (!this.canCreateGuestGroup) {
                    return;
                }

                this.guestGroupModalOpened = true;
                this.accountGuestGroup = new AccountGuestGroup();
            },

            async deleteGuestGroup() {
                await this.$api.accountGuestGroup.destroy(this.contextAccount.id, this.deleteGuestGroupModal.id);

                this.reloadContextAccount();
            },

            editGuestGroup(id) {
                this.accountGuestGroup = new AccountGuestGroup(
                    this.contextAccount.guest_groups.find(group => group.id == id),
                );
                this.guestGroupModalOpened = true;
            },

            onDeleteGuestGroup(id) {
                this.deleteGuestGroupModal.id = id;
                this.deleteGuestGroupModal.opened = true;
            },

            onGuestGroupSaved() {
                this.reloadContextAccount();
            },

            onRenewalSettingSaved(setting, index = null) {
                const renewalAlertOptions = cloneDeep(this.currentForm.renewal_alert_options);

                if (index != null) {
                    renewalAlertOptions.settings[index] = setting;
                } else {
                    renewalAlertOptions.settings.push(setting);
                }
                useAccountCardStore().currentForm.renewal_alert_options = renewalAlertOptions;
            },

            onRenewalSettingDelete(index) {
                const renewalAlertOptions = cloneDeep(this.currentForm.renewal_alert_options);
                renewalAlertOptions.settings.splice(index, 1);
                useAccountCardStore().currentForm.renewal_alert_options = renewalAlertOptions;
            },
        },

        created() {
            if (!empty(this.currentForm.vauto_fallback_email) && this.hasVehicleExport) {
                this.editVAutoUsername = true;
            }
        },
    };
</script>
