<template>
    <textarea-autosize
        class="form-control"
        :class="classes"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="value"
        :readonly="readonly"
        :maxlength="maxlength"
        ref="input"
        v-on="inputListeners"
    />
</template>

<script>
    export default {
        name: 'ActivixTextarea',

        props: {
            inputClass: {
                type: [String, Array],
                default: '',
            },
            value: {
                type: String,
                default: '',
            },
            placeholder: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            maxlength: {
                type: [Number, Boolean],
                default: 255,
            },
            white: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            classes() {
                const inputClass = Array.isArray(this.inputClass) ? this.inputClass : [this.inputClass];

                return inputClass.concat([
                    {
                        'input-white': this.white,
                    },
                ]);
            },

            inputListeners() {
                return {
                    ...this.$listeners,
                    ...{
                        input: event => this.$emit('input', event),
                    },
                };
            },
        },

        methods: {
            focus() {
                this.$refs.input.focus();
            },
        },
    };
</script>
