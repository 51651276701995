import Enum from './Enum.js';

export default class Locale extends Enum {
    static get entries() {
        return {
            fr: 'fr',
            en: 'en',
        };
    }

    static get FR() {
        return this.entries.fr;
    }

    static get EN() {
        return this.entries.en;
    }

    static get translationGroup() {
        return 'locales';
    }
}
