<template>
    <div>
        <div class="box | mb-6">
            <div class="box-header | border-b flex items-center justify-between">
                <h4 class="box-title">
                    {{ $t('accounts.edit.accountInformation') }}
                </h4>

                <div class="flex items-center">
                    <activix-tooltip :content="$t('accounts.edit.activeInactive')">
                        <activix-switcher
                            :value="currentForm.active"
                            :disabled="!hasAdminPermissions"
                            @input="updateAccountProperties({ active: $event })"
                        />
                    </activix-tooltip>
                    <activix-tooltip :content="$t('accounts.edit.activationDate')">
                        <span class="ml-2 cursor-pointer" @click="openActivationDateModal">
                            {{ as_locale(currentForm.activation_date, 'activation_date').humanShort() || '-' }}
                        </span>
                    </activix-tooltip>
                </div>
            </div>
            <div class="box-body">
                <div class="text-center | lg:flex">
                    <div class="mb-4 | lg:mr-12">
                        <div class="font-bold text-grey-500 mb-1">
                            FR
                        </div>

                        <el-popover placement="top" v-model="logoFrPopoverVisible">
                            <div class="btn-group">
                                <activix-button @click="uploadLogoFr">
                                    <icon name="regular/pencil-1" />
                                </activix-button>
                                <activix-button @click="deleteLogoFr">
                                    <icon :name="$icons.trash" />
                                </activix-button>
                            </div>

                            <div slot="reference">
                                <activix-tooltip :content="!logoFrSrc ? $t('accounts.edit.addLogo') : ''">
                                    <img class="h-32 cursor-pointer" alt="Logo FR" :src="logoFrSrc" />
                                </activix-tooltip>
                            </div>
                        </el-popover>

                        <input
                            class="hidden"
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            ref="logo"
                            @change="updateLogoFr"
                        />
                    </div>
                    <div class="mb-4">
                        <div class="font-bold text-grey-500 mb-1">
                            EN
                        </div>

                        <el-popover placement="top" v-model="logoEnPopoverVisible">
                            <div class="btn-group">
                                <activix-button @click="uploadLogoEn">
                                    <icon name="regular/pencil-1" />
                                </activix-button>
                                <activix-button @click="deleteLogoEn">
                                    <icon :name="$icons.trash" />
                                </activix-button>
                            </div>

                            <div slot="reference">
                                <activix-tooltip :content="!logoEnSrc ? $t('accounts.edit.addLogoEn') : ''">
                                    <img class="h-32 cursor-pointer" alt="Logo EN" :src="logoEnSrc" />
                                </activix-tooltip>
                            </div>
                        </el-popover>

                        <input
                            class="hidden"
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            ref="logo_en"
                            @change="updateLogoEn"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="mb-4" :class="mayUpdateTimezone ? 'col-xs-3' : 'col-xs-6'">
                        <label>{{ $t('accounts.edit.accountName') }}</label>
                        <input
                            type="text"
                            class="form-control"
                            :disabled="authUser.isDirector()"
                            :value="currentForm.name"
                            @input="updateAccountProperties({ name: $event.target.value })"
                        />
                    </div>

                    <div class="col-xs-2 mb-4">
                        <label>{{ $t('accounts.edit.accountPhone') }}</label>
                        <activix-masked-input
                            mask-type="phoneWithCountry"
                            :value="currentForm.phone"
                            @input="updatePhoneAttribute('phone', $event)"
                        />
                    </div>

                    <div class="col-xs-2 mb-4">
                        <label>{{ $t('accounts.edit.callerId') }}</label>
                        <activix-multiselect
                            identifier="value"
                            label="label"
                            :allow-empty="false"
                            :selected="{
                                value: currentForm.caller_id_account ? 1 : 2,
                                label: currentForm.caller_id_account
                                    ? $t('accounts.edit.dealer')
                                    : $t('accounts.edit.user'),
                            }"
                            :options="callerIdOptions"
                            :searchable="false"
                            :disabled="!hasAdminPermissions && !authUser.isManager()"
                            @update="updateCallerId"
                        />
                    </div>

                    <div class="col-xs-2">
                        <label>{{ $t('accounts.edit.locale') }}</label>
                        <activix-multiselect
                            identifier="value"
                            label="text"
                            :selected="selectedLocale"
                            :options="locales"
                            :searchable="false"
                            :allow-empty="false"
                            @update="updateLocale"
                        />
                    </div>

                    <div class="col-xs-3 mb-4" v-if="mayUpdateTimezone">
                        <label>{{ $t('general.timezone') }}</label>
                        <activix-multiselect
                            identifier="value"
                            label="text"
                            :allow-empty="false"
                            :options="timezones"
                            :searchable="true"
                            :selected="selectedTimezone"
                            @update="updateTimezone"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-3 mb-4">
                        <label>{{ $t('accounts.edit.address') }}</label>
                        <input
                            type="text"
                            class="form-control"
                            :value="currentForm.address"
                            @input="updateAccountProperties({ address: $event.target.value })"
                        />
                    </div>

                    <div class="col-xs-3 mb-4">
                        <label>{{ $t('accounts.edit.city') }}</label>
                        <input
                            type="text"
                            class="form-control"
                            :value="currentForm.city"
                            @input="updateAccountProperties({ city: $event.target.value })"
                        />
                    </div>

                    <div class="col-xs-2 mb-4">
                        <label>{{ $t('accounts.edit.province.' + currentForm.country) }}</label>
                        <activix-multiselect
                            identifier="value"
                            label="text"
                            :selected="selectedProvince"
                            :options="provinces"
                            :searchable="false"
                            :allow-empty="false"
                            @update="updateProvince"
                        />
                    </div>

                    <div class="col-xs-2">
                        <label>{{ $t(`general.postalCode.${selectedCountry.value || 'CA'}`) }}</label>
                        <activix-masked-input
                            type="text"
                            mask-type="postalCode"
                            :value="currentForm.postal_code"
                            :country="currentForm.country"
                            @input="updateAccountProperties({ postal_code: $event })"
                        />
                    </div>

                    <div class="col-xs-2 mb-4">
                        <label>{{ $t('accounts.edit.country') }}</label>
                        <activix-multiselect
                            identifier="value"
                            label="text"
                            :selected="selectedCountry"
                            :options="countries"
                            :searchable="false"
                            :allow-empty="false"
                            @update="updateCountry"
                        />
                    </div>
                </div>

                <div class="row">

                    <div class="col-xs-3 mb-4">
                        <label>{{ $t('accounts.edit.infos.accountManager') }}</label>
                        <activix-multiselect
                            label="name"
                            multiple="multiple"
                            :selected="currentForm.children"
                            :options="filteredPossibleChildAccounts"
                            :searchable="true"
                            :close-on-select="false"
                            :taggable="true"
                            :disabled="!authUser.isAdmin() || isChildAccount"
                            @update="updateChildAccounts"
                        />
                    </div>

                    <div class="col-xs-3 mb-4">
                        <label>{{ $tc('accounts.edit.team') }}</label>
                        <activix-multiselect
                            :selected="selectedTeams"
                            :options="teams"
                            :searchable="false"
                            :with-none="true"
                            @update="updateTeams"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-3 mb-4">
                        <label>{{ $t('accounts.edit.dmsExchangeExport') }}</label>
                        <activix-multiselect
                            label="display_name"
                            multiple="multiple"
                            :custom-label="formatSupplierDisplayName"
                            :selected="selectedOutboundSuppliers"
                            :options="outboundSuppliers"
                            :searchable="false"
                            :taggable="true"
                            :disabled="true"
                        />
                    </div>

                    <div class="col-xs-3 mb-4">
                        <label>{{ $t('accounts.edit.dmsExchangeImport') }}</label>
                        <activix-multiselect
                            label="display_name"
                            multiple="multiple"
                            :custom-label="formatSupplierDisplayName"
                            :selected="selectedInboundSuppliers"
                            :options="inboundSuppliers"
                            :searchable="false"
                            :taggable="true"
                            :disabled="true"
                        />
                    </div>

                    <div class="col-xs-3 mb-4">
                        <div class="flex justify-between">
                            <label class="flex items-center">
                                {{ $t('accounts.edit.recordingMessage') }}
                                <activix-tooltip :content="$t('accounts.edit.recordingMessageCustomPhoneProviderTooltip')" v-if="!accountHasSupportedPhoneProvider">
                                    <icon class="text-orange-500 text-sm ml-1" name="bold/alert-triangle" />
                                </activix-tooltip>
                                <activix-tooltip :content="$t('accounts.edit.recordingMessageTooltip')" v-if="accountHasSupportedPhoneProvider">
                                    <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                </activix-tooltip>
                            </label>
                            <div
                                class="flex items-baseline"
                                v-if="
                                    currentForm.recording_message_audio &&
                                        currentForm.recording_message_audio != 'delete'
                                "
                            >
                                <a
                                    class="link-grey-light text-sm"
                                    target="_blank"
                                    :href="currentForm.recording_message_audio"
                                >
                                    <activix-tooltip :content="$t('general.download')">
                                        <icon name="regular/download-button" />
                                    </activix-tooltip>
                                </a>
                                <a
                                    class="link-grey-light text-sm ml-2 | hover:text-red-500"
                                    @click="deleteAudio('recording_message_audio')"
                                    v-if="authUser.isAdmin()"
                                >
                                    <activix-tooltip :content="$t('general.delete')">
                                        <icon :name="$icons.trash" />
                                    </activix-tooltip>
                                </a>
                            </div>
                        </div>
                        <div>
                            <activix-upload
                                class="block"
                                :accepts="['.mp3', '.wma']"
                                :disabled="!authUser.isAdmin() || !accountHasSupportedPhoneProvider"
                                ref="recording_message"
                                @input="updateAudio($event, 'recording_message_audio_file')"
                            />
                        </div>
                    </div>
                    <div class="col-xs-3 mb-4" v-if="authUser.isAdmin()">
                        <label>{{ $t('accounts.edit.emailSubdomain') }}</label>
                        <activix-tooltip :content="dnsTooltip" v-if="contextAccount.email_domain">
                            <icon
                                class="ml-2 text-green-500"
                                name="bold/check-circle-1"
                                v-if="contextAccount.transactional_domain_active && contextAccount.marketing_domain_active"
                            />
                            <icon
                                class="ml-2 text-orange-500"
                                name="bold/alert-circle"
                                v-else
                            />
                        </activix-tooltip>

                        <input
                            type="text"
                            class="form-control"
                            :readonly="contextAccount.email_domain"
                            :value="emailSubDomain"
                            @input="updateAccountProperties({ email_domain: $event.target.value })"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="flex space-x-6">
            <div class="box | mb-6 flex-1" v-if="$route.name == 'accounts.update'">
                <div class="box-header | border-b flex items-center justify-between">
                    <h4 class="box-title">
                        {{ $t('accounts.edit.phoneSystem') }}
                    </h4>
                    <div>
                        <external-phone-provider :disabled="!authUser.isAdmin() || haveExternalPhoneProviders" :external-phone-providers="fieldOptions.phone_providers" />
                    </div>
                </div>
                <div class="box-body">
                    <table class="inline-table">
                        <tr>
                            <th>
                                <div class="flex items-center">
                                    {{ $t('phoneProvider.phone') }}
                                    <activix-tooltip
                                        :content="!currentForm.active ? $t('accounts.edit.needActiveAccount') : ''"
                                        v-if="authUser.isAdmin()"
                                    >
                                        <dropdown-button
                                            :disabled="!currentForm.active"
                                            :options="fieldOptions.phone_providers"
                                            @select="addNewPhoneNumber"
                                            v-if="!accountHasSupportedPhoneProvider"
                                        >
                                            <icon name="bold/add" class="link-grey-light ml-2 text-sm" />
                                        </dropdown-button>
                                        <activix-base-button
                                            class="link-grey-light ml-2 text-sm"
                                            :class="{ disabled: !currentForm.active }"
                                            @click="openPhoneProviderModal"
                                            v-else
                                        >
                                            <icon name="bold/add" />
                                        </activix-base-button>
                                    </activix-tooltip>
                                </div>
                            </th>
                            <template v-if="orderedPhoneProviders.length">
                                <th>{{ $t('phoneProvider.direction') }}</th>
                                <th>{{ $t('phoneProvider.division') }}</th>
                                <th>{{ $t('phoneProvider.user') }}</th>
                                <th>{{ $t('phoneProvider.source') }}</th>
                                <th class="text-center" v-show="accountHasSupportedPhoneProvider">
                                    {{ $t('phoneProvider.ivr') }}
                                </th>
                                <th class="text-center">
                                    <activix-tooltip :content="$t('accounts.edit.recordingMessage')">
                                        <icon name="bold/microphone" />
                                    </activix-tooltip>
                                </th>
                                <th v-if="authUser.isAdmin()" />
                            </template>
                        </tr>

                        <tr
                            is="phone-provider"
                            :phone="phone"
                            :related-data="relatedData"
                            :disabled="disabledPhone(phone)"
                            :key="index"
                            v-for="(phone, index) in orderedPhoneProviders"
                        />
                    </table>
                </div>
            </div>

            <div class="box | mb-6 w-1/4">
                <div class="box-header | border-b">
                    <h4 class="box-title">
                        {{ $t('accounts.edit.exclusions') }}
                    </h4>
                </div>
                <div class="box-body | space-y-8">
                    <blacklist @update-blacklist-phones="updateBlacklistPhones" v-if="authUser.isAdmin()" />
                    <imap-exclusions />
                </div>
            </div>
        </div>

        <div class="box | mb-6">
            <div class="box-header | border-b flex items-center justify-between">
                <h4 class="box-title">
                    {{ $t('accounts.edit.ivrSection') }}
                </h4>

                <activix-tooltip placement="left" :content="phoneExclusionTooltip">
                    <div class="flex items-center">
                        <icon class="text-orange-500" name="bold/alert-triangle" v-if="phoneExclusionTooltip" />
                        <activix-switcher
                            class="ml-2"
                            :value="currentForm.ivr"
                            :disabled="!hasAdminPermissions || !validIvrNumbers || haveExternalPhoneProviders"
                            @input="updateAccountProperties({ ivr: $event })"
                        />
                    </div>
                </activix-tooltip>
            </div>
            <div class="box-body">
                <div class="row">
                    <div class="col-xs-6">
                        <div class="row">
                            <div class="col-xs-6">
                                <div class="row">
                                    <div class="col-xs-6 mb-4">
                                        <label>{{ $t('accounts.edit.ivrLocale') }}</label>
                                        <activix-multiselect
                                            label="value"
                                            :allow-empty="false"
                                            :selected="
                                                currentForm.ivr_locale
                                                    ? { id: currentForm.ivr_locale, value: currentForm.ivr_locale }
                                                    : { id: null, value: $t('accounts.edit.bilingual') }
                                            "
                                            :options="[
                                                { id: null, value: $t('accounts.edit.bilingual') },
                                                { id: 'FR', value: 'FR' },
                                                { id: 'EN', value: 'EN' },
                                            ]"
                                            :placeholder="$t('accounts.edit.bilingual')"
                                            :searchable="false"
                                            :disabled="!hasIvr || !hasAdminPermissions"
                                            @update="updateIvrLocale"
                                        />
                                    </div>

                                    <div class="col-xs-6 mb-4">
                                        <label>{{ $t('accounts.edit.ivrWaitingSound') }}</label>
                                        <activix-multiselect
                                            label="value"
                                            :allow-empty="false"
                                            :selected="
                                                currentForm.ivr_waiting_sound
                                                    ? {
                                                        id: currentForm.ivr_waiting_sound,
                                                        value: $t(`accounts.edit.${currentForm.ivr_waiting_sound}`),
                                                    }
                                                    : { id: 'music', value: $t('accounts.edit.music') }
                                            "
                                            :options="[
                                                { id: 'music', value: $t('accounts.edit.music') },
                                                { id: 'ringing', value: $t('accounts.edit.ringing') },
                                            ]"
                                            :searchable="false"
                                            :placeholder="$t('accounts.edit.music')"
                                            :disabled="!hasIvr || !hasAdminPermissions"
                                            @update="updateIvrWaitingSound"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-6 mb-4">
                                <label class="flex items-center">
                                    {{ $t('accounts.edit.ivrName') }}
                                    <activix-tooltip
                                        :content="$t('accounts.edit.audioOverwriteName')"
                                        v-if="hasIvr && currentForm.ivr_name && currentForm.ivr_audio_welcome"
                                    >
                                        <icon class="text-orange-500 text-sm ml-1" name="bold/alert-triangle" />
                                    </activix-tooltip>
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    :disabled="!hasIvr || !hasAdminPermissions"
                                    :value="currentForm.ivr_name"
                                    @input="
                                        updateAccountProperties({ ivr_name: $event.target.value })
                                    "
                                />
                            </div>

                            <div class="col-xs-6 mb-4">
                                <label>{{ $t('accounts.edit.ivrServicePhone') }}</label>
                                <activix-masked-input
                                    mask-type="phoneWithCountry"
                                    :value="currentForm.ivr_service_number"
                                    :disabled="!hasIvr || !hasAdminPermissions"
                                    @input="updatePhoneAttribute('ivr_service_number', $event)"
                                />
                            </div>

                            <div class="col-xs-6 mb-4">
                                <div class="flex justify-between">
                                    <label>{{ $t('accounts.edit.ivrAudioWelcome') }}</label>
                                    <div
                                        class="flex items-baseline"
                                        v-if="
                                            currentForm.ivr_audio_welcome &&
                                                currentForm.ivr_audio_welcome_file != 'delete'
                                        "
                                    >
                                        <a
                                            class="link-grey-light text-sm"
                                            target="_blank"
                                            :href="currentForm.ivr_audio_welcome"
                                        >
                                            <activix-tooltip content="$t('general.download')">
                                                <icon name="regular/download-button" />
                                            </activix-tooltip>
                                        </a>
                                        <a
                                            class="link-grey-light text-sm ml-2 | hover:text-red-500"
                                            @click="deleteAudio('ivr_audio_welcome_file')"
                                            v-if="hasAdminPermissions"
                                        >
                                            <activix-tooltip :content="$t('general.delete')">
                                                <icon :name="$icons.trash" />
                                            </activix-tooltip>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <activix-upload
                                        :accepts="['.mp3', '.wma']"
                                        :disabled="!hasIvr || !hasAdminPermissions"
                                        ref="ivr_audio_welcome"
                                        @input="updateAudio($event, 'ivr_audio_welcome_file')"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6">
                        <div class="row" v-if="currentForm.ivr_locale != 'FR'">
                            <div class="col-xs-6 mb-4">
                                <div class="flex justify-between">
                                    <label>{{ $t('accounts.edit.ivrAudioMenuEn') }}</label>
                                    <div
                                        class="flex items-baseline"
                                        v-if="
                                            currentForm.ivr_audio_menu_en &&
                                                currentForm.ivr_audio_menu_en_file != 'delete'
                                        "
                                    >
                                        <a
                                            class="link-grey-light text-sm"
                                            target="_blank"
                                            :href="currentForm.ivr_audio_menu_en"
                                        >
                                            <activix-tooltip :content="$t('general.download')">
                                                <icon name="regular/download-button" />
                                            </activix-tooltip>
                                        </a>
                                        <a
                                            class="link-grey-light text-sm ml-2 | hover:text-red-500"
                                            @click="deleteAudio('ivr_audio_menu_en_file')"
                                            v-if="hasAdminPermissions"
                                        >
                                            <activix-tooltip :content="$t('general.delete')">
                                                <icon :name="$icons.trash" />
                                            </activix-tooltip>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <activix-upload
                                        :accepts="['.mp3', '.wma']"
                                        :disabled="!hasIvr || !hasAdminPermissions"
                                        ref="ivr_audio_menu_en"
                                        @input="updateAudio($event, 'ivr_audio_menu_en_file')"
                                    />
                                </div>
                            </div>

                            <div class="col-xs-6 mb-4">
                                <div class="flex justify-between">
                                    <label>{{ $t('accounts.edit.ivrAudioInvalidEn') }}</label>
                                    <div
                                        class="flex items-baseline"
                                        v-if="
                                            currentForm.ivr_audio_invalid_en &&
                                                currentForm.ivr_audio_invalid_en_file != 'delete'
                                        "
                                    >
                                        <a
                                            class="link-grey-light text-sm"
                                            target="_blank"
                                            :href="currentForm.ivr_audio_invalid_en"
                                        >
                                            <activix-tooltip :content="$t('general.download')">
                                                <icon name="regular/download-button" />
                                            </activix-tooltip>
                                        </a>
                                        <a
                                            class="link-grey-light text-sm ml-2 | hover:text-red-500"
                                            @click="deleteAudio('ivr_audio_invalid_en_file')"
                                            v-if="hasAdminPermissions"
                                        >
                                            <activix-tooltip :content="$t('general.delete')">
                                                <icon :name="$icons.trash" />
                                            </activix-tooltip>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <activix-upload
                                        :accepts="['.mp3', '.wma']"
                                        :disabled="!hasIvr || !hasAdminPermissions"
                                        ref="ivr_audio_invalid_en"
                                        @input="updateAudio($event, 'ivr_audio_invalid_en_file')"
                                    />
                                </div>
                            </div>

                            <div class="col-xs-6 mb-4">
                                <div class="flex justify-between">
                                    <label>{{ $t('accounts.edit.ivrAudioTransferEn') }}</label>
                                    <div
                                        class="flex items-baseline"
                                        v-if="
                                            currentForm.ivr_audio_transfer_en &&
                                                currentForm.ivr_audio_transfer_en_file != 'delete'
                                        "
                                    >
                                        <a
                                            class="link-grey-light text-sm"
                                            target="_blank"
                                            :href="currentForm.ivr_audio_transfer_en"
                                        >
                                            <activix-tooltip :content="$t('general.download')">
                                                <icon name="regular/download-button" />
                                            </activix-tooltip>
                                        </a>
                                        <a
                                            class="link-grey-light text-sm ml-2 | hover:text-red-500"
                                            @click="deleteAudio('ivr_audio_transfer_en_file')"
                                            v-if="hasAdminPermissions"
                                        >
                                            <activix-tooltip :content="$t('general.delete')">
                                                <icon :name="$icons.trash" />
                                            </activix-tooltip>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <activix-upload
                                        :accepts="['.mp3', '.wma']"
                                        :disabled="!hasIvr || !hasAdminPermissions"
                                        ref="ivr_audio_transfer_en"
                                        @input="updateAudio($event, 'ivr_audio_transfer_en_file')"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="currentForm.ivr_locale != 'EN'">
                            <div class="col-xs-6 mb-4">
                                <div class="flex justify-between">
                                    <label>{{ $t('accounts.edit.ivrAudioMenuFr') }}</label>
                                    <div
                                        class="flex items-baseline"
                                        v-if="
                                            currentForm.ivr_audio_menu_fr &&
                                                currentForm.ivr_audio_menu_fr_file != 'delete'
                                        "
                                    >
                                        <a
                                            class="link-grey-light text-sm"
                                            target="_blank"
                                            :href="currentForm.ivr_audio_menu_fr"
                                        >
                                            <activix-tooltip :content="$t('general.download')">
                                                <icon name="regular/download-button" />
                                            </activix-tooltip>
                                        </a>
                                        <a
                                            class="link-grey-light text-sm ml-2 | hover:text-red-500"
                                            @click="deleteAudio('ivr_audio_menu_fr_file')"
                                            v-if="hasAdminPermissions"
                                        >
                                            <activix-tooltip :content="$t('general.delete')">
                                                <icon :name="$icons.trash" />
                                            </activix-tooltip>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <activix-upload
                                        :accepts="['.mp3', '.wma']"
                                        :disabled="!hasIvr || !hasAdminPermissions"
                                        ref="ivr_audio_menu_fr"
                                        @input="updateAudio($event, 'ivr_audio_menu_fr_file')"
                                    />
                                </div>
                            </div>

                            <div class="col-xs-6 mb-4">
                                <div class="flex justify-between">
                                    <label>{{ $t('accounts.edit.ivrAudioInvalidFr') }}</label>
                                    <div
                                        class="flex items-baseline"
                                        v-if="
                                            currentForm.ivr_audio_invalid_fr &&
                                                currentForm.ivr_audio_invalid_fr_file != 'delete'
                                        "
                                    >
                                        <a
                                            class="link-grey-light text-sm"
                                            target="_blank"
                                            :href="currentForm.ivr_audio_invalid_fr"
                                        >
                                            <activix-tooltip :content="$t('general.download')">
                                                <icon name="regular/download-button" />
                                            </activix-tooltip>
                                        </a>
                                        <a
                                            class="link-grey-light text-sm ml-2 | hover:text-red-500"
                                            @click="deleteAudio('ivr_audio_invalid_fr_file')"
                                            v-if="hasAdminPermissions"
                                        >
                                            <activix-tooltip :content="$t('general.delete')">
                                                <icon :name="$icons.trash" />
                                            </activix-tooltip>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <activix-upload
                                        :accepts="['.mp3', '.wma']"
                                        :disabled="!hasIvr || !hasAdminPermissions"
                                        ref="ivr_audio_invalid_fr"
                                        @input="updateAudio($event, 'ivr_audio_invalid_fr_file')"
                                    />
                                </div>
                            </div>

                            <div class="col-xs-6 mb-4">
                                <div class="flex justify-between">
                                    <label>{{ $t('accounts.edit.ivrAudioTransferFr') }}</label>
                                    <div
                                        class="flex items-baseline"
                                        v-if="
                                            currentForm.ivr_audio_transfer_fr &&
                                                currentForm.ivr_audio_transfer_fr_file != 'delete'
                                        "
                                    >
                                        <a
                                            class="link-grey-light text-sm"
                                            target="_blank"
                                            :href="currentForm.ivr_audio_transfer_fr"
                                        >
                                            <activix-tooltip :content="$t('general.download')">
                                                <icon name="regular/download-button" />
                                            </activix-tooltip>
                                        </a>
                                        <a
                                            class="link-grey-light text-sm ml-2 | hover:text-red-500"
                                            @click="deleteAudio('ivr_audio_transfer_fr_file')"
                                            v-if="hasAdminPermissions"
                                        >
                                            <activix-tooltip :content="$t('general.delete')">
                                                <icon :name="$icons.trash" />
                                            </activix-tooltip>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <activix-upload
                                        :accepts="['.mp3', '.wma']"
                                        :disabled="!hasIvr || !hasAdminPermissions"
                                        ref="ivr_audio_transfer_fr"
                                        @input="updateAudio($event, 'ivr_audio_transfer_fr_file')"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box | mb-6">
            <div class="box-header | border-b">
                <h4 class="box-title">
                    {{ $t('accounts.edit.modulesOptions') }}
                </h4>
            </div>
            <div class="box-body">
                <div class="row">
                    <div class="col-xs-5">
                        <div class="row">
                            <div class="col-xs-6">
                                <div>
                                    <activix-tooltip :content="getDisabledTooltip('crmMax')">
                                        <activix-checkbox
                                            class="mb-4"
                                            :value="crmMax"
                                            :disabled="!authUser.isProgrammer() || (crmMax && currentForm.web_order) || (!accountHasSupportedPhoneProvider && !haveOutgoingInternalPhoneProviders)"
                                            @input="updateCrmMax"
                                        >
                                            <span class="font-bold" slot="before">{{
                                                $t('accounts.edit.crmMax')
                                            }}</span>
                                        </activix-checkbox>
                                    </activix-tooltip>
                                </div>
                                <div :class="{ highlight: hoverOptions.web_order }">
                                    <activix-tooltip :content="getDisabledTooltip('leadxpress')">
                                        <activix-checkbox
                                            class="mb-4"
                                            :value="currentForm.leadxpress"
                                            :disabled="!authUser.isProgrammer() || currentForm.web_order"
                                            @input="updateAccountProperties({ leadxpress: $event })"
                                        >
                                            {{ $t('accounts.edit.leadXpress') }}
                                        </activix-checkbox>
                                    </activix-tooltip>
                                </div>
                                <div :class="{ highlight: hoverOptions.web_order }">
                                    <activix-tooltip :content="getDisabledTooltip('webboost')">
                                        <activix-checkbox
                                            class="mb-4"
                                            :value="currentForm.webboost"
                                            :disabled="!authUser.isProgrammer() || currentForm.web_order || (!accountHasSupportedPhoneProvider && !haveOutgoingInternalPhoneProviders)"
                                            @input="updateAccountProperties({ webboost: $event })"
                                        >
                                            {{ $t('accounts.edit.webBoost') }}
                                        </activix-checkbox>
                                    </activix-tooltip>
                                </div>
                                <div>
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.walk_in"
                                        :disabled="!authUser.isProgrammer()"
                                        @input="updateAccountProperties({ walk_in: $event })"
                                    >
                                        {{ $t('accounts.edit.walkIn') }}
                                    </activix-checkbox>
                                </div>
                                <div>
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.phone_up"
                                        :disabled="!authUser.isProgrammer()"
                                        @input="updateAccountProperties({ phone_up: $event })"
                                    >
                                        {{ $t('accounts.edit.phoneUp') }}
                                    </activix-checkbox>
                                </div>
                            </div>
                            <div class="col-xs-6">
                                <div>
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="saleDepartment"
                                        :disabled="!authUser.isProgrammer()"
                                        @input="updateSaleDepartment"
                                    >
                                        <span class="font-bold" slot="before">{{
                                            $t('accounts.edit.departmentMoney')
                                        }}</span>
                                    </activix-checkbox>
                                </div>
                                <div
                                    :class="{ highlight: hoverOptions.web_order }"
                                    @mouseenter="setMouseHover('web_order')"
                                    @mouseleave="setMouseLeave('web_order')"
                                >
                                    <activix-tooltip :content="getDisabledTooltip('web_order')">
                                        <activix-checkbox
                                            class="mb-4"
                                            :value="currentForm.web_order"
                                            :disabled="
                                                !authUser.isAdmin() || !currentForm.leadxpress || !currentForm.webboost
                                            "
                                            @input="updateAccountProperties({ web_order: $event })"
                                        >
                                            {{ $t('accounts.edit.webOrder') }}
                                        </activix-checkbox>
                                    </activix-tooltip>
                                </div>
                                <div>
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.loyalty"
                                        :disabled="!authUser.isAdmin()"
                                        @input="updateAccountProperties({ loyalty: $event })"
                                    >
                                        {{ $t('accounts.edit.loyalty') }}
                                    </activix-checkbox>
                                </div>
                                <div
                                    :class="{ highlight: hoverOptions.service }"
                                    @mouseenter="setMouseHover('service')"
                                    @mouseleave="setMouseLeave('service')"
                                >
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.service"
                                        :disabled="!authUser.isProgrammer()"
                                        @input="updateAccountProperties({ service: $event })"
                                    >
                                        {{ $t('accounts.edit.service') }}
                                    </activix-checkbox>
                                </div>
                                <div
                                    :class="{ highlight: hoverOptions.renewal }"
                                    @mouseenter="setMouseHover('renewal')"
                                    @mouseleave="setMouseLeave('renewal')"
                                >
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.renewal"
                                        :disabled="!authUser.isProgrammer()"
                                        @input="updateAccountProperties({ renewal: $event })"
                                    >
                                        {{ $t('accounts.edit.renew') }}
                                    </activix-checkbox>
                                </div>
                                <div
                                    :class="{ highlight: hoverOptions.commercial }"
                                    @mouseenter="setMouseHover('commercial')"
                                    @mouseleave="setMouseLeave('commercial')"
                                >
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.commercial"
                                        :disabled="!authUser.isProgrammer()"
                                        @input="updateAccountProperties({ commercial: $event })"
                                    >
                                        {{ $t('accounts.edit.commercialDirector') }}
                                    </activix-checkbox>
                                </div>
                                <div
                                    :class="{ highlight: hoverOptions.sale_table }"
                                    @mouseenter="setMouseHover('sale_table')"
                                    @mouseleave="setMouseLeave('sale_table')"
                                >
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.sale_table"
                                        :disabled="!authUser.isProgrammer()"
                                        @input="updateAccountProperties({ sale_table: $event })"
                                    >
                                        {{ $t('accounts.edit.salesChart') }}
                                    </activix-checkbox>
                                </div>
                                <div>
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.event"
                                        :disabled="!authUser.isProgrammer()"
                                        @input="updateAccountProperties({ event: $event })"
                                    >
                                        {{ $t('accounts.edit.event') }}
                                    </activix-checkbox>
                                </div>
                                <div>
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.activity_report"
                                        :disabled="!authUser.isAdmin()"
                                        @input="updateAccountProperties({ activity_report: $event })"
                                    >
                                        {{ $t('accounts.edit.activityReport') }}
                                    </activix-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-7">
                        <div class="mb-4">
                            <activix-checkbox
                                :value="saleOptions"
                                :disabled="!authUser.isProgrammer()"
                                @input="updateSaleOptions"
                            >
                                <span class="font-bold" slot="before">{{ $t('accounts.edit.optionsMoney') }}</span>
                            </activix-checkbox>
                        </div>

                        <div class="flex flex-wrap -mx-3">
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.bdc_advisor"
                                    :disabled="!authUser.isAdmin() || currentForm.children.length > 0"
                                    @input="updateAccountProperties({ bdc_advisor: $event })"
                                >
                                    {{ $t('accounts.edit.bdcAgent') }}
                                </activix-checkbox>
                            </div>
                            <div
                                class="w-1/3 px-3"
                                :class="{ highlight: hoverOptions.power_sport }"
                                @mouseenter="setMouseHover('power_sport')"
                                @mouseleave="setMouseLeave('power_sport')"
                            >
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.power_sport"
                                    :disabled="!authUser.isAdmin()"
                                    @input="updateAccountProperties({ power_sport: $event })"
                                >
                                    {{ $t('accounts.edit.powerSport') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.in_turn"
                                    :disabled="!authUser.isProgrammer()"
                                    @input="updateAccountProperties({ in_turn: $event })"
                                >
                                    {{ $t('accounts.edit.inTurn') }}
                                </activix-checkbox>
                            </div>
                            <div
                                class="w-1/3 px-3"
                                :class="{ highlight: hoverOptions.recreative_special }"
                                @mouseenter="setMouseHover('recreative_special')"
                                @mouseleave="setMouseLeave('recreative_special')"
                            >
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.recreative_special"
                                    :disabled="!authUser.isAdmin()"
                                    @input="updateAccountProperties({ recreative_special: $event })"
                                >
                                    {{ $t('accounts.edit.recreativeSpecial') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.scan"
                                    :disabled="!authUser.isProgrammer()"
                                    @input="updateAccountProperties({ scan: $event })"
                                >
                                    {{ $t('accounts.edit.scanXpress') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.vin_decoder"
                                    :disabled="!authUser.isProgrammer()"
                                    @input="updateAccountProperties({ vin_decoder: $event })"
                                >
                                    {{ $t('accounts.edit.vinDecoder') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.calendar_options"
                                    :disabled="!authUser.isProgrammer() && !authUser.isAdmin()"
                                    @input="updateAccountProperties({ calendar_options: $event })"
                                >
                                    {{ $t('accounts.edit.globalCalendar') }}
                                </activix-checkbox>
                            </div>
                            <div
                                class="w-1/3 px-3"
                                :class="{ highlight: hoverOptions.sale_accessories }"
                                @mouseenter="setMouseHover('sale_accessories')"
                                @mouseleave="setMouseLeave('sale_accessories')"
                            >
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.sale_accessories"
                                    :disabled="!authUser.isAdmin()"
                                    @input="updateAccountProperties({ sale_accessories: $event })"
                                >
                                    {{ $t('accounts.edit.accSaleSpecial') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.vin_manual_decoder"
                                    :disabled="!authUser.isProgrammer()"
                                    @input="updateAccountProperties({ vin_manual_decoder: $event })"
                                >
                                    {{ $t('accounts.edit.vinManualDecoder') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.credit"
                                    :disabled="!authUser.isAdmin()"
                                    @input="updateAccountProperties({ credit: $event })"
                                >
                                    {{ $t('accounts.edit.credit') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.automation"
                                    :disabled="!authUser.isProgrammer()"
                                    @input="updateAccountProperties({ automation: $event })"
                                >
                                    {{ $t('accounts.edit.automations') }}
                                </activix-checkbox>
                            </div>
                            <div
                                class="w-1/3 px-3"
                                :class="{ highlight: hoverOptions.trade_report }"
                                @mouseenter="setMouseHover('trade_report')"
                                @mouseleave="setMouseLeave('trade_report')"
                            >
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.trade_report"
                                    :disabled="!authUser.isAdmin()"
                                    @input="updateAccountProperties({ trade_report: $event })"
                                >
                                    {{ $t('accounts.edit.tradeReport') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.duplicates"
                                    :disabled="!authUser.isAdmin()"
                                    @input="updateAccountProperties({ duplicates: $event })"
                                >
                                    {{ $t('accounts.edit.duplicates') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-tooltip :content="$t('accounts.edit.imapEmail')">
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.email_client"
                                        :disabled="!authUser.isProgrammer()"
                                        @input="updateAccountProperties({ email_client: $event })"
                                    >
                                        {{ $t('users.edit.imapEmail') }}
                                    </activix-checkbox>
                                </activix-tooltip>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.niotext"
                                    :disabled="!authUser.isProgrammer() || currentForm.children.length > 0"
                                    @input="updateAccountProperties({ niotext: $event })"
                                >
                                    {{ $t('accounts.edit.nioText') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.niotext_appointment"
                                    :disabled="!authUser.isProgrammer() || !currentForm.niotext"
                                    @input="updateAccountProperties({ niotext_appointment: $event })"
                                >
                                    {{ $t('accounts.edit.nioTextAppointment') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.niotext_campaign"
                                    :disabled="!authUser.isProgrammer() || !currentForm.niotext"
                                    @input="updateAccountProperties({ niotext_campaign: $event })"
                                >
                                    {{ $t('accounts.edit.nioTextCampaign') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="!currentForm.children.length">
                                <activix-tooltip :content="!currentForm.id ? $t('accounts.edit.onlyOnUpdate') : ''">
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="!!currentForm.facebook_id"
                                        :disabled="!currentForm.id"
                                        @click.prevent="facebookModalOpened = true"
                                    >
                                        {{ $t('accounts.edit.facebookMessenger') }}
                                    </activix-checkbox>
                                </activix-tooltip>
                            </div>
                            <div class="w-1/3 px-3" v-if="videoconferenceOptionAvailable">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.video_conference"
                                    :disabled="!authUser.isProgrammer()"
                                    @input="updateAccountProperties({ video_conference: $event })"
                                >
                                    {{ $t('accounts.edit.videoconference') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="!currentForm.children.length">
                                <activix-tooltip
                                    :content="!currentForm.renewal ? $t('accounts.edit.renewalRequired') : ''"
                                >
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.equity_alert"
                                        :disabled="!currentForm.renewal || !authUser.isProgrammer()"
                                        @input="updateAccountProperties({
                                            equity_alert: $event,
                                            renewal_alert_options: renewalAlertOptionsDefault,
                                        })"
                                    >
                                        {{ $t('accounts.edit.equity_alert') }}
                                    </activix-checkbox>
                                </activix-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box | mb-6">
            <div class="box-header | border-b">
                <h4 class="box-title">
                    {{ $t('accounts.edit.fields') }}
                </h4>
            </div>
            <div class="box-body">
                <div class="flex flex-wrap -mx-2">
                    <!-- Custom inputs-->
                    <div class="w-1/2 px-2 | xl:w-1/4">
                        <div class="mb-4">
                            <label>{{ $t('accounts.edit.customInput') }}</label>
                        </div>
                        <draggable :options="{ handle: '.draggable' }" @update="updateCustomFieldPosition">
                            <li
                                :id="item.id"
                                class="flex items-center h-6 mb-4"
                                :key="item.id"
                                v-for="item in customFieldsFiltered"
                            >
                                <div class="draggable | link-grey cursor-move">
                                    <icon name="regular/navigation-menu" />
                                </div>
                                <div class="flex items-center group ml-2">
                                    <activix-tooltip
                                        :content="
                                            customFieldDisabled(item)
                                                ? $t('accounts.edit.customFieldDisabledBadge')
                                                : ''
                                        "
                                    >
                                        <activix-switcher
                                            color="green"
                                            :disabled="customFieldDisabled(item)"
                                            v-model="item.visible"
                                        />
                                    </activix-tooltip>
                                    <div class="link-grey ml-2 truncate" @click="editCustomField(item)">
                                        {{ item.name }}
                                    </div>
                                    <icon
                                        class="link-grey-light transition-opacity duration-150 opacity-0 ml-2 | group-hover:opacity-100 hover:text-red-500"
                                        :name="$icons.trash"
                                        @click="deleteCustomField(item)"
                                        v-if="!customFieldDisabled(item)"
                                    />
                                </div>
                            </li>
                        </draggable>
                        <div>
                            <button class="flex items-center link-grey" @click="addCustomField">
                                <icon :name="$icons.add" class="text-sm mr-2" />
                                {{ $t('accounts.edit.customFields.addButton') }}
                            </button>
                        </div>
                    </div>

                    <!-- General info fields -->
                    <div class="w-1/2 px-2 | xl:w-1/4">
                        <div class="mb-4">
                            <label>{{ $t('accounts.edit.generalInfo') }}</label>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.communication_preference"
                                @input="updateClientCardsProperty('general_info.communication_preference', $event)"
                            >
                                {{ $t('accounts.edit.communicationPreference') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.sector"
                                @input="updateClientCardsProperty('general_info.sector', $event)"
                            >
                                {{ $t('accounts.edit.sector') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.budget"
                                @input="updateClientCardsProperty('general_info.budget', $event)"
                            >
                                {{ $t('accounts.edit.budgetTextField') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.rating"
                                @input="updateClientCardsProperty('general_info.rating', $event)"
                            >
                                {{ $t('accounts.edit.rating') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.lead_type"
                                @input="updateClientCardsProperty('general_info.lead_type', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('accounts.edit.leadType') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.lead_form"
                                @input="updateClientCardsProperty('general_info.lead_form', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('accounts.edit.leadForm') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.created_method"
                                @input="updateClientCardsProperty('general_info.created_method', $event)"
                            >
                                {{ $t('accounts.edit.createdMethod') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.source"
                                @input="updateClientCardsProperty('general_info.source', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('accounts.edit.source') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.division"
                                @input="updateClientCardsProperty('general_info.division', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('accounts.edit.division') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.comment"
                                @input="updateClientCardsProperty('general_info.comment', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('accounts.edit.comment') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.campaign"
                                @input="updateClientCardsProperty('general_info.campaign', $event)"
                            >
                                {{ $t('accounts.edit.campaign') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.segment"
                                @input="updateClientCardsProperty('general_info.segment', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('accounts.edit.segment') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.storage"
                                @input="updateClientCardsProperty('general_info.storage', $event)"
                            >
                                {{ $t('accounts.edit.storage') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.client_number"
                                @input="updateClientCardsProperty('general_info.client_number', $event)"
                            >
                                {{ $t('accounts.edit.clientNumber') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.called_count"
                                @input="updateClientCardsProperty('general_info.called_count', $event)"
                            >
                                {{ $t('accounts.edit.calledCount') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.call_date"
                                @input="updateClientCardsProperty('general_info.call_date', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('accounts.edit.callDate') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.prepaid"
                                @input="updateClientCardsProperty('general_info.prepaid', $event)"
                            >
                                {{ $t('accounts.edit.prepaid') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.loyalty"
                                @input="updateClientCardsProperty('general_info.loyalty', $event)"
                            >
                                {{ $t('accounts.edit.loyalty') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.keyword"
                                @input="updateClientCardsProperty('general_info.keyword', $event)"
                            >
                                {{ $t('clientCard.keyword') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.navigation_history"
                                @input="updateClientCardsProperty('general_info.navigation_history', $event)"
                            >
                                {{ $t('clientCard.navigationHistory') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.referrer"
                                @input="updateClientCardsProperty('general_info.referrer', $event)"
                            >
                                {{ $t('clientCard.referrer') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsGeneralInfo.search_term"
                                @input="updateClientCardsProperty('general_info.search_term', $event)"
                            >
                                {{ $t('clientCard.searchTerm') }}
                            </activix-switcher>
                        </div>
                    </div>

                    <!-- Performance fields -->
                    <div class="w-1/2 px-2 | xl:w-1/4">
                        <div class="mb-4 mt-6 xl:mt-0">
                            <label>{{ $t('accounts.edit.performance') }}</label>
                        </div>
                        <div :class="{ highlight: hoverOptions.power_sport }">
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.qualification"
                                @input="updateClientCardsProperty('performance.qualification', $event)"
                            >
                                {{ $t('accounts.edit.qualification') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.dealer_tour"
                                @input="updateClientCardsProperty('performance.dealer_tour', $event)"
                            >
                                {{ $t('clientCard.dealerTour') }}
                            </activix-switcher>
                        </div>
                        <div :class="{ highlight: hoverOptions.power_sport }">
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.walk_around"
                                @input="updateClientCardsProperty('performance.walk_around', $event)"
                            >
                                {{ $t('accounts.edit.walkAround') }}
                            </activix-switcher>
                        </div>
                        <div :class="{ highlight: hoverOptions.renewal }">
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.refinanced"
                                @input="updateClientCardsProperty('performance.refinanced', $event)"
                            >
                                {{ $t('accounts.edit.refinanced') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.deposit"
                                @input="updateClientCardsProperty('performance.deposit', $event)"
                            >
                                {{ $t('clientCard.deposit') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.write_up"
                                @input="updateClientCardsProperty('performance.write_up', $event)"
                            >
                                {{ $t('clientCard.writeUp') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.twenty_four_hour"
                                @input="updateClientCardsProperty('performance.twenty_four_hour', $event)"
                            >
                                {{ $t('clientCard.twenty_four_hour') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.phone_appointment"
                                @input="updateClientCardsProperty('performance.phone_appointment', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('clientCard.phoneAppointment') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.appointment"
                                @input="updateClientCardsProperty('performance.appointment', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('clientCard.appointment') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.presented"
                                @input="updateClientCardsProperty('performance.presented', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('clientCard.presented') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.sale_by_phone"
                                @input="updateClientCardsProperty('performance.sale_by_phone', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('clientCard.saleByPhone') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.sale"
                                @input="updateClientCardsProperty('performance.sale', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('clientCard.sale') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.renew"
                                @input="updateClientCardsProperty('performance.renew', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('clientCard.renewal') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.road_test"
                                @input="updateClientCardsProperty('performance.road_test', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $tc('clientCard.roadTest') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.take_over"
                                @input="updateClientCardsProperty('performance.take_over', $event)"
                            >
                                {{ $t('clientCard.takeOver') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.be_back"
                                @input="updateClientCardsProperty('performance.be_back', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('clientCard.beBack') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsPerformance.long_term"
                                @input="updateClientCardsProperty('performance.long_term', $event)"
                                v-if="contextAccount.isPolestar()"
                            >
                                {{ $t('clientCard.longTerm') }}
                            </activix-switcher>
                        </div>
                    </div>

                    <!-- Process fields -->
                    <div class="w-1/2 px-2 | xl:w-1/4">
                        <div class="mb-4 mt-6 xl:mt-0">
                            <label>{{ $t('accounts.edit.process') }}</label>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.available"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.available', $event)"
                            >
                                {{ $t('accounts.edit.processFields.available') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.deliverable"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.deliverable', $event)"
                            >
                                {{ $t('accounts.edit.processFields.deliverable') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.prepared_work_order"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.prepared_work_order', $event)"
                            >
                                {{ $t('accounts.edit.processFields.prepared_work_order') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-tooltip :content="getDisabledTooltip('process_paperwork')">
                                <activix-switcher
                                    class="mb-4 h-6"
                                    :value="clientFieldsProcess.paperwork"
                                    :disabled="!authUser.isAdmin()"
                                    @input="updateClientCardsProperty('process.paperwork', $event)"
                                >
                                    {{ $t('accounts.edit.processFields.paperwork') }}
                                </activix-switcher>
                            </activix-tooltip>
                        </div>
                        <div :class="{ highlight: hoverOptions.process }">
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.gas"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.gas', $event)"
                            >
                                {{ $t('accounts.edit.processFields.gas') }}
                            </activix-switcher>
                        </div>
                        <div :class="{ highlight: hoverOptions.sale_table }">
                            <activix-tooltip :content="getDisabledTooltip('process_recorded')">
                                <activix-switcher
                                    class="mb-4 h-6"
                                    :value="clientFieldsProcess.recorded"
                                    :disabled="!authUser.isAdmin() || currentForm.sale_table"
                                    @input="updateClientCardsProperty('process.recorded', $event)"
                                >
                                    {{ $t('accounts.edit.processFields.recorded') }}
                                </activix-switcher>
                            </activix-tooltip>
                        </div>
                        <div :class="{ highlight: hoverOptions.sale_table }">
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.discounted"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.discounted', $event)"
                            >
                                {{ $t('accounts.edit.processFields.discounted') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.lead_bank"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.lead_bank', $event)"
                            >
                                {{ $t('accounts.edit.processFields.leadBank') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.progress_state"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.progress_state', $event)"
                            >
                                {{ $t('accounts.edit.processFields.progressState') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.approved"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.approved', $event)"
                            >
                                {{ $t('accounts.edit.processFields.approved') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.delivery"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.delivery', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('accounts.edit.processFields.delivery') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.waiting_sale"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.waiting_sale', $event)"
                            >
                                {{ $t('accounts.edit.processFields.waitingSale') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.prepared"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.prepared', $event)"
                            >
                                {{ $t('accounts.edit.processFields.prepared') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.inspected"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.inspected', $event)"
                            >
                                {{ $t('accounts.edit.processFields.inspected') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.delivered"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.delivered', $event)"
                                v-if="contextAccount.isCustom()"
                            >
                                {{ $t('accounts.edit.processFields.delivered') }}
                            </activix-switcher>
                        </div>
                        <div>
                            <activix-switcher
                                class="mb-4 h-6"
                                :value="clientFieldsProcess.csi"
                                :disabled="!authUser.isAdmin()"
                                @input="updateClientCardsProperty('process.csi', $event)"
                            >
                                {{ $t('accounts.edit.processFields.csi') }}
                            </activix-switcher>
                        </div>
                    </div>
                </div>

                <hr class="my-6" />

                <div class="flex flex-wrap -mx-2">
                    <div class="w-full px-2 | xl:w-1/2">
                        <div class="mb-4">
                            <label>{{ $t('accounts.edit.wantedVehicles') }}</label>
                        </div>

                        <div class="flex -mx-2">
                            <!-- Wanted vehicle column 1 -->
                            <div class="w-1/2 px-2">
                                <div
                                    :class="{ highlight: hoverOptions.power_sport || hoverOptions.recreative_special }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.category"
                                        @input="updateClientCardsProperty('wanted_vehicle.category', $event)"
                                    >
                                        {{ $t('accounts.edit.vehicleCategory') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.category_rv"
                                        @input="updateClientCardsProperty('wanted_vehicle.category_rv', $event)"
                                    >
                                        {{ $t('accounts.edit.vehicleCategoryRV') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }" v-if="contextAccount.hasWantedVehicleField('budget')">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.budget"
                                        @input="updateClientCardsProperty('wanted_vehicle.budget', $event)"
                                    >
                                        {{ $t('accounts.edit.budget') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }" v-if="contextAccount.hasWantedVehicleField('year')">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.year"
                                        @input="updateClientCardsProperty('wanted_vehicle.year', $event)"
                                    >
                                        {{ $t('accounts.edit.year') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.certified"
                                        @input="updateClientCardsProperty('wanted_vehicle.certified', $event)"
                                    >
                                        {{ $t('accounts.edit.certified') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.transmission"
                                        @input="updateClientCardsProperty('wanted_vehicle.transmission', $event)"
                                    >
                                        {{ $t('accounts.edit.transmission') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.fuel"
                                        @input="updateClientCardsProperty('wanted_vehicle.fuel', $event)"
                                    >
                                        {{ $t('accounts.edit.fuel') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.sale_table }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.offer_number"
                                        @input="updateClientCardsProperty('wanted_vehicle.offer_number', $event)"
                                    >
                                        {{ $t('accounts.edit.offerNumber') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.sale_table }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.suffix"
                                        @input="updateClientCardsProperty('wanted_vehicle.suffix', $event)"
                                    >
                                        {{ $t('accounts.edit.suffix') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.order_number"
                                        @input="updateClientCardsProperty('wanted_vehicle.order_number', $event)"
                                    >
                                        {{ $t('accounts.edit.orderNumber') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.sale_accessories }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.tire"
                                        @input="updateClientCardsProperty('wanted_vehicle.tire', $event)"
                                    >
                                        {{ $t('accounts.edit.tires') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.modality"
                                        :disabled="currentForm.renewal || currentForm.auto_renewal"
                                        @input="updateClientCardsProperty('wanted_vehicle.modality', $event)"
                                    >
                                        {{ $t('accounts.edit.modality') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.frequency"
                                        @input="updateClientCardsProperty('wanted_vehicle.frequency', $event)"
                                    >
                                        {{ $t('accounts.edit.frequency') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.rate"
                                        @input="updateClientCardsProperty('wanted_vehicle.rate', $event)"
                                    >
                                        {{ $t('accounts.edit.rate') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.initial_cash"
                                        @input="updateClientCardsProperty('wanted_vehicle.initial_cash', $event)"
                                    >
                                        {{ $t('accounts.edit.initialCash') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.allowed_mileage"
                                        @input="updateClientCardsProperty('wanted_vehicle.allowed_mileage', $event)"
                                    >
                                        {{ $t('accounts.edit.allowedMileage') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-tooltip
                                        :content="
                                            currentForm.stock_required_for_sale
                                                ? $t('accounts.edit.stockDisabledStockRequiredForSale')
                                                : ''
                                        "
                                    >
                                        <activix-switcher
                                            class="mb-4 h-6"
                                            :value="clientFieldsWantedVehicle.stock"
                                            :disabled="currentForm.stock_required_for_sale"
                                            @input="updateClientCardsProperty('wanted_vehicle.stock', $event)"
                                        >
                                            {{ $t('accounts.edit.stock') }}
                                        </activix-switcher>
                                    </activix-tooltip>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.vin"
                                        @input="updateClientCardsProperty('wanted_vehicle.vin', $event)"
                                    >
                                        {{ $t('accounts.edit.vin') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.engine"
                                        @input="updateClientCardsProperty('wanted_vehicle.engine', $event)"
                                    >
                                        {{ $t('accounts.edit.engine') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.bodyType"
                                        @input="updateClientCardsProperty('wanted_vehicle.bodyType', $event)"
                                    >
                                        {{ $t('accounts.edit.bodyType') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal || hoverOptions.service }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.end_warranty_date"
                                        @input="updateClientCardsProperty('wanted_vehicle.end_warranty_date', $event)"
                                    >
                                        {{ $t('accounts.edit.endWarrantyDate') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.end_warranty_mileage"
                                        @input="updateClientCardsProperty('wanted_vehicle.end_warranty_mileage', $event)"
                                    >
                                        {{ $t('accounts.edit.endWarrantyMileage') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.end_extended_warranty_date"
                                        @input="updateClientCardsProperty('wanted_vehicle.end_extended_warranty_date', $event)"
                                    >
                                        {{ $t('accounts.edit.endExtendedWarrantyDate') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.end_extended_warranty_mileage"
                                        @input="updateClientCardsProperty('wanted_vehicle.end_extended_warranty_mileage', $event)"
                                    >
                                        {{ $t('accounts.edit.endExtendedWarrantyMileage') }}
                                    </activix-switcher>
                                </div>
                            </div>

                            <!-- Wanted vehicle column 2 -->
                            <div class="w-1/2 px-2">
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.color_exterior"
                                        @input="updateClientCardsProperty('wanted_vehicle.color_exterior', $event)"
                                    >
                                        {{ $t('accounts.edit.colorExterior') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.color_interior"
                                        @input="updateClientCardsProperty('wanted_vehicle.color_interior', $event)"
                                    >
                                        {{ $t('accounts.edit.colorInterior') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.weight"
                                        @input="updateClientCardsProperty('wanted_vehicle.weight', $event)"
                                    >
                                        {{ $t('accounts.edit.weight') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.length"
                                        @input="updateClientCardsProperty('wanted_vehicle.length', $event)"
                                    >
                                        {{ $t('accounts.edit.length') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.sleeping"
                                        @input="updateClientCardsProperty('wanted_vehicle.sleeping', $event)"
                                    >
                                        {{ $t('accounts.edit.sleeping') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.price"
                                        @input="updateClientCardsProperty('wanted_vehicle.price', $event)"
                                    >
                                        {{ $t('accounts.edit.price') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.commercial ||
                                            hoverOptions.power_sport ||
                                            hoverOptions.sale_accessories,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.accessories"
                                        @input="updateClientCardsProperty('wanted_vehicle.accessories', $event)"
                                    >
                                        {{ $t('accounts.edit.accessories') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.power_sport }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.preparation"
                                        @input="updateClientCardsProperty('wanted_vehicle.preparation', $event)"
                                    >
                                        {{ $t('accounts.edit.preparation') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.power_sport }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.documentation"
                                        @input="updateClientCardsProperty('wanted_vehicle.documentation', $event)"
                                    >
                                        {{ $t('accounts.edit.documentation') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.total"
                                        @input="updateClientCardsProperty('wanted_vehicle.total', $event)"
                                    >
                                        {{ $t('accounts.edit.wantedVehicleTotal') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.term"
                                        @input="updateClientCardsProperty('wanted_vehicle.term', $event)"
                                    >
                                        {{ $t('accounts.edit.term') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.payment"
                                        @input="updateClientCardsProperty('wanted_vehicle.payment', $event)"
                                    >
                                        {{ $t('accounts.edit.payment') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.payment_with_tax"
                                        @input="updateClientCardsProperty('wanted_vehicle.payment_with_tax', $event)"
                                    >
                                        {{ $t('accounts.edit.paymentWithTax') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.profit"
                                        @input="updateClientCardsProperty('wanted_vehicle.profit', $event)"
                                    >
                                        {{ $t('accounts.edit.profit') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }" v-if="contextAccount.hasWantedVehicleField('warranty')">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.warranty"
                                        @input="updateClientCardsProperty('wanted_vehicle.warranty', $event)"
                                    >
                                        {{ $t('accounts.edit.warranty') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal || hoverOptions.commercial }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.end_contract_date"
                                        :disabled="currentForm.renewal || currentForm.auto_renewal"
                                        @input="updateClientCardsProperty('wanted_vehicle.end_contract_date', $event)"
                                    >
                                        {{ $t('accounts.edit.contractEnd') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.security_deposit"
                                        @input="updateClientCardsProperty('wanted_vehicle.security_deposit', $event)"
                                    >
                                        {{ $t('accounts.edit.securityDeposit') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.residual"
                                        @input="updateClientCardsProperty('wanted_vehicle.residual', $event)"
                                    >
                                        {{ $t('accounts.edit.residual') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.comment }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="currentForm.client_card_fields.wanted_vehicle.comment"
                                        @input="updateClientCardsProperty('wanted_vehicle.comment', $event)"
                                    >
                                        {{ $t('accounts.edit.comment') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="currentForm.client_card_fields.wanted_vehicle.mileage"
                                        @input="updateClientCardsProperty('wanted_vehicle.mileage', $event)"
                                    >
                                        {{ $t('accounts.edit.mileage') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.drivingWheels"
                                        @input="updateClientCardsProperty('wanted_vehicle.drivingWheels', $event)"
                                    >
                                        {{ $t('accounts.edit.drivingWheels') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.in_service_date"
                                        @input="updateClientCardsProperty('wanted_vehicle.in_service_date', $event)"
                                    >
                                        {{ $t('clientCard.vehicles.in_service_date') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsWantedVehicle.warranty_type"
                                        @input="updateClientCardsProperty('wanted_vehicle.warranty_type', $event)"
                                    >
                                        {{ $t('clientCard.vehicles.warranty_type') }}
                                    </activix-switcher>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-full px-2 | xl:w-1/2">
                        <div class="mb-4 mt-6 | xl:mt-0">
                            <label>{{ $t('accounts.edit.currentVehicles') }}</label>
                        </div>

                        <div class="flex -mx-2">
                            <!-- Exchange vehicle column 1 -->
                            <div class="w-1/2 px-2">
                                <div
                                    :class="{ highlight: hoverOptions.power_sport || hoverOptions.recreative_special }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.category"
                                        @input="updateClientCardsProperty('exchange_vehicle.category', $event)"
                                    >
                                        {{ $t('accounts.edit.vehicleCategory') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.category_rv"
                                        @input="updateClientCardsProperty('exchange_vehicle.category_rv', $event)"
                                    >
                                        {{ $t('accounts.edit.vehicleCategoryRV') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.sale_table || hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.mileage"
                                        @input="updateClientCardsProperty('exchange_vehicle.mileage', $event)"
                                    >
                                        {{ $t('accounts.edit.mileage') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }" v-if="contextAccount.hasExchangeVehicleField('budget')">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.budget"
                                        @input="updateClientCardsProperty('exchange_vehicle.budget', $event)"
                                    >
                                        {{ $t('accounts.edit.budget') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.certified"
                                        @input="updateClientCardsProperty('exchange_vehicle.certified', $event)"
                                    >
                                        {{ $t('accounts.edit.certified') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.trade_report }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.condition"
                                        @input="updateClientCardsProperty('exchange_vehicle.condition', $event)"
                                    >
                                        {{ $t('accounts.edit.condition') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.link"
                                        @input="updateClientCardsProperty('exchange_vehicle.link', $event)"
                                    >
                                        {{ $t('accounts.edit.link') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.modality"
                                        @input="updateClientCardsProperty('exchange_vehicle.modality', $event)"
                                    >
                                        {{ $t('accounts.edit.modality') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.frequency"
                                        @input="updateClientCardsProperty('exchange_vehicle.frequency', $event)"
                                    >
                                        {{ $t('accounts.edit.frequency') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.rate"
                                        @input="updateClientCardsProperty('exchange_vehicle.rate', $event)"
                                    >
                                        {{ $t('accounts.edit.rate') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.price"
                                        @input="updateClientCardsProperty('exchange_vehicle.price', $event)"
                                    >
                                        {{ $t('accounts.edit.price') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.initial_cash"
                                        @input="updateClientCardsProperty('exchange_vehicle.initial_cash', $event)"
                                    >
                                        {{ $t('accounts.edit.initialCash') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.allowed_mileage"
                                        @input="updateClientCardsProperty('exchange_vehicle.allowed_mileage', $event)"
                                    >
                                        {{ $t('accounts.edit.allowedMileage') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.institution"
                                        @input="updateClientCardsProperty('exchange_vehicle.institution', $event)"
                                    >
                                        {{ $t('accounts.edit.institution') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.intention"
                                        @input="updateClientCardsProperty('exchange_vehicle.intention', $event)"
                                    >
                                        {{ $t('accounts.edit.intention') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.sold_by"
                                        @input="updateClientCardsProperty('exchange_vehicle.sold_by', $event)"
                                    >
                                        {{ $t('accounts.edit.soldBy') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="currentForm.client_card_fields.exchange_vehicle.offer_number"
                                        @input="updateClientCardsProperty('exchange_vehicle.offer_number', $event)"
                                    >
                                        {{ $t('accounts.edit.offerNumber') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.trade_report }">
                                    <activix-tooltip :content="getDisabledTooltip('accidented_damage_cost')">
                                        <activix-switcher
                                            class="mb-4 h-6"
                                            :value="
                                                currentForm.client_card_fields.exchange_vehicle.accidented_damage_cost
                                            "
                                            :disabled="!currentForm.trade_report"
                                            @input="
                                                updateClientCardsProperty(
                                                    'exchange_vehicle.accidented_damage_cost',
                                                    $event,
                                                )
                                            "
                                        >
                                            {{ $t('accounts.edit.accidented_damage_cost') }}
                                        </activix-switcher>
                                    </activix-tooltip>
                                </div>
                                <div :class="{ highlight: hoverOptions.trade_report }">
                                    <activix-tooltip :content="getDisabledTooltip('tire_type')">
                                        <activix-switcher
                                            class="mb-4 h-6"
                                            :value="currentForm.client_card_fields.exchange_vehicle.tire_type"
                                            :disabled="!currentForm.trade_report"
                                            @input="updateClientCardsProperty('exchange_vehicle.tire_type', $event)"
                                        >
                                            {{ $t('accounts.edit.tire_type') }}
                                        </activix-switcher>
                                    </activix-tooltip>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.engine"
                                        @input="updateClientCardsProperty('exchange_vehicle.engine', $event)"
                                    >
                                        {{ $t('accounts.edit.engine') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.bodyType"
                                        @input="updateClientCardsProperty('exchange_vehicle.bodyType', $event)"
                                    >
                                        {{ $t('accounts.edit.bodyType') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal || hoverOptions.service }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.end_warranty_date"
                                        @input="updateClientCardsProperty('exchange_vehicle.end_warranty_date', $event)"
                                    >
                                        {{ $t('accounts.edit.endWarrantyDate') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.end_warranty_mileage"
                                        @input="updateClientCardsProperty('exchange_vehicle.end_warranty_mileage', $event)"
                                    >
                                        {{ $t('accounts.edit.endWarrantyMileage') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.end_extended_warranty_date"
                                        @input="updateClientCardsProperty('exchange_vehicle.end_extended_warranty_date', $event)"
                                    >
                                        {{ $t('accounts.edit.endExtendedWarrantyDate') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.end_extended_warranty_mileage"
                                        @input="updateClientCardsProperty('exchange_vehicle.end_extended_warranty_mileage', $event)"
                                    >
                                        {{ $t('accounts.edit.endExtendedWarrantyMileage') }}
                                    </activix-switcher>
                                </div>
                            </div>

                            <!-- Exchange vehicle column 2 -->
                            <div class="w-1/2 px-2">
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.sold_date"
                                        @input="updateClientCardsProperty('exchange_vehicle.sold_date', $event)"
                                    >
                                        {{ $t('accounts.edit.soldDate') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.stock"
                                        @input="updateClientCardsProperty('exchange_vehicle.stock', $event)"
                                    >
                                        {{ $t('accounts.edit.stock') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.vin"
                                        @input="updateClientCardsProperty('exchange_vehicle.vin', $event)"
                                    >
                                        {{ $t('accounts.edit.vin') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.color_exterior"
                                        @input="updateClientCardsProperty('exchange_vehicle.color_exterior', $event)"
                                    >
                                        {{ $t('accounts.edit.colorExterior') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.color_interior"
                                        @input="updateClientCardsProperty('exchange_vehicle.color_interior', $event)"
                                    >
                                        {{ $t('accounts.edit.colorInterior') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }" v-if="contextAccount.hasExchangeVehicleField('year')">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.year"
                                        @input="updateClientCardsProperty('exchange_vehicle.year', $event)"
                                    >
                                        {{ $t('accounts.edit.year') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.transmission"
                                        @input="updateClientCardsProperty('exchange_vehicle.transmission', $event)"
                                    >
                                        {{ $t('accounts.edit.transmission') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.fuel"
                                        @input="updateClientCardsProperty('exchange_vehicle.fuel', $event)"
                                    >
                                        {{ $t('accounts.edit.fuel') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.weight"
                                        @input="updateClientCardsProperty('exchange_vehicle.weight', $event)"
                                    >
                                        {{ $t('accounts.edit.weight') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.length"
                                        @input="updateClientCardsProperty('exchange_vehicle.length', $event)"
                                    >
                                        {{ $t('accounts.edit.length') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.recreative_special }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.sleeping"
                                        @input="updateClientCardsProperty('exchange_vehicle.sleeping', $event)"
                                    >
                                        {{ $t('accounts.edit.sleeping') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.sale_table || hoverOptions.power_sport }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.requested"
                                        @input="updateClientCardsProperty('exchange_vehicle.requested', $event)"
                                    >
                                        {{ $t('accounts.edit.wantedMoney') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport ||
                                            hoverOptions.trade_report,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.value"
                                        @input="updateClientCardsProperty('exchange_vehicle.value', $event)"
                                    >
                                        {{ $t('accounts.edit.worth') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.term"
                                        @input="updateClientCardsProperty('exchange_vehicle.term', $event)"
                                    >
                                        {{ $t('accounts.edit.term') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.payment"
                                        @input="updateClientCardsProperty('exchange_vehicle.payment', $event)"
                                    >
                                        {{ $t('accounts.edit.payment') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.payment_with_tax"
                                        @input="updateClientCardsProperty('exchange_vehicle.payment_with_tax', $event)"
                                    >
                                        {{ $t('accounts.edit.paymentWithTax') }}
                                    </activix-switcher>
                                </div>
                                <div
                                    :class="{
                                        highlight:
                                            hoverOptions.renewal ||
                                            hoverOptions.commercial ||
                                            hoverOptions.sale_table ||
                                            hoverOptions.power_sport,
                                    }"
                                >
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.profit"
                                        @input="updateClientCardsProperty('exchange_vehicle.profit', $event)"
                                    >
                                        {{ $t('accounts.edit.profit') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }" v-if="contextAccount.hasExchangeVehicleField('warranty')">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.warranty"
                                        @input="updateClientCardsProperty('exchange_vehicle.warranty', $event)"
                                    >
                                        {{ $t('accounts.edit.warranty') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.end_contract_date"
                                        @input="updateClientCardsProperty('exchange_vehicle.end_contract_date', $event)"
                                    >
                                        {{ $t('accounts.edit.contractEnd') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.security_deposit"
                                        @input="updateClientCardsProperty('exchange_vehicle.security_deposit', $event)"
                                    >
                                        {{ $t('accounts.edit.securityDeposit') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal || hoverOptions.power_sport }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.balance"
                                        @input="updateClientCardsProperty('exchange_vehicle.balance', $event)"
                                    >
                                        {{ $t('accounts.edit.balance') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.renewal }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.residual"
                                        @input="updateClientCardsProperty('exchange_vehicle.residual', $event)"
                                    >
                                        {{ $t('accounts.edit.residual') }}
                                    </activix-switcher>
                                </div>
                                <div :class="{ highlight: hoverOptions.comment }">
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="currentForm.client_card_fields.exchange_vehicle.comment"
                                        @input="updateClientCardsProperty('exchange_vehicle.comment', $event)"
                                    >
                                        {{ $t('accounts.edit.comment') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.drivingWheels"
                                        @input="updateClientCardsProperty('exchange_vehicle.drivingWheels', $event)"
                                    >
                                        {{ $t('accounts.edit.drivingWheels') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.in_service_date"
                                        @input="updateClientCardsProperty('exchange_vehicle.in_service_date', $event)"
                                    >
                                        {{ $t('clientCard.vehicles.in_service_date') }}
                                    </activix-switcher>
                                </div>
                                <div>
                                    <activix-switcher
                                        class="mb-4 h-6"
                                        :value="clientFieldsExchangeVehicle.warranty_type"
                                        @input="updateClientCardsProperty('exchange_vehicle.warranty_type', $event)"
                                    >
                                        {{ $t('clientCard.vehicles.warranty_type') }}
                                    </activix-switcher>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <activation-date :opened.sync="activationDateModalOpened" />
        <facebook-modal :opened.sync="facebookModalOpened" />
        <phone-provider-modal
            :opened.sync="phoneProviderModalOpened"
            :account="currentForm"
            :related-data="relatedData"
            v-if="authUser.isAdmin()"
        />

        <activix-confirm-modal
            type="warning"
            :content="$t('accounts.edit.inactiveWillDeletePhonesText')"
            :opened.sync="inactiveModalOpened"
            @approve="triggerInactive"
        />

        <activix-confirm-modal
            type="warning"
            :content="$t('accounts.edit.serviceDisabled')"
            :opened.sync="serviceModalOpened"
            @approve="triggerServiceDisabled"
        />

        <custom-fields-modal
            :opened.sync="customFieldsModalOpened"
            :field="selectedField"
            @update-custom-field="updateCustomField"
        />

        <activix-confirm-modal
            type="warning"
            portal="modal-2"
            :title="$t('general.areYouSure')"
            :content="$t('accounts.edit.customFields.deleteModal.description', [deleteCustomFieldModal.count])"
            :approve-text="$t('accounts.edit.customFields.deleteModal.confirm')"
            :deny-text="$t('general.cancel')"
            :opened.sync="deleteCustomFieldModal.opened"
            @approve="deleteCustomFieldModal.openedConfirm = true"
            @cancel="deleteCustomFieldModal.fieldId = null"
        />

        <activix-confirm-modal
            type="danger"
            :title="$t('general.areYouSure')"
            :content="$t('accounts.edit.customFields.deleteModal.step2Description')"
            :approve-text="$t('accounts.edit.customFields.deleteModal.confirm')"
            :deny-text="$t('general.cancel')"
            :opened.sync="deleteCustomFieldModal.openedConfirm"
            @approve="onCustomFieldDelete"
            @cancel="deleteCustomFieldModal.fieldId = null"
        />

        <activix-confirm-modal
            type="warning"
            portal="modal-2"
            :title="$t('accounts.edit.customFields.retroactiveModal.title')"
            :content="$t('accounts.edit.customFields.retroactiveModal.description')"
            :approve-text="$t('accounts.edit.customFields.retroactiveModal.confirm')"
            :deny-text="$t('general.no')"
            :opened.sync="retroactiveCustomFieldModal.opened"
            @approve="onAcceptRetroactiveDefaultValueCreation"
            @cancel="retroactiveCustomFieldModal.fieldId = null"
        />
    </div>
</template>

<script>
    import { camelCase, cloneDeep, orderBy, range, set } from 'lodash-es';
    import draggable from 'vuedraggable';
    import { mapState, mapActions } from 'pinia';
    import { randomString } from '../../utils/index.js';
    import CommunicationType from '../../entities/CommunicationType.js';

    // Components
    import PhoneProvider from './PhoneProvider.vue';
    import PhoneProviderEntity from '../../entities/PhoneProvider.js';
    import PhoneProviderModal from '../modals/PhoneProvider.vue';
    import ExternalPhoneProvider from './ExternalPhoneProvider.vue';
    import ActivationDate from '../modals/ActivationDate.vue';
    import Blacklist from './Blacklist.vue';
    import CustomFieldsModal from '../modals/CustomFieldsModal.vue';
    import FacebookModal from '../modals/FacebookModal.vue';
    import ImapExclusions from './ImapExclusions.vue';

    // Entities
    import Supplier from '../../entities/Supplier.js';
    import Locale from '../../entities/Locale.js';
    import ClientCardSection from '../../entities/ClientCardSection.js';
    import DropdownButton from '../buttons/DropdownButton.vue';

    // Pinia
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            ImapExclusions,
            DropdownButton,
            draggable,
            PhoneProvider,
            PhoneProviderModal,
            ActivationDate,
            Blacklist,
            FacebookModal,
            CustomFieldsModal,
            ExternalPhoneProvider,
        },

        props: {
            relatedData: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                logoFrData: '',
                logoEnData: '',
                facebookModalOpened: false,
                activationDateModalOpened: false,
                phoneProviderModalOpened: false,
                inactiveModalOpened: false,
                serviceModalOpened: false,
                crmMax: false,
                logoFrPopoverVisible: false,
                logoEnPopoverVisible: false,
                saleDepartment: false,
                saleOptions: false,
                updatingCrm: false,
                updatingSale: false,
                updatingOptions: false,
                hoverOptions: {
                    renewal: false,
                    commercial: false,
                    sale_table: false,
                    power_sport: false,
                    sale_accessories: false,
                    recreative_special: false,
                    trade_report: false,
                    process: false,
                    service: false,
                    comment: false,
                    web_order: false,
                },
                callerIdOptions: [
                    {
                        value: 1,
                        label: this.$t('accounts.edit.dealer'),
                    },
                    {
                        value: 2,
                        label: this.$t('accounts.edit.user'),
                    },
                ],
                fieldOptions: {
                    web_order: [
                        ['crm', 'leadxpress'],
                        ['crm', 'webboost'],
                    ],
                    renewal: [
                        ['wanted_vehicle', 'color'],
                        ['wanted_vehicle', 'certified'],
                        ['wanted_vehicle', 'transmission'],
                        ['wanted_vehicle', 'modality'],
                        ['wanted_vehicle', 'end_contract_date'],
                        ['wanted_vehicle', 'term'],
                        ['wanted_vehicle', 'frequency'],
                        ['wanted_vehicle', 'price'],
                        ['wanted_vehicle', 'payment'],
                        ['wanted_vehicle', 'rate'],
                        ['wanted_vehicle', 'profit'],
                        ['wanted_vehicle', 'total'],
                        ['wanted_vehicle', 'security_deposit'],
                        ['wanted_vehicle', 'residual'],
                        ['wanted_vehicle', 'allowed_mileage'],
                        ['wanted_vehicle', 'warranty'],
                        ['wanted_vehicle', 'initial_cash'],
                        ['wanted_vehicle', 'end_warranty_date'],
                        ['wanted_vehicle', 'in_service_date'],
                        ['wanted_vehicle', 'warranty_type'],

                        ['exchange_vehicle', 'value'],
                        ['exchange_vehicle', 'balance'],
                        ['exchange_vehicle', 'end_contract_date'],
                        ['exchange_vehicle', 'security_deposit'],
                        ['exchange_vehicle', 'payment'],
                        ['exchange_vehicle', 'residual'],
                        ['exchange_vehicle', 'allowed_mileage'],
                        ['exchange_vehicle', 'intention'],
                        ['exchange_vehicle', 'term'],
                        ['exchange_vehicle', 'color'],
                        ['exchange_vehicle', 'certified'],
                        ['exchange_vehicle', 'transmission'],
                        ['exchange_vehicle', 'frequency'],
                        ['exchange_vehicle', 'institution'],
                        ['exchange_vehicle', 'sold_by'],
                        ['exchange_vehicle', 'sold_date'],
                        ['exchange_vehicle', 'warranty'],
                        ['exchange_vehicle', 'mileage'],
                        ['exchange_vehicle', 'modality'],
                        ['exchange_vehicle', 'rate'],
                        ['exchange_vehicle', 'initial_cash'],
                        ['exchange_vehicle', 'end_warranty_date'],
                        ['exchange_vehicle', 'in_service_date'],
                        ['exchange_vehicle', 'warranty_type'],

                        ['performance', 'refinanced'],
                    ],
                    service: [
                        ['wanted_vehicle', 'end_warranty_date'],
                        ['exchange_vehicle', 'end_warranty_date'],
                    ],
                    commercial: [
                        ['wanted_vehicle', 'color'],
                        ['wanted_vehicle', 'transmission'],
                        ['wanted_vehicle', 'modality'],
                        ['wanted_vehicle', 'end_contract_date'],
                        ['wanted_vehicle', 'term'],
                        ['wanted_vehicle', 'frequency'],
                        ['wanted_vehicle', 'accessories'],
                        ['wanted_vehicle', 'price'],
                        ['wanted_vehicle', 'payment'],
                        ['wanted_vehicle', 'rate'],
                        ['wanted_vehicle', 'profit'],
                        ['wanted_vehicle', 'total'],
                        ['wanted_vehicle', 'stock'],
                    ],
                    sale_table: [
                        ['wanted_vehicle', 'color'],
                        ['wanted_vehicle', 'transmission'],
                        ['wanted_vehicle', 'offer_number'],
                        ['wanted_vehicle', 'suffix'],
                        ['wanted_vehicle', 'modality'],
                        ['wanted_vehicle', 'term'],
                        ['wanted_vehicle', 'frequency'],
                        ['wanted_vehicle', 'price'],
                        ['wanted_vehicle', 'payment'],
                        ['wanted_vehicle', 'rate'],
                        ['wanted_vehicle', 'profit'],
                        ['wanted_vehicle', 'total'],
                        ['wanted_vehicle', 'stock'],

                        ['exchange_vehicle', 'value'],
                        ['exchange_vehicle', 'requested'],
                        ['exchange_vehicle', 'mileage'],
                        ['exchange_vehicle', 'offer_number'],
                        ['exchange_vehicle', 'suffix'],

                        ['options', 'process'],
                        ['options', 'csi'],

                        ['process', 'recorded'],
                    ],
                    power_sport: [
                        ['wanted_vehicle', 'modality'],
                        ['wanted_vehicle', 'term'],
                        ['wanted_vehicle', 'frequency'],
                        ['wanted_vehicle', 'accessories'],
                        ['wanted_vehicle', 'preparation'],
                        ['wanted_vehicle', 'documentation'],
                        ['wanted_vehicle', 'price'],
                        ['wanted_vehicle', 'payment'],
                        ['wanted_vehicle', 'rate'],
                        ['wanted_vehicle', 'profit'],
                        ['wanted_vehicle', 'total'],
                        ['wanted_vehicle', 'color'],
                        ['wanted_vehicle', 'transmission'],
                        ['wanted_vehicle', 'stock'],
                        ['wanted_vehicle', 'category'],

                        ['exchange_vehicle', 'value'],
                        ['exchange_vehicle', 'requested'],
                        ['exchange_vehicle', 'balance'],
                        ['exchange_vehicle', 'category'],
                        ['exchange_vehicle', 'preparation'],
                        ['wanted_vehicle', 'documentation'],
                        ['wanted_vehicle', 'price'],
                        ['wanted_vehicle', 'payment'],
                        ['wanted_vehicle', 'rate'],
                        ['wanted_vehicle', 'profit'],
                        ['wanted_vehicle', 'total'],
                        ['wanted_vehicle', 'color'],
                        ['wanted_vehicle', 'transmission'],
                        ['wanted_vehicle', 'stock'],

                        ['performance', 'qualification'],
                        ['performance', 'walk_around'],
                    ],
                    recreative_special: [
                        ['wanted_vehicle', 'category'],
                        ['wanted_vehicle', 'category_rv'],
                        ['wanted_vehicle', 'fuel'],
                        ['wanted_vehicle', 'weight'],
                        ['wanted_vehicle', 'length'],
                        ['wanted_vehicle', 'sleeping'],
                        ['wanted_vehicle', 'budget'],
                        ['wanted_vehicle', 'year'],

                        ['exchange_vehicle', 'category'],
                        ['exchange_vehicle', 'category_rv'],
                        ['exchange_vehicle', 'link'],
                        ['exchange_vehicle', 'fuel'],
                        ['exchange_vehicle', 'weight'],
                        ['exchange_vehicle', 'length'],
                        ['exchange_vehicle', 'sleeping'],
                    ],
                    sale_accessories: [
                        ['exchange_vehicle', 'tire'],
                        ['wanted_vehicle', 'tire'],
                        ['wanted_vehicle', 'accessories'],
                    ],
                    trade_report: [
                        ['exchange_vehicle', 'condition'],
                        ['exchange_vehicle', 'value'],
                        ['exchange_vehicle', 'accidented_damage_cost'],
                        ['exchange_vehicle', 'tire_type'],
                    ],
                    process: [
                        ['process', 'available'],
                        ['process', 'gas'],
                        ['process', 'recorded'],
                        ['process', 'paperwork'],
                    ],
                    phone_providers: [
                        {
                            value: 'twilio',
                            label: 'Twilio',
                        },
                        {
                            value: 'tel_synergy',
                            label: 'Tel Synergy',
                        },
                        {
                            value: 'sbk_telecom',
                            label: 'SBK Telecom',
                        },
                        {
                            value: 'purple_cloud',
                            label: 'Purple Cloud',
                        },
                    ],
                },
                selectedField: {},
                customFieldsModalOpened: false,
                deleteCustomFieldModal: {
                    field: null,
                    count: 0,
                    opened: false,
                    openedConfirm: false,
                },
                retroactiveCustomFieldModal: {
                    field: null,
                    opened: false,
                },
                renewalAlertOptionsDefault: {
                    equity_base: 'rough',
                    end_location_notifications: ['P1M', 'P3M', 'P6M', 'P12M'],
                    end_warranty_notifications: ['P1M', 'P3M', 'P6M', 'P12M'],
                    settings: [],
                },
            };
        },

        computed: {
            ...mapState(useAccountCardStore, {
                hasIvr: store => store.currentForm.ivr,
                clientCardFields: store => store.currentForm.client_card_fields,
                clientFieldsGeneralInfo: store => store.currentForm.client_card_fields.general_info,
                clientFieldsPerformance: store => store.currentForm.client_card_fields.performance,
                clientFieldsProcess: store => store.currentForm.client_card_fields.process,
                clientFieldsWantedVehicle: store => store.currentForm.client_card_fields.wanted_vehicle,
                clientFieldsExchangeVehicle: store => store.currentForm.client_card_fields.exchange_vehicle,
                customFields: store => store.currentForm.custom_fields,
                currentForm: 'currentForm',
            }),
            ...mapState(useGlobalStore, ['authUser', 'timezones', 'configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            dnsTooltip() {
                const tooltip = [];

                if (!this.contextAccount.transactional_domain_active) {
                    tooltip.push(this.$t('accounts.edit.dnsTransactionalMessage'));
                }

                if (!this.contextAccount.marketing_domain_active) {
                    tooltip.push(this.$t('accounts.edit.dnsMarketingMessage'));
                }

                if (this.contextAccount.transactional_domain_active && this.contextAccount.marketing_domain_active) {
                    tooltip.push(this.$t('accounts.edit.dnsValidMessage'));
                }

                return tooltip.join('<br>');
            },

            logoFrSrc() {
                return this.logoFrData || this.currentForm.logoFrPath || '/img/logo-placeholder.jpg';
            },

            logoEnSrc() {
                return this.logoEnData || this.currentForm.logoEnPath || '/img/logo-placeholder.jpg';
            },

            teams() {
                return range(1, 11).map(team => {
                    return { value: team, label: `${team} ${this.$tc('accounts.edit.team', team === 0 ? 1 : team).toLowerCase()}` };
                });
            },

            selectedTeams() {
                return this.teams.find(team => team.value == this.currentForm.teams);
            },

            emailSubDomain() {
                if (!this.currentForm.email_domain) {
                    return '';
                }

                return this.currentForm.email_domain.replace(/([^a-z-A-Z-0-1])+/g, '').toLowerCase();
            },

            outboundSuppliers() {
                return this.relatedData.suppliers
                    .filter(supplier => {
                        return supplier.outbound;
                    })
                    .map(supplier => {
                        return {
                            display_name: supplier.display_name,
                            id: supplier.id,
                            inbound: supplier.inbound,
                            name: supplier.name,
                            outbound: supplier.outbound,
                            disabled: this.calculatorOutboundSupplierNeedsToBeDisabled(supplier),
                        };
                    });
            },

            selectedOutboundSuppliers() {
                return [...new Map(this.currentForm.outbound_suppliers.map((supplier) => [supplier.id, supplier])).values()];
            },

            inboundSuppliers() {
                return this.relatedData.suppliers
                    .filter(supplier => {
                        return supplier.inbound;
                    })
                    .map(supplier => {
                        return {
                            display_name: supplier.display_name,
                            id: supplier.id,
                            inbound: supplier.inbound,
                            name: supplier.name,
                            outbound: supplier.outbound,
                            disabled: this.calculatorInboundSupplierNeedsToBeDisabled(supplier),
                        };
                    });
            },

            selectedInboundSuppliers() {
                return [...new Map(this.currentForm.inbound_suppliers.map((supplier) => [supplier.id, supplier])).values()];
            },

            isChildAccount() {
                return this.currentForm.parents.length > 0;
            },

            filteredPossibleChildAccounts() {
                return this.relatedData.accounts.filter(account => {
                    return (
                        account.id != 1 &&
                        account.id != 171 &&
                        account.id != 202 &&
                        account.id != 587 &&
                        account.id != 617 &&
                        account.id != this.currentForm.id &&
                        account.active
                    );
                });
            },

            orderedPhoneProviders() {
                return orderBy(this.currentForm.phone_providers, 'id', 'desc');
            },

            selectedProvince() {
                return this.provinces.find(province => province.value == this.currentForm.province) || [];
            },

            selectedCountry() {
                return this.countries.find(country => country.value == this.currentForm.country) || [];
            },

            selectedTimezone() {
                return this.timezones.find(timezone => timezone.value == this.currentForm.timezone) || [];
            },

            selectedLocale() {
                return this.locales.find(locale => locale.value == this.currentForm.locale) || [];
            },

            provinces() {
                return this.configs.provinces[this.selectedCountry.value].map(province => {
                    return {
                        value: province,
                        text: this.$t(`clientCard.provinces.${province}`),
                    };
                }).sort((a, b) => a.text.localeCompare(b.text));
            },

            countries() {
                return this.configs.countries.map(country => {
                    return {
                        value: country,
                        text: this.$t(`clientCard.countries.${country}`),
                    };
                });
            },

            locales() {
                return Locale.list.map(locale => {
                    return {
                        value: locale.id,
                        text: this.$t(`database.${locale.id}`),
                    };
                });
            },

            mayUpdateTimezone() {
                return this.authUser.isAdmin();
            },

            validIvrNumbers() {
                return this.currentForm.phone_providers.some(phone => {
                    return phone.communication_type_id == 2 && !phone.division && phone.ivr;
                });
            },

            customFieldsFiltered() {
                return this.customFields.filter(field => !field.deleted_at);
            },

            hasAdminPermissions() {
                return !this.authUser.isDirector() && this.authUser.isProgrammer();
            },

            videoconferenceOptionAvailable() {
                return !this.currentForm.children.length;
            },

            accountHasSupportedPhoneProvider() {
                return PhoneProviderEntity.INTEGRATED_PROVIDERS.includes(this.currentForm.phone_provider);
            },

            outgoingPhoneProviders() {
                return this.currentForm.phone_providers.filter(phone => {
                    return phone.communication_type_id == CommunicationType.OUTGOING;
                });
            },

            haveOutgoingInternalPhoneProviders() {
                return this.outgoingPhoneProviders.some(phone => {
                    return PhoneProviderEntity.INTEGRATED_PROVIDERS.includes(phone.phone_provider);
                });
            },

            haveExternalPhoneProviders() {
                return this.currentForm.phone_providers.some(phone => {
                    return !PhoneProviderEntity.INTEGRATED_PROVIDERS.includes(phone.phone_provider);
                });
            },

            phoneExclusionTooltip() {
                if (!this.accountHasSupportedPhoneProvider) {
                    return this.$t('accounts.edit.needTwilioNumber');
                }

                if (!this.validIvrNumbers) {
                    return this.$t('accounts.edit.needIvrNumber');
                }

                return '';
            },
        },

        watch: {
            logoFrPopoverVisible() {
                if (this.logoFrPopoverVisible && (!this.currentForm.logo || !this.currentForm.logoFrPath)) {
                    this.uploadLogoFr();
                }
            },

            logoEnPopoverVisible() {
                if (this.logoEnPopoverVisible && (!this.currentForm.logo_en || !this.currentForm.logoEnPath)) {
                    this.uploadLogoEn();
                }
            },

            'currentForm.ivr_locale'() {
                this.$nextTick(() => {
                    this.clearFileInput();
                });
            },

            'currentForm.ivr'(newValue, oldValue) {
                if (this.hasIvr == true && !this.currentForm.ivr_name && newValue != oldValue) {
                    this.updateAccountProperties({ ivr_name: this.currentForm.name });
                }

                this.$nextTick(() => {
                    this.clearFileInput();
                });
            },

            'currentForm.leadxpress'() {
                this.checkModules();
            },

            'currentForm.webboost'() {
                this.checkModules();
            },

            'currentForm.walk_in'() {
                this.checkModules();
            },

            'currentForm.phone_up'() {
                this.checkModules();
            },

            'currentForm.loyalty'() {
                this.checkModules();
            },

            'currentForm.web_order'() {
                this.checkModules();
            },

            'currentForm.service'() {
                this.checkModules();
                this.updateFields('service');
            },

            'currentForm.renewal'() {
                if (!this.currentForm.renewal) {
                    this.currentForm.auto_renewal = false;
                    this.currentForm.equity_alert = false;
                }

                this.checkModules();
                this.updateFields('renewal');
            },

            'currentForm.commercial'() {
                this.checkModules();
                this.updateFields('commercial');
            },

            'currentForm.sale_table'() {
                this.checkModules();
                this.updateFields('sale_table');
            },

            'currentForm.event'() {
                this.checkModules();
            },

            'currentForm.activity_report'() {
                this.checkModules();
            },

            'currentForm.in_turn'() {
                this.checkModules();
            },

            'currentForm.scan'() {
                this.checkModules();
            },

            'currentForm.vin_decoder'(checked) {
                if (checked) {
                    this.updateAccountProperties({ vin_manual_decoder: true });
                }
                this.checkModules();
            },

            'currentForm.vin_manual_decoder'() {
                this.checkModules();
            },

            'currentForm.sale_by_phone'() {
                this.checkModules();
            },

            'currentForm.bdc_advisor'(newValue, oldValue) {
                if (!newValue && newValue != oldValue) {
                    this.updateAccountProperties({ activity_report: newValue });
                }

                this.checkModules();
            },

            'currentForm.calendar_options'() {
                this.checkModules();
            },

            'currentForm.niotext'() {
                this.checkModules();
            },

            'currentForm.power_sport'() {
                this.checkModules();
                this.updateFields('power_sport');
            },

            'currentForm.recreative_special'() {
                this.checkModules();
                this.updateFields('recreative_special');
            },

            'currentForm.sale_accessories'() {
                this.checkModules();
                this.updateFields('sale_accessories');
            },

            'currentForm.trade_report'() {
                this.checkModules();
                this.updateFields('trade_report');
            },

            'currentForm.credit'() {
                this.checkModules();
            },

            'currentForm.automation'() {
                this.checkModules();
            },

            'currentForm.duplicates'() {
                this.checkModules();
            },

            'currentForm.active'(newValue, oldValue) {
                if (!newValue && this.currentForm.phone_providers.length > 0 && newValue != oldValue) {
                    this.inactiveModalOpened = true;
                }

                if (!this.currentForm.activation_date && newValue == true) {
                    this.updateAccountProperties({ activation_date: now_date().toString() });
                }
            },

            'currentForm.auto_renewal'(newValue, oldValue) {
                if (newValue && newValue != oldValue) {
                    this.updateClientCardsProperty('wanted_vehicle.modality', newValue);
                    this.updateClientCardsProperty('wanted_vehicle.end_contract_date', newValue);
                }
            },

            validIvrNumbers(newValue, oldValue) {
                if (!newValue && newValue != oldValue) {
                    this.updateAccountProperties({ ivr: false });
                }
            },

            'currentForm.phone_provider'(newValue) {
                if (!PhoneProviderEntity.INTEGRATED_PROVIDERS.includes(newValue)) {
                    this.updateAccountProperties({ webboost: this.currentForm.webboost && this.haveOutgoingInternalPhoneProviders });
                    this.updateAccountProperties({ ivr: false });
                }
            },

        },

        methods: {
            ...mapActions(useAccountCardStore, ['updateAccountProperties']),

            addNewPhoneNumber(provider) {
                if (PhoneProviderEntity.INTEGRATED_PROVIDERS.includes(provider)) {
                    this.openPhoneProviderModal();
                } else {
                    this.createPhoneNumberFromProvider(provider);
                }
            },

            createPhoneNumberFromProvider(provider) {
                this.$eventBus.$emit('update-phone-providers', {
                    account_id: this.currentForm.id,
                    accountId: this.currentForm.id,
                    communication_type: this.getCommunicationType('incoming'),
                    communication_type_id: this.getCommunicationType('incoming').id,
                    type: this.getCommunicationType('incoming').id,
                    division: null,
                    division_id: null,
                    id: `a${randomString()}`,
                    ivr: false,
                    phone: '',
                    phone_provider: provider,
                    provider,
                    press_to_talk: true,
                    recording_message: false,
                    source: null,
                    source_id: null,
                    user: null,
                    user_id: null,
                });
            },

            getCommunicationType(type) {
                return this.relatedData.communicationTypes.find(communicationType => communicationType.name === type);
            },

            editCustomField(customField) {
                this.selectedField = this.customFields.find(field => field.field == customField.field);
                this.customFieldsModalOpened = true;
            },

            addCustomField() {
                this.selectedField = {
                    account_id: this.currentForm.id,
                    name: '',
                    field: '',
                    type: '',
                    visible: true,
                    section: ClientCardSection.GENERAL_INFO,
                    select_picker_options: [],
                    tempId: randomString(),
                    deleted_at: null,
                    custom_values_count: 0,
                    display: 'line',
                    default: [],
                    order: this.customFields.length + 1,
                    retroactive: false,
                };

                this.customFieldsModalOpened = true;
            },

            deleteCustomField(field) {
                this.deleteCustomFieldModal.field = field.field;
                this.deleteCustomFieldModal.count = field.customables_count;
                this.deleteCustomFieldModal.opened = true;
            },

            onCustomFieldDelete() {
                const deletedField = this.customFields.find(field => field.field == this.deleteCustomFieldModal.field);
                deletedField.deleted_at = true;

                let index = 1;
                this.customFields.forEach(field => {
                    if (field.id != deletedField.id) {
                        field.order = index++;
                    }
                });

                if (deletedField.tempId) {
                    useAccountCardStore().currentForm.custom_fields = this.customFields.filter(field => field.tempId !== deletedField.tempId);
                }
            },

            onCreateCustomFieldWithDefault(field) {
                this.retroactiveCustomFieldModal.field = field.field;
                this.retroactiveCustomFieldModal.count = field.custom_values_count;
                this.retroactiveCustomFieldModal.opened = true;
            },

            onAcceptRetroactiveDefaultValueCreation() {
                const newField = this.customFields.find(field => field.field == this.retroactiveCustomFieldModal.field);
                newField.retroactive = true;
            },

            updateCustomFieldPosition(evt) {
                const customFields = cloneDeep(this.customFields.sort((a, b) => a.order - b.order));
                const customField = customFields.find(field => field.id == evt.item.id);

                let index = 1;
                customFields.forEach(field => {
                    if (field.order != evt.oldIndex + 1) {
                        field.order = index++;
                    }
                });

                customField.order = evt.newIndex + 1;

                customFields.forEach(field => {
                    if (field.id != evt.item.id && field.order >= evt.newIndex + 1) {
                        field.order += 1;
                    }
                });

                useAccountCardStore().currentForm.custom_fields = customFields;
            },

            formatSupplierDisplayName(supplier) {
                const displayName = camelCase(supplier.display_name);

                return this.$t(`suppliers.${displayName}`);
            },

            updateBlacklistPhones(value) {
                this.updateAccountProperties({ blacklist_phone_up: value });
            },

            updateIvrWaitingSound(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                if (newValue) {
                    this.updateAccountProperties({ ivr_waiting_sound: newValue.id });
                } else {
                    this.updateAccountProperties({ ivr_waiting_sound: 'music' });
                }
            },

            updateIvrLocale(newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                if (newValue) {
                    this.updateAccountProperties({ ivr_locale: newValue.id });
                } else {
                    this.updateAccountProperties({ ivr_locale: null });
                }
            },

            deleteLogoFr() {
                this.logoFrPopoverVisible = false;

                this.updateAccountProperties({
                    logo: 'delete',
                    logoFrPath: null,
                });
            },

            uploadLogoFr() {
                this.logoFrPopoverVisible = false;

                $(this.$refs.logo).click();
            },

            updateLogoFr(event) {
                if (event.target.files && event.target.files[0]) {
                    const reader = new FileReader();

                    reader.onload = e => {
                        this.logoFrData = e.target.result;
                    };

                    reader.readAsDataURL(event.target.files[0]);

                    this.updateAccountProperties({
                        logo: event.target.files[0],
                    });
                }
            },

            deleteLogoEn() {
                this.logoEnPopoverVisible = false;

                this.updateAccountProperties({
                    logo_en: 'delete',
                    logoEnPath: null,
                });
            },

            uploadLogoEn() {
                this.logoEnPopoverVisible = false;

                $(this.$refs.logo_en).click();
            },

            updateLogoEn(event) {
                if (event.target.files && event.target.files[0]) {
                    const reader = new FileReader();

                    reader.onload = e => {
                        this.logoEnData = e.target.result;
                    };

                    reader.readAsDataURL(event.target.files[0]);
                    this.updateAccountProperties({ logo_en: event.target.files[0] });
                }
            },

            setMouseHover(module) {
                this.hoverOptions[module] = true;
            },

            setMouseLeave(module) {
                this.hoverOptions[module] = false;
            },

            checkModules() {
                if (!this.updatingCrm) {
                    this.crmMax =
                        this.currentForm.leadxpress &&
                        this.currentForm.webboost &&
                        this.currentForm.walk_in &&
                        this.currentForm.phone_up;
                }

                if (!this.updatingSale) {
                    this.saleDepartment =
                        this.currentForm.web_order &&
                        this.currentForm.loyalty &&
                        this.currentForm.service &&
                        this.currentForm.renewal &&
                        this.currentForm.commercial &&
                        this.currentForm.sale_table &&
                        this.currentForm.event &&
                        this.currentForm.activity_report;
                }

                if (!this.updatingOptions) {
                    this.saleOptions =
                        this.currentForm.in_turn &&
                        this.currentForm.scan &&
                        this.currentForm.vin_decoder &&
                        this.currentForm.vin_manual_decoder &&
                        this.currentForm.sale_by_phone &&
                        this.currentForm.bdc_advisor &&
                        this.currentForm.calendar_options &&
                        this.currentForm.niotext &&
                        this.currentForm.power_sport &&
                        this.currentForm.recreative_special &&
                        this.currentForm.sale_accessories &&
                        this.currentForm.credit &&
                        this.currentForm.trade_report &&
                        this.currentForm.automation &&
                        this.currentForm.duplicates &&
                        this.currentForm.niotext_appointment &&
                        this.currentForm.niotext_campaign;
                }
            },

            updateCrmMax() {
                if (this.authUser.isProgrammer()) {
                    const val = !this.crmMax;

                    this.updatingCrm = true;

                    this.updateAccountProperties({
                        leadxpress: val,
                        phone_up: val,
                        webboost: val,
                        walk_in: val,
                    });

                    this.$nextTick(() => {
                        this.updatingCrm = false;
                        this.checkModules();
                    });
                }
            },

            updateSaleDepartment() {
                if (this.authUser.isProgrammer()) {
                    const val = !this.saleDepartment;

                    this.updatingSale = true;

                    this.updateAccountProperties({
                        web_order: val,
                        loyalty: val,
                        service: val,
                        renewal: val,
                        commercial: val,
                        sale_table: val,
                        event: val,
                        activity_report: val,
                    });

                    this.$nextTick(() => {
                        this.updatingSale = false;
                        this.checkModules();
                    });
                }
            },

            updateSaleOptions() {
                if (this.authUser.isProgrammer()) {
                    const val = !this.saleOptions;

                    this.updatingOptions = true;

                    this.updateAccountProperties({
                        in_turn: val,
                        scan: val,
                        vin_decoder: val,
                        vin_manual_decoder: val,
                        sale_by_phone: val,
                        calendar_options: val,
                        niotext: val,
                        power_sport: val,
                        recreative_special: val,
                        sale_accessories: val,
                        credit: val,
                        trade_report: val,
                        automation: val,
                        duplicates: val,
                    });

                    if (val || !this.currentForm.children) {
                        this.updateAccountProperties({ bdc_advisor: val });
                    }

                    this.$nextTick(() => {
                        this.updatingOptions = false;
                        this.checkModules();
                    });
                }
            },

            updateChildAccounts(newValue) {
                this.updateAccountProperties({ children: newValue });

                // Automatically enable bdc_advisor option
                if (newValue) {
                    this.updateAccountProperties({ bdc_advisor: true });
                }
            },

            updateCallerId(newValue) {
                this.updateAccountProperties({ caller_id_account: newValue.value == 1 });
            },

            updateProvince(newValue) {
                this.updateAccountProperties({ province: newValue.value });
                this.checkForBetterTimezone(newValue.value);
            },

            updateCountry(newValue) {
                this.updateAccountProperties({ province: null });
                this.updateAccountProperties({ country: newValue.value });
            },

            updateTimezone(newValue) {
                this.updateAccountProperties({ timezone: newValue.value });
            },

            updateLocale(newValue) {
                this.updateAccountProperties({ locale: newValue.value });
            },

            updateFields(module) {
                const val = this.currentForm[module];
                const fields = this.fieldOptions[module];

                const bulkChangedProperties = {};

                for (const i in fields) {
                    if (!fields.hasOwnProperty(i)) {
                        continue;
                    }

                    let update = true;

                    // Making sure its not already on as default for an other option
                    if (val == false) {
                        if (fields[i][0] == 'crm') {
                            update = false;
                        }
                        if (fields[i][0] == 'wanted_vehicle') {
                            if (
                                fields[i][1] == 'accessories' &&
                                (this.currentForm.power_sport ||
                                    this.currentForm.sale_accessories ||
                                    this.currentForm.commercial)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'color' &&
                                (this.currentForm.sale_table ||
                                    this.currentForm.commercial ||
                                    this.currentForm.renewal ||
                                    this.currentForm.power_sport)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'transmission' &&
                                (this.currentForm.sale_table ||
                                    this.currentForm.commercial ||
                                    this.currentForm.renewal ||
                                    this.currentForm.power_sport)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'modality' &&
                                (this.currentForm.sale_table ||
                                    this.currentForm.commercial ||
                                    this.currentForm.renewal ||
                                    this.currentForm.power_sport)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'end_contract_date' &&
                                (this.currentForm.commercial || this.currentForm.renewal)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'end_warranty_date' &&
                                (this.currentForm.service || this.currentForm.renewal)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'frequency' &&
                                (this.currentForm.sale_table ||
                                    this.currentForm.commercial ||
                                    this.currentForm.renewal ||
                                    this.currentForm.power_sport)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'price' &&
                                (this.currentForm.sale_table ||
                                    this.currentForm.commercial ||
                                    this.currentForm.renewal ||
                                    this.currentForm.power_sport)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'payment' &&
                                (this.currentForm.sale_table ||
                                    this.currentForm.commercial ||
                                    this.currentForm.renewal ||
                                    this.currentForm.power_sport)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'profit' &&
                                (this.currentForm.sale_table ||
                                    this.currentForm.commercial ||
                                    this.currentForm.renewal ||
                                    this.currentForm.power_sport)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'total' &&
                                (this.currentForm.sale_table ||
                                    this.currentForm.commercial ||
                                    this.currentForm.renewal ||
                                    this.currentForm.power_sport)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'term' &&
                                (this.currentForm.sale_table ||
                                    this.currentForm.commercial ||
                                    this.currentForm.renewal ||
                                    this.currentForm.power_sport)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'rate' &&
                                (this.currentForm.sale_table ||
                                    this.currentForm.commercial ||
                                    this.currentForm.renewal ||
                                    this.currentForm.power_sport)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'stock' &&
                                (this.currentForm.sale_table ||
                                    this.currentForm.commercial ||
                                    this.currentForm.power_sport)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'category' &&
                                (this.currentForm.power_sport || this.currentForm.recreative_special)
                            ) {
                                update = false;
                            } else if (fields[i][1] == 'category_rv' && this.currentForm.recreative_special) {
                                update = false;
                            } else if (fields[i][1] == 'fuel' && this.currentForm.recreative_special) {
                                update = false;
                            } else if (fields[i][1] == 'weight' && this.currentForm.recreative_special) {
                                update = false;
                            } else if (fields[i][1] == 'length' && this.currentForm.recreative_special) {
                                update = false;
                            } else if (fields[i][1] == 'sleeping' && this.currentForm.recreative_special) {
                                update = false;
                            } else if (fields[i][1] == 'budget' && this.currentForm.recreative_special) {
                                update = false;
                            } else if (fields[i][1] == 'year' && this.currentForm.recreative_special) {
                                update = false;
                            }
                        } else if (fields[i][0] == 'exchange_vehicle') {
                            if (
                                fields[i][1] == 'value' &&
                                (this.currentForm.power_sport ||
                                    this.currentForm.renewal ||
                                    this.currentForm.sale_table ||
                                    this.currentForm.trade_report)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'end_warranty_date' &&
                                (this.currentForm.service || this.currentForm.renewal)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'balance' &&
                                (this.currentForm.power_sport || this.currentForm.renewal)
                            ) {
                                update = false;
                            } else if (
                                fields[i][1] == 'requested' &&
                                (this.currentForm.power_sport || this.currentForm.sale_table)
                            ) {
                                update = false;
                            } else if (fields[i][1] == 'link' && this.currentForm.recreative_special) {
                                update = false;
                            } else if (
                                fields[i][1] == 'category' &&
                                (this.currentForm.power_sport || this.currentForm.recreative_special)
                            ) {
                                update = false;
                            } else if (fields[i][1] == 'category_rv' && this.currentForm.recreative_special) {
                                update = false;
                            } else if (fields[i][1] == 'fuel' && this.currentForm.recreative_special) {
                                update = false;
                            } else if (fields[i][1] == 'weight' && this.currentForm.recreative_special) {
                                update = false;
                            } else if (fields[i][1] == 'length' && this.currentForm.recreative_special) {
                                update = false;
                            } else if (fields[i][1] == 'sleeping' && this.currentForm.recreative_special) {
                                update = false;
                            } else if (fields[i][1] == 'condition' && this.currentForm.trade_report) {
                                update = false;
                            } else if (fields[i][1] == 'accidented_damage_cost' && this.currentForm.trade_report) {
                                update = false;
                            } else if (fields[i][1] == 'tire_type' && this.currentForm.trade_report) {
                                update = false;
                            }
                        } else if (fields[i][0] == 'options') {
                            if (fields[i][1] == 'csi' && this.currentForm.sale_table) {
                                update = false;
                            }
                        }
                    }

                    if (update == true) {
                        if (['crm', 'options'].includes(fields[i][0])) {
                            bulkChangedProperties[fields[i][1]] = val;
                        } else {
                            bulkChangedProperties[`client_card_fields.${fields[i][0]}.${fields[i][1]}`] = val;
                        }
                    }
                }
                this.updateAccountProperties(bulkChangedProperties);
            },

            updateTeams(team) {
                this.updateAccountProperties({ teams: team.value });
            },

            openActivationDateModal() {
                if (!this.authUser.isDirector() && this.authUser.isProgrammer()) {
                    this.activationDateModalOpened = true;
                }
            },

            openPhoneProviderModal() {
                if (this.currentForm.active) {
                    this.phoneProviderModalOpened = true;
                }
            },

            clearFileInput() {
                this.$refs.recording_message.clear();
                this.$refs.ivr_audio_welcome.clear();

                if (this.currentForm.ivr_locale == 'FR') {
                    this.$refs.ivr_audio_menu_fr.clear();
                    this.$refs.ivr_audio_invalid_fr.clear();
                    this.$refs.ivr_audio_transfer_fr.clear();
                } else {
                    this.$refs.ivr_audio_menu_en.clear();
                    this.$refs.ivr_audio_invalid_en.clear();
                    this.$refs.ivr_audio_transfer_en.clear();
                }
            },

            deleteAudio(file) {
                if (this.authUser.isDirector()) {
                    return;
                }

                const files = {};
                files[file] = 'delete';
                this.updateAccountProperties(files);
            },

            updateAudio(event, file) {
                const files = {};
                files[file] = event || null;
                this.updateAccountProperties(files);
            },

            disabledPhone(phone) {
                return (
                    this.authUser.isDirector() ||
                    (phone.provider && phone.source_id == 63 && !this.authUser.isProgrammer())
                );
            },

            getDisabledTooltip(field) {
                if (!this.authUser.isAdmin()) {
                    return this.$t('accounts.edit.disabledTooptips.onlyAdmins');
                }

                switch (field) {
                    case 'crmMax':
                        if (this.crmMax && this.currentForm.web_order) {
                            return this.$t('accounts.edit.disabledTooptips.mandatoryOptions');
                        }

                        break;
                    case 'leadxpress':
                        if (this.currentForm.web_order) {
                            return this.$t('accounts.edit.disabledTooptips.webOrderRequiresIt');
                        }

                        break;
                    case 'webboost':
                        if (this.currentForm.web_order) {
                            return this.$t('accounts.edit.disabledTooptips.webOrderRequiresIt');
                        }

                        break;
                    case 'web_order':
                        if (!this.currentForm.leadxpress || !this.currentForm.webboost) {
                            return this.$t('accounts.edit.disabledTooptips.webCrmRequired');
                        }

                        break;
                    case 'process_recorded':
                        if (this.currentForm.sale_table) {
                            return this.$t('accounts.edit.disabledTooptips.saleTableRequiresIt');
                        }

                        break;
                    case 'accidented_damage_cost':
                    case 'tire_type':
                        if (!this.currentForm.trade_report) {
                            return this.$t('accounts.edit.disabledTooptips.tradeReportRequires');
                        }

                        break;
                }

                return '';
            },

            updateClientCardsProperty(property, value) {
                const fields = cloneDeep(this.clientCardFields);

                set(fields, property, value);

                useAccountCardStore().currentForm.client_card_fields = fields;
            },

            updateCustomField(value) {
                const fields = cloneDeep(this.currentForm.custom_fields);

                let index;

                if (value.id) {
                    index = fields.findIndex(v => v.id == value.id);
                }

                if (value.tempId) {
                    index = fields.findIndex(v => v.tempId == value.tempId);
                }

                if (index !== -1) {
                    fields.splice(index, 1, value);
                } else {
                    fields.push(value);
                }

                useAccountCardStore().currentForm.custom_fields = fields;

                this.customFieldsModalOpened = false;

                if (value.tempId && value.default.length && !value.default.includes('') && [
                    ClientCardSection.GENERAL_INFO,
                    ClientCardSection.PERFORMANCE,
                    ClientCardSection.PROCESS,
                    ClientCardSection.PROCESS_SERVICE,
                ].includes(value.section)) {
                    this.onCreateCustomFieldWithDefault(value);
                }
            },

            customFieldDisabled(customField) {
                return !!(
                    customField.id &&
                    customField.visible &&
                    customField.id == this.currentForm.sale_table_badge_field
                );
            },

            updatePhoneAttribute(dispatch, target) {
                if (!target || target.match(/\d/g).length === 11) {
                    const updateInfo = {};
                    updateInfo[dispatch] = target || '';
                    this.updateAccountProperties(updateInfo);
                }
            },

            checkForBetterTimezone(province) {
                this.updateAccountProperties({ timezone: this.configs.provinceTimezones[province] });
            },

            triggerInactive() {
                this.updateAccountProperties({ active: true });
            },

            triggerServiceDisabled() {
                this.updateAccountProperties({ service: false });
            },

            onRefreshFileInput() {
                this.clearFileInput();
            },

            calculatorOutboundSupplierNeedsToBeDisabled(supplier) {
                switch (supplier.id) {
                    case Supplier.AUTOPROPULSION:
                        return this.currentForm.outbound_suppliers.some(supplier => [Supplier.VAUTO, Supplier.QUICKTRADE].includes(supplier.id));

                    case Supplier.VAUTO:
                        return this.currentForm.outbound_suppliers.some(supplier => [Supplier.AUTOPROPULSION, Supplier.QUICKTRADE].includes(supplier.id));

                    case Supplier.QUICKTRADE:
                        return this.currentForm.outbound_suppliers.some(supplier => [Supplier.VAUTO, Supplier.AUTOPROPULSION].includes(supplier.id));
                }

                return false;
            },

            calculatorInboundSupplierNeedsToBeDisabled(supplier) {
                switch (supplier.id) {
                    case Supplier.AUTOPROPULSION:
                        return this.currentForm.inbound_suppliers.some(supplier => [Supplier.VAUTO, Supplier.QUICKTRADE].includes(supplier.id));

                    case Supplier.VAUTO:
                        return this.currentForm.inbound_suppliers.some(supplier => [Supplier.AUTOPROPULSION, Supplier.QUICKTRADE].includes(supplier.id));

                    case Supplier.QUICKTRADE:
                        return this.currentForm.inbound_suppliers.some(supplier => [Supplier.VAUTO, Supplier.AUTOPROPULSION].includes(supplier.id));
                }

                return false;
            },
        },

        async mounted() {
            await this.$nextTick();
            this.checkModules();

            this.$eventBus.$on('refresh-file-input', this.onRefreshFileInput);
        },

        beforeDestroy() {
            this.$eventBus.$off('refresh-file-input', this.onRefreshFileInput);
        },
    };
</script>
