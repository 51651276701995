<template>
    <tr v-if="weekdayHours.division_id == divisionId">
        <td class="w-1/4">
            {{ weekdayName }}
        </td>
        <td>
            <div class="flex items-center justify-center">
                <activix-checkbox
                    :value="weekdayHours.open"
                    @input="updateAccountBusinessHours(index, { open: $event })"
                />
            </div>
        </td>
        <td>
            <el-time-select
                class="w-full"
                :clearable="false"
                :picker-options="startPickerOption"
                :value="weekdayHours.opening_time.slice(0, -3)"
                @input="updateAccountBusinessHours(index, { opening_time: `${$event}:00` })"
            />
        </td>
        <td>
            <el-time-select
                class="w-full"
                :clearable="false"
                :picker-options="endPickerOption"
                :value="weekdayHours.closing_time.slice(0, -3)"
                @input="updateAccountBusinessHours(index, { closing_time: `${$event}:00` })"
            />
        </td>
    </tr>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapActions } from 'pinia';
    import Moment from '../../value-objects/Moment.js';
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';

    export default {
        props: ['weekdayHours', 'index', 'divisionId'],

        computed: {
            startPickerOption() {
                return {
                    start: '06:00',
                    step: '00:15',
                    end: '23:45',
                    maxTime: this.weekdayHours.closing_time,
                };
            },

            endPickerOption() {
                return {
                    start: '06:00',
                    step: '00:15',
                    end: '23:45',
                    minTime: this.weekdayHours.opening_time,
                };
            },

            weekdayName() {
                const weekdayName = Moment.weekdayName(this.weekdayHours.weekday);

                return this.$t(`weekDay.${weekdayName}`);
            },
        },
        methods: {
            ...mapActions(useAccountCardStore, ['updateAccountBusinessHours']),
        },
    };
</script>
