<template>
    <div class="mb-4 flex items-center">
        <activix-switcher
            class="w-48 mr-4 whitespace-nowrap"
            :value="accountProduct.visible"
            @input="updateProductEnable"
        >
            {{ $t(`clientCard.products.${accountProduct.name}`) }}
        </activix-switcher>

        <activix-masked-input
            class="price | text-center mr-4 flex-1"
            mask-type="money"
            :integer-limit="8"
            :placeholder="$t('clientCard.services.price')"
            :value="defaultPrice"
            ref="price"
            @input="updateDefaultPrice"
        />

        <activix-time-picker v-model="time" />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { minutesToTime, timeToMinutes } from '../../utils/time.js';
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';

    export default {
        props: {
            productId: Number,
        },

        computed: {
            ...mapState(useAccountCardStore, {
                currentForm: 'currentForm',
                serviceProducts: store => store.currentForm.service_products,
            }),

            accountProduct() {
                return this.serviceProducts.find(product => product.product_id === this.productId);
            },

            time: {
                get() {
                    return minutesToTime(this.accountProduct.default_minutes);
                },

                set(newValue) {
                    this.accountProduct.default_minutes = timeToMinutes(newValue);

                    if (
                        !empty(timeToMinutes(newValue)) &&
                        this.accountProduct.default_minutes != timeToMinutes(newValue)
                    ) {
                        useAccountCardStore().currentForm.service_products = this.updateProductServicesProperty('default_minutes', timeToMinutes(newValue));
                    }
                },
            },

            defaultPrice() {
                return this.accountProduct.default_price;
            },
        },

        watch: {
            defaultPrice() {
                if (this.defaultPrice && !this.accountProduct.visible) {
                    this.accountProduct.visible = true;
                    useAccountCardStore().currentForm.service_products = this.updateProductServicesProperty('visible', true);
                }
            },

            'accountProduct.default_minutes'(newValue, oldValue) {
                if (typeof oldValue === 'undefined' || (empty(newValue) && empty(oldValue)) || newValue == oldValue) {
                    return;
                }

                // Update visible if required
                if (!empty(newValue) && !this.accountProduct.visible) {
                    this.accountProduct.visible = true;
                    useAccountCardStore().currentForm.service_products = this.updateProductServicesProperty('visible', true);
                }
            },
        },

        methods: {
            updateDefaultPrice(price) {
                useAccountCardStore().currentForm.service_products = this.updateProductServicesProperty('default_price', price);
            },

            updateProductEnable(enabled) {
                useAccountCardStore().currentForm.service_products = this.updateProductServicesProperty('visible', enabled);
            },

            updateProductServicesProperty(property, value) {
                return this.serviceProducts.map(product => {
                    if (product.product_id === this.productId) {
                        product[property] = value;
                    }

                    return product;
                });
            },
        },
    };
</script>
