<template>
    <div>
        <activix-button
            :disabled="isLoading"
            :loading="$wait.is(['creating.account.new', 'updating.account.new'])"
            @click="onSaveAndNew"
            v-if="fullAccess"
        >
            {{ $t('general.saveAndNew') }}
        </activix-button>
        <activix-button
            class="ml-2"
            type="primary"
            :disabled="isLoading"
            :loading="$wait.is(['creating.account.close', 'updating.account.close'])"
            @click="onSaveAndClose"
            v-if="fullAccess"
        >
            {{ $t('general.saveAndClose') }}
        </activix-button>
        <activix-button
            class="ml-2"
            type="primary"
            :disabled="isLoading"
            :loading="$wait.is(['creating.account.default', 'updating.account.default'])"
            @click="onSave"
        >
            {{ $t('general.save') }}
        </activix-button>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            isLoading() {
                return this.$wait.is('creating.account.*') || this.$wait.is('updating.account.*');
            },

            fullAccess() {
                return this.authUser.hasAccessTo('accounts.index');
            },
        },

        methods: {
            onSave() {
                this.$emit('save');
            },

            onSaveAndNew() {
                this.$emit('save', 'new');
            },

            onSaveAndClose() {
                this.$emit('save', 'close');
            },
        },
    };
</script>
