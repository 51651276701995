<template>
    <div>
        <table class="table  table-bordered | border-t-2 border-b-2" v-if="renewalAlertOptions.length">
            <thead>
                <tr>
                    <th>{{ $t('accounts.edit.renewalOptions.makes') }}</th>
                    <th>{{ $t('accounts.edit.renewalOptions.models') }}</th>
                    <th>{{ $t('accounts.edit.renewalOptions.year') }}</th>
                    <th>{{ $t('accounts.edit.renewalOptions.condition') }}</th>
                    <th />
                </tr>
            </thead>

            <tbody>
                <tr
                    :key="index"
                    v-for="(renewalAlertOption, index) in renewalAlertOptions"
                >
                    <td>
                        <div :key="make" v-for="make in getMakes(renewalAlertOption.criterion)">
                            {{ make }}
                        </div>
                    </td>
                    <td>
                        <div :key="model" v-for="model in getModels(renewalAlertOption.criterion)">
                            {{ model }}
                        </div>
                    </td>
                    <td>
                        <div :key="year" v-for="year in getYears(renewalAlertOption.criterion)">
                            {{ year }}
                        </div>
                    </td>
                    <td>
                        <div>{{ $t(`accounts.edit.renewalOptions.equity.${renewalAlertOption.condition}`) }}</div>
                    </td>
                    <td class="text-right space-x-2 w-0 whitespace-nowrap">
                        <activix-button @click="editSetting(index)">
                            <icon name="regular/pencil-1" />
                        </activix-button>
                        <activix-button @click="deleteSetting(index)">
                            <icon name="regular/trash-1" />
                        </activix-button>
                    </td>
                </tr>
            </tbody>
        </table>
        <renewal-setting-modal
            :setting="editedSetting"
            :opened.sync="renewalSettingModalOpened"
            @saved="onRenewalSettingSaved"
        />
    </div>
</template>

<script>
    import cloneDeep from 'lodash/cloneDeep.js';
    import RenewalSettingModal from './modals/RenewalSetting.vue';

    export default {
        components: {
            RenewalSettingModal,
        },

        props: {
            renewalAlertOptions: {
                type: Array,
                required: true,
                default: () => [],
            },
        },

        data() {
            return {
                editedSetting: {
                    makes: [],
                    models: [],
                    year: null,
                    operator: null,
                    condition: null,
                },
                editedIndex: null,

                renewalSettingModalOpened: false,
            };
        },

        methods: {
            getMakes(criterion) {
                const criteria = criterion.find(criteria => criteria.property == 'exchangeVehicles.make');

                return criteria?.value ?? ['-'];
            },

            getModels(criterion) {
                const criteria = criterion.find(criteria => criteria.property == 'exchangeVehicles.model');

                return criteria?.value ?? ['-'];
            },

            getYears(criterion) {
                const criteria = criterion.find(criteria => criteria.property == 'exchangeVehicles.year');

                if (!criteria) {
                    return ['-'];
                }

                if (criteria.operator == '=') {
                    return criteria.value;
                }

                return [`${criteria.operator} ${criteria.value[0]}`];
            },

            editSetting(index) {
                this.editedSetting = cloneDeep(this.renewalAlertOptions[index]);
                this.editedIndex = index;

                this.renewalSettingModalOpened = true;
            },

            deleteSetting(index) {
                this.$emit('delete', index);
            },

            onRenewalSettingSaved(setting) {
                this.$emit('save', setting, this.editedIndex);
            },
        },
    };
</script>
