<template>
    <activix-modal :opened="opened" @close="close" @open="onOpen">
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('phoneProvider.title') }}
            </h4>
        </template>

        <template slot="body">
            <div class="row">
                <div class="col-xs-6 form-group">
                    <label>{{ $t('phoneProvider.typeOfNumber') }}</label>
                    <activix-multiselect
                        identifier="value"
                        label="label"
                        :allow-empty="false"
                        :selected="type"
                        :options="typeOptions"
                        :searchable="false"
                        @update="updateType"
                    />
                </div>

                <div class="col-xs-6 form-group">
                    <label>{{ $t('phoneProvider.direction') }}</label>
                    <activix-multiselect
                        label="name"
                        placeholder="-"
                        :allow-empty="false"
                        :selected="direction"
                        :options="relatedData.communicationTypes"
                        :searchable="false"
                        :disabled="!accountHasSupportedPhoneProvider"
                        :custom-label="formatCommunicationType"
                        @update="updateDirection"
                    />
                </div>
            </div>
            <div class="row" v-if="type.value == 'near' && !closed">
                <div class="col-xs-6 form-group">
                    <label>{{ $t('phoneProvider.postalCode') }}<span class="form-star" v-if="!postalCodeValid"> *</span></label>
                    <activix-masked-input
                        type="text"
                        mask-type="postalCode"
                        :country="currentForm.country || 'CA'"
                        v-model="type.postalCode"
                    />
                </div>
                <div class="col-xs-6 form-group">
                    <label>{{ $t('phoneProvider.areaCode') }}</label>
                    <activix-input
                        :maxlength="3"
                        type="number"
                        @input="limitInput"
                        v-model="type.areaCode"
                    />
                </div>
            </div>

            <div class="alert alert-warning | w-full m-0" v-if="closed && (!fetching || type.value == 'custom')">
                {{ $t('accounts.edit.accountClosed') }}
            </div>
            <div class="alert alert-warning | w-full m-0" v-if="noNumberAvailable && !fetching">
                {{ $t('phoneProvider.noNumberAvailable') }}
            </div>
            <activix-spinner class="mt-2" :size="40" v-if="fetching && type.value != 'custom'" />

            <div class="row">
                <div v-if="numbers.length > 0 && !fetching && type.value != 'custom' && !closed">
                    <div
                        class="col-xs-6 | truncate text-left flex items-center py-1"
                        :key="index"
                        v-for="(number, index) in numbers"
                    >
                        <activix-radio name="numberToBuy" :value="number.phone_number" v-model="numberToBuy">
                            {{ number.friendly_name }}
                            <span v-if="number.postal_code"> - {{ number.postal_code }}</span>
                            <span v-if="number.rate_center"> - {{ number.rate_center }}</span>
                        </activix-radio>
                    </div>
                </div>

                <div class="col-xs-12 text-center form-group" v-if="type.value == 'custom' && !closed">
                    <label>{{ $t('phoneProvider.addAlreadyBoughtNumber') }}</label>
                    <activix-masked-input mask-type="phoneWithCountry" v-model="numberToBuy" />
                </div>
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.cancel') }}
            </activix-button>

            <activix-button
                type="primary"
                :disabled="closed || numberToBuy == '' || contains(numberToBuy, '_') || type == null"
                :loading="saving"
                @click="buyNumber"
            >
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { showError } from '../../utils/toastr.js';
    import PhoneProviderEntity from '../../entities/PhoneProvider.js';
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';
    import { useGlobalStore } from '../../store/store.js';
    /* eslint-disable vue/no-mutating-props */
    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            account: {
                type: Object,
                default: () => ({}),
            },
            relatedData: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            const accountCardStore = mapState(useAccountCardStore, ['currentForm']);

            return {
                fetching: false,
                saving: false,
                closed: false,
                noNumberAvailable: false,
                direction: this.getCommunicationType('outgoing'),
                numbers: {},
                numberToBuy: '',
                inputToSearch: null,
                type: {
                    value: 'near',
                    label: this.$t('phoneProvider.nearOf'),
                    postalCode: accountCardStore.currentForm().postal_code,
                    areaCode: '',
                },
                typeOptions: [
                    {
                        value: 'near',
                        label: this.$t('phoneProvider.nearOf'),
                        postalCode: '',
                        areaCode: '',
                    },
                    {
                        value: 'tollFree',
                        label: this.$t('phoneProvider.tollFree'),
                        postalCode: '',
                        areaCode: '',

                    },
                    {
                        value: 'custom',
                        label: this.$t('phoneProvider.custom'),
                        postalCode: '',
                        areaCode: '',

                    },
                ],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['configs']),
            ...mapState(useAccountCardStore, ['currentForm']),

            numberToBuyIsValid() {
                return this.numberToBuy != '' && !this.contains(this.numberToBuy, '_');
            },

            accountHasSupportedPhoneProvider() {
                return PhoneProviderEntity.INTEGRATED_PROVIDERS.includes(this.currentForm.phone_provider);
            },

            postalCodeValid() {
                return (this.currentForm.country == 'US' && String(this.type.postalCode).length == 5) ||
                    (this.currentForm.country == 'CA' && String(this.type.postalCode).length == 7);
            },

            inputIsValid() {
                return (
                    (String(this.type.areaCode).length == 0 || String(this.type.areaCode).length == 3) && this.postalCodeValid) ||
                    this.type.value != 'near';
            },
        },

        watch: {
            type: {
                deep: true,
                handler(newValue, oldValue) {
                    if (newValue.value != oldValue.value) {
                        this.numberToBuy = '';
                        this.numbers = {};
                    }

                    // Fetch numbers
                    if (this.inputIsValid && newValue.value != 'custom') {
                        this.checkAccountStatus();
                    }
                },
            },

            account() {
                this.checkAccountStatus();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['appendNewError']),

            contains(check, toFind) {
                return check != null && check != undefined && check.indexOf(toFind) != -1;
            },

            formatCommunicationType({ name }) {
                return this.$t(`general.${name}`);
            },

            checkAccountStatus() {
                if (!this.account.id ||
                    !this.inputIsValid
                ) {
                    return;
                }

                this.numberToBuy = '';
                this.numbers = {};
                this.fetching = true;
                this.noNumberAvailable = false;

                this.$axios
                    .get(`v1/phone-provider/check-status/${this.account.id}`, {
                        params: {
                            provider: 'twilio',
                        },
                    })
                    .then(response => {
                        if (response.data.data == 'active') {
                            this.closed = false;
                            this.fetchNumbers(this.type);
                        } else {
                            this.closed = true;
                            this.fetching = false;
                        }
                    })
                    .catch(() => {
                        this.closed = true;
                        this.fetching = false;
                    });
            },

            fetchNumbers(option) {
                if (option.value == 'custom') {
                    return;
                }

                this.$axios
                    .get(`v1/phone-provider/available-numbers/${option.value}`, {
                        params: {
                            account_id: this.account.id,
                            provider: 'twilio',
                            postal_code: option.postalCode,
                            area_code: option.areaCode,
                            country: this.currentForm.country,
                        },
                    })
                    .then(response => {
                        this.fetching = false;
                        this.numbers = response.data.data;
                    })
                    .catch(error => {
                        this.fetching = false;

                        if (error.response && error.response.status === 403) {
                            return;
                        }

                        if (error.response && error.response.status === 404) {
                            this.noNumberAvailable = true;
                            this.numbers = {};
                            return;
                        }

                        showError('', '0022');
                    });
            },

            buyNewNumber() {
                if (this.numberToBuyIsValid) {
                    this.saving = true;

                    this.$axios
                        .post('v1/phone-provider/buy-number', {
                            accountId: this.account.id,
                            type: this.direction.id,
                            number: this.numberToBuy,
                            provider: 'twilio',
                        })
                        .then(response => {
                            if (response.status == 200 && response.data.success) {
                                this.close();
                                this.$notify.success(this.$t('phoneProvider.successfullyAdded'));

                                this.setTwilioPhones(response.data.data);
                                this.saving = false;

                                this.addTwilioNumberToBlackList(this.numberToBuy);
                            } else {
                                this.appendNewError({
                                    code: '0116',
                                    display: true,
                                    response,
                                    request: {
                                        accountId: this.account.id,
                                        type: this.direction.id,
                                        number: this.numberToBuy,
                                        provider: 'twilio',
                                    },
                                });

                                this.fetching = false;
                                this.saving = false;
                            }
                        })
                        .catch(error => {
                            this.fetching = false;
                            this.saving = false;

                            if (error.response && error.response.status === 403) {
                                return;
                            }

                            this.appendNewError({
                                code: '0023',
                                display: true,
                                error,
                                request: {
                                    accountId: this.account.id,
                                    type: this.direction.id,
                                    number: this.numberToBuy,
                                    provider: 'twilio',
                                },
                            });
                        });
                }
            },

            // Only availlable for twilio
            addCustomNumber() {
                if (this.numberToBuyIsValid) {
                    this.saving = true;

                    this.$axios
                        .post('v1/phone-provider/add-number-to-account', {
                            accountId: this.account.id,
                            type: this.direction.id,
                            number: this.numberToBuy,
                            provider: 'twilio',
                        })
                        .then(response => {
                            this.close();
                            this.$notify.success(this.$t('phoneProvider.successfullyAdded'));
                            this.setTwilioPhones(response.data.data);
                            this.saving = false;

                            this.addTwilioNumberToBlackList(this.numberToBuy);
                        })
                        .catch(error => {
                            this.fetching = false;
                            this.saving = false;

                            if (error.response && error.response.status === 403) {
                                return;
                            }

                            this.appendNewError({
                                code: '0024',
                                display: true,
                                error,
                                request: {
                                    accountId: this.account.id,
                                    type: this.direction.id,
                                    number: this.numberToBuy,
                                    provider: 'twilio',
                                },
                            });
                        });
                }
            },

            updateType(newValue) {
                this.type = newValue;
            },

            updateDirection(newValue) {
                this.direction = newValue;
            },

            addTwilioNumberToBlackList(newNumber) {
                this.currentForm.blacklist_phone_up.push(newNumber);
                this.$emit('update-blacklist-phones', this.currentForm.blacklist_phone_up);
            },

            buyNumber() {
                if (!this.closed) {
                    if (this.type.value == 'custom') {
                        this.addCustomNumber();
                    } else {
                        this.buyNewNumber();
                    }
                }
            },

            setTwilioPhones(phoneProvider) {
                this.$eventBus.$emit('update-phone-providers', phoneProvider);
            },

            close() {
                this.$emit('update:opened', false);
            },

            onOpen() {
                this.checkAccountStatus();
            },

            getCommunicationType(direction) {
                return this.relatedData.communicationTypes.find(communicationType => communicationType.name === direction);
            },

            limitInput(event) {
                if (String(event).length > 3) {
                    this.type.areaCode = event.slice(0, 3);
                }
            },
        },
    };
</script>
