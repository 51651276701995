<template>
    <div class="flex space-x-4">
        <activix-tooltip :content="phoneIsSameAsAccountTooltip">
            <activix-masked-input
                class="flex-1"
                mask-type="phoneWithCountry"
                :value="phone"
                :disabled="phoneIsSameAsAccount"
                @keydown.enter="$emit('add')"
                @input="$emit('update', $event)"
            />
        </activix-tooltip>
        <button
            class="link-grey-light"
            :disabled="phoneIsSameAsAccount"
            :class="{ 'hover:text-red-500': !phoneIsSameAsAccount }"
            @click="$emit('delete')"
        >
            <icon :name="$icons.trash" />
        </button>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        props: {
            phone: {
                type: String,
                required: true,
            },
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            phoneIsSameAsAccount() {
                return this.contextAccount.phone == this.phone;
            },

            phoneIsSameAsAccountTooltip() {
                return this.phoneIsSameAsAccount
                    ? this.$t('accounts.edit.dealerPhoneMustBeBlacklisted')
                    : '';
            },
        },
    };
</script>
