<template>
    <div>
        <div class="flex items-center mb-2">
            <label class="m-0">
                {{ $tc('accounts.edit.blacklistPhones', blacklistPhones.length) }}
            </label>
            <activix-tooltip :content="$t('accounts.edit.blacklistPhonesTooltip')">
                <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
            </activix-tooltip>
            <button
                class="inline-flex items-center link-grey-light ml-2"
                :disabled="hasInvalidBlacklistPhone"
                @click="initNextBlacklistPhone"
            >
                <icon name="bold/add" class="text-sm" />
            </button>
        </div>

        <div class="space-y-4">
            <blacklist-item
                :phone="phone"
                :key="index"
                @add="initNextBlacklistPhone"
                @update="updatePhoneAttribute(index, $event)"
                @delete="deleteBlacklistPhone(index)"
                v-for="(phone, index) in blacklistPhones"
            />
        </div>
    </div>
</template>

<script>
    import BlacklistItem from '@/components/accounts/BlacklistItem.vue';
    import { mapState } from 'pinia';
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';

    export default {
        components: { BlacklistItem },

        data() {
            return {
                blacklistPhones: [],
            };
        },

        computed: {
            ...mapState(useAccountCardStore, {
                accountBlacklistPhones: store => store.currentForm.blacklist_phone_up,
            }),

            hasInvalidBlacklistPhone() {
                return this.blacklistPhones.some(phone => {
                    return !phone || phone.match(/\d/g).length != 11;
                });
            },
        },

        watch: {
            accountBlacklistPhones: {
                immediate: true,
                handler() {
                    this.blacklistPhones = this.accountBlacklistPhones;
                },
            },

            blacklistPhones() {
                this.addBlacklistPhones();
            },
        },

        methods: {
            initNextBlacklistPhone() {
                if (this.hasInvalidBlacklistPhone) {
                    return;
                }

                this.blacklistPhones.push('');
            },

            addBlacklistPhones() {
                if (!this.hasInvalidBlacklistPhone) {
                    this.$emit('update-blacklist-phones', this.blacklistPhones);
                }
            },

            deleteBlacklistPhone(index) {
                this.blacklistPhones.splice(index, 1);
            },

            updatePhoneAttribute(index, phone) {
                this.blacklistPhones.splice(index, 1, phone);
            },
        },
    };
</script>
