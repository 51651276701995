<template>
    <activix-select
        :autosize="true"
        :options="externalPhoneProviders"
        :placeholder="$t('multiselect.selectOption')"
        :value="currentForm.phone_provider"
        :disabled="disabled"
        @change="updateExternalPhoneProvider"
    >
        <el-option
            :label="externalPhoneProvider.label"
            :value="externalPhoneProvider.value"
            :key="externalPhoneProvider.value"
            v-for="externalPhoneProvider in externalPhoneProviders"
        />
    </activix-select>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';

    export default {
        props: {
            externalPhoneProviders: {
                type: Array,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapState(useAccountCardStore, ['currentForm']),
        },

        methods: {
            ...mapActions(useAccountCardStore, ['updateAccountProperties']),

            updateExternalPhoneProvider(selected) {
                this.updateAccountProperties({ phone_provider: selected });
            },
        },
    };
</script>
