<!-- eslint-disable vue/no-mutating-props -->
<template>
    <tr>
        <td>
            <div class="flex items-center relative">
                <div class="form-control bg-grey-200 min-w-40" v-text="formattedPhone" v-if="isSupportedProvider" />
                <activix-masked-input
                    class="w-full"
                    mask-type="phoneWithCountry"
                    v-model="phone.phone"
                    v-else
                />

                <activix-tooltip
                    :content="phoneProviderName"
                    v-if="!empty(phone.phone_provider) && !authUser.isDirector()"
                >
                    <span class="text-grey-500 capitalize absolute right-0 mr-3">
                        {{ phoneProviderAcronym }}
                    </span>
                </activix-tooltip>
            </div>
        </td>

        <td>
            <div class="flex items-center relative">
                <activix-multiselect
                    class="w-full"
                    label="name"
                    :selected="phone.communication_type"
                    :options="relatedData.communicationTypes"
                    :searchable="false"
                    :allow-empty="false"
                    :disabled="disabled || !accountHasSupportedPhoneProvider"
                    :custom-label="formatCommunicationType"
                    @update="updateCommunicationType"
                />
                <icon
                    class="text-grey-500 absolute right-0 mr-9"
                    name="regular/headphones-customer-support"
                    v-if="phone.ivr && currentForm.ivr"
                />
            </div>
        </td>

        <td>
            <activix-multiselect
                label="name"
                :clearable="true"
                :selected="phone.division"
                :options="relatedData.divisions"
                :searchable="false"
                :allow-empty="true"
                :disabled="divisionAndUserDisabled || phone.communication_type.id == 1 || phone.ivr"
                :custom-label="formatDivision"
                :placeholder="$t('phoneProvider.noDivision')"
                @update="updateDivision"
            />
        </td>

        <td>
            <activix-multiselect
                label="last_name"
                :clearable="true"
                :selected="phone.user"
                :options="filteredUsers"
                :searchable="true"
                :disabled="divisionAndUserDisabled || phone.communication_type.id == 1 || phone.ivr"
                :custom-label="formatName"
                :placeholder="$t('phoneProvider.noAdvisor')"
                @update="updateUser"
            />
        </td>

        <td>
            <activix-multiselect
                label="name"
                :clearable="true"
                :selected="phone.source"
                :options="orderedSources"
                :searchable="false"
                :disabled="disabled || phone.communication_type.id == 1"
                :placeholder="$t('phoneProvider.noSource')"
                @update="updateSource"
            />
        </td>

        <td class="collapsing" v-if="accountHasSupportedPhoneProvider">
            <activix-checkbox
                :disabled="disabled || phone.communication_type.id === 1"
                v-model="phone.ivr"
                v-if="isSupportedProvider"
            />
        </td>

        <td class="collapsing">
            <activix-tooltip
                :content="disabledRecordingMessage ? $t('accounts.edit.recordingMessageRequiredTooltip') : ''"
                v-if="isSupportedProvider"
            >
                <activix-checkbox :disabled="disabledRecordingMessage" v-model="phone.recording_message" />
            </activix-tooltip>
        </td>

        <td class="collapsing" v-if="authUser.isAdmin()">
            <div class="flex items-center">
                <icon
                    class="link-grey-light"
                    :name="$icons.trash"
                    :class="disabled ? 'disabled' : 'hover:text-red-500'"
                    @click="deletePhone(phone.id)"
                />
            </div>
            <activix-confirm-modal
                type="warning"
                :title="$t('general.areYouSure')"
                :content="$t('phoneProvider.deletePhoneConfirmation')"
                :approve-text="$t('phoneProvider.removeIt')"
                :deny-text="$t('general.cancel')"
                :opened.sync="deletePhoneModal.opened"
                @approve="onPhoneDeleteConfirm"
                @cancel="deletePhoneModal.phoneId = null"
            />
        </td>
    </tr>
</template>

<script>
    /* eslint-disable vue/require-prop-types, vue/no-mutating-props */
    import { orderBy, upperFirst } from 'lodash-es';
    import { mapState, mapActions } from 'pinia';
    import PhoneProviderEntity from '../../entities/PhoneProvider.js';
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: ['relatedData', 'disabled', 'phone'],
        data() {
            return {
                deletePhoneModal: {
                    opened: false,
                    phoneId: null,
                },
            };
        },
        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useAccountCardStore, ['currentForm']),

            divisionAndUserDisabled() {
                if (this.authUser.isDirector()) {
                    return false;
                }

                return this.disabled;
            },

            phoneProviderName() {
                return PhoneProviderEntity.entries[this.phone.phone_provider];
            },

            phoneProviderAcronym() {
                return this.phoneProviderName.match(/\b\w/g).join('');
            },

            disabledRecordingMessage() {
                return (
                    this.disabled ||
                    this.phone.communication_type_id == 1 ||
                    (!this.currentForm.recording_message_audio && !this.currentForm.recording_message_audio_file) ||
                    this.currentForm.recording_message_audio == 'delete'
                );
            },

            filteredUsers() {
                return orderBy(
                    this.relatedData.users.filter((user) => user.active),
                    ['first_name', 'last_name'],
                );
            },

            orderedSources() {
                return orderBy(this.relatedData.sources, 'name');
            },

            formattedPhone() {
                return this.formatPhone(this.phone.phone, true);
            },

            isSupportedProvider() {
                return PhoneProviderEntity.INTEGRATED_PROVIDERS.includes(this.phone.phone_provider);
            },

            accountHasSupportedPhoneProvider() {
                return PhoneProviderEntity.INTEGRATED_PROVIDERS.includes(this.currentForm.phone_provider);
            },
        },

        watch: {
            'phone.communication_type'(newValue) {
                if (empty(newValue) || newValue.id == 1) {
                    this.phone.ivr = false;
                    this.phone.recording_message = false;

                    this.updateDivision(null);
                }
            },

            'phone.division'(newValue) {
                if (!empty(newValue)) {
                    this.phone.ivr = false;
                }
            },

            'phone.ivr'(newValue) {
                if (newValue) {
                    this.phone.user = null;
                    this.phone.division = null;
                    this.phone.division_id = null;
                }
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['appendNewError']),

            upperFirst(value) {
                return upperFirst(value);
            },

            deletePhone(id) {
                if (this.disabled) {
                    return;
                }

                this.deletePhoneModal.phoneId = id;
                this.deletePhoneModal.opened = true;
            },

            deleteProviderPhone(id) {
                if (typeof id === 'string') {
                    this.$eventBus.$emit('remove-phone', id);
                } else {
                    this.$axios
                        .delete(`v1/phone-provider/phone/${id}`)
                        .then(() => {
                            this.$eventBus.$emit('remove-phone', id);
                        })
                        .catch(error => {
                            this.appendNewError({
                                code: '0001',
                                display: true,
                                error,
                                payload: id,
                            });
                        });
                }
            },

            // Updates

            updateCommunicationType(newSelected) {
                this.phone.communication_type = newSelected;

                if (!empty(newSelected)) {
                    this.phone.communication_type_id = newSelected.id;
                } else {
                    this.phone.communication_type_id = null;
                }
            },

            updateDivision(newSelected) {
                this.phone.division = newSelected;

                if (!empty(newSelected)) {
                    this.phone.division_id = newSelected.id;
                    this.phone.ivr = false;
                } else {
                    this.phone.division_id = null;
                }
            },

            updateUser(newSelected) {
                this.phone.user = newSelected;

                if (!empty(newSelected)) {
                    this.phone.user_id = newSelected.id;
                } else {
                    this.phone.user_id = null;
                }
            },

            // Formaters

            updateSource(newSelected) {
                this.phone.source = newSelected;

                if (!empty(newSelected)) {
                    this.phone.source_id = newSelected.id;
                } else {
                    this.phone.source_id = null;
                }
            },

            formatCommunicationType({ name }) {
                return this.$t(`general.${name}`);
            },

            formatDivision({ name }) {
                return this.$t(`divisions.${name}`);
            },

            formatName(value) {
                return this.getFullName(value);
            },

            onPhoneDeleteConfirm() {
                this.deleteProviderPhone(this.deletePhoneModal.phoneId);
            },
        },
    };
</script>
