<template>
    <el-dropdown trigger="click" placement="bottom">
        <activix-base-button :disabled="disabled">
            <slot />
        </activix-base-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                :disabled="optionIsDisabled(option)"
                :key="option.value"
                @click.native="selectOption(option)"
                v-for="option in options"
            >
                {{ option.label }}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            options: {
                type: Array,
                required: true,
            },
        },

        methods: {
            optionIsDisabled(option) {
                if (this.disabled === true) {
                    return true;
                }
                if (typeof option.disabled === 'undefined') {
                    return false;
                }
                if (typeof option.disabled === 'function') {
                    return option.disabled();
                }
                if (typeof option.disabled === 'boolean') {
                    return option.disabled;
                }
                return false;
            },

            selectOption(option) {
                this.$emit('select', option.value);
            },
        },
    };
</script>
