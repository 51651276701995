<template>
    <div>
        <activix-modal
            size="sm"
            :opened="opened"
            portal="modal-1"
            @close="$emit('update:opened', false)"
        >
            <template slot="header">
                <h4 class="modal-title">
                    {{ $t('accounts.edit.facebookMessenger') }}
                </h4>
            </template>
            <template slot="body">
                <div class="flex justify-center">
                    <activix-button type="primary" @click="facebookLogout" v-if="!!currentForm.facebook_id">
                        <icon class="mr-2 text-2xl" name="bold/social-media-facebook-1" />
                        {{ $t('accounts.edit.disconnectFacebookButton') }}
                    </activix-button>
                    <activix-button type="primary" @click="facebookLogin" v-else>
                        <icon class="mr-2 text-2xl" name="bold/social-media-facebook-1" />
                        {{ $t('accounts.edit.continueWithFacebookButton') }}
                    </activix-button>
                </div>
            </template>
        </activix-modal>

        <facebook-page-modal :opened.sync="facebookPageModalOpened" :pages="pageData" @save="savePageData" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import FacebookPageModal from './FacebookPageModal.vue';
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            FacebookPageModal,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                facebookPageModalOpened: false,
                pageData: [],
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useAccountCardStore, {
                currentForm: 'currentForm',
                pageId: store => store.currentForm.facebook_id,
            }),
        },

        methods: {
            ...mapActions(useAccountCardStore, ['updateAccountProperties']),

            facebookLogin() {
                const loginPayload = {
                    scope: 'pages_show_list,pages_messaging',
                    return_scopes: true,
                };

                window.FB.login(response => {
                    if (response.authResponse) {
                        this.onSignInSuccess(response);
                    } else {
                        this.$emit('update:opened', false);
                    }
                }, loginPayload);
            },

            facebookLogout() {
                this.updateAccountProperties({ facebook_id: null });

                this.updateAccount(this.contextAccount.id, {
                    facebook_id: null,
                    long_access_token: null,
                });

                this.$emit('update:opened', false);
            },

            async onSignInSuccess(responseSignIn) {
                const accessToken = await this.getLongAccessToken(responseSignIn.authResponse.accessToken);

                window.FB.api(
                    `/${responseSignIn.authResponse.userID}/accounts?access_token=${accessToken}`,
                    response => {
                        if (!response || response.error) {
                            return;
                        }

                        if (!response.data.length) {
                            this.facebookLogout();
                        } else {
                            this.pageData = response.data;
                            this.facebookPageModalOpened = true;
                        }
                    },
                );
            },

            savePageData(page) {
                const payload = {
                    subscribed_fields: 'messages,message_echoes',
                    access_token: page.access_token,
                };

                window.FB.api(`/${page.id}/subscribed_apps`, 'POST', payload, response => {
                    this.$emit('update:opened', false);

                    if (!response || !response.success) {
                        return;
                    }

                    this.updateAccountProperties({ facebook_id: page.id });
                    this.updateAccount(this.contextAccount.id, {
                        facebook_id: page.id,
                        long_access_token: page.access_token,
                    });

                    this.$notify.success(this.$t('accounts.alerts.facebook.success'));
                });
            },

            async updateAccount(id, payload = {}) {
                const response = await this.$axios.put(`v1/accounts/${id}/facebook`, payload);

                return response.data.data;
            },

            async getLongAccessToken(shortAccessToken) {
                const response = await this.$axios.get('v1/facebook/tokens', {
                    params: {
                        shortAccessToken,
                    },
                });

                return response.data.data;
            },
        },
    };
</script>
