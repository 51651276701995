<template>
    <activix-modal
        size="sm"
        :closable="false"
        :opened="opened"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('accounts.edit.guestGroup.title') }}
            </h4>
        </template>

        <template slot="body">
            <div class="flex items-center">
                <label class="w-24 mb-0">
                    {{ $t('accounts.edit.guestGroup.name') }}
                    <span class="form-star" v-if="title.length < 3">*</span>
                </label>
                <div class="flex-1 px-6">
                    <activix-input v-model="title" />
                </div>
            </div>

            <div class="flex items-center mt-6">
                <label class="w-24 mb-0">
                    {{ $t('users.edit.roles') }}
                </label>
                <div class="flex-1 px-6">
                    <activix-multiselect
                        label="name"
                        multiple="multiple"
                        :options="activeRoles"
                        :searchable="false"
                        :allow-empty="false"
                        :close-on-select="false"
                        :selected="roles"
                        @update="updateRoles"
                    />
                </div>
            </div>

            <div class="flex items-center mt-6">
                <label class="w-24 mb-0">
                    {{ $t('users.edit.title') }}
                </label>
                <div class="flex-1 px-6">
                    <activix-multiselect
                        label="name"
                        multiple="multiple"
                        :options="activePosts"
                        :searchable="false"
                        :allow-empty="false"
                        :close-on-select="false"
                        :selected="posts"
                        @update="updatePosts"
                    />
                </div>
            </div>

            <div class="flex items-center mt-6">
                <label class="w-24 mb-0">
                    {{ $t('users.edit.users') }}
                    <span class="form-star" v-if="!users.length">*</span>
                </label>
                <div class="flex-1 px-6">
                    <activix-multiselect
                        label="name"
                        multiple="multiple"
                        :options="formatedActiveUsers"
                        :searchable="false"
                        :allow-empty="false"
                        :close-on-select="false"
                        :selected="users"
                        @update="updateUsers"
                    />
                </div>
            </div>
        </template>

        <template slot="footer">
            <div class="flex justify-center">
                <activix-button @click="cancel">
                    {{ $t('general.cancel') }}
                </activix-button>
                <activix-button
                    class="ml-2"
                    type="primary"
                    :disabled="disabled"
                    @click="save"
                >
                    {{ actionText }}
                </activix-button>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import { orderBy, uniqBy, isEmpty } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';

    import AccountGuestGroup from '../../entities/AccountGuestGroup.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'GuestGroupModal',

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            accountRoles: {
                type: Array,
                required: true,
            },
            accountGuestGroup: {
                type: AccountGuestGroup,
                required: true,
            },
            accountPosts: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                title: '',
                roles: [],
                users: [],
                posts: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['activeUsers']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            actionText() {
                return this.editMode ? this.$t('general.save') : this.$t('general.create');
            },

            editMode() {
                return !!this.accountGuestGroup.id;
            },

            activeRoles() {
                const allUniqueRolesInActiveUsers = uniqBy(this.activeUsers, 'role_id').map(
                    userRole => userRole.role_id,
                );

                const filteredRoles = this.accountRoles.filter(role => allUniqueRolesInActiveUsers.includes(role.id));

                return orderBy(
                    filteredRoles.map(role => {
                        return {
                            id: role.id,
                            name: this.$t(`database.role_${role.name}`),
                        };
                    }),
                    'name',
                );
            },

            activePosts() {
                const activePosts = uniqBy(this.activeUsers, 'post_id').map(
                    userPost => userPost.post_id,
                );

                return orderBy(
                    uniqBy(this.accountPosts.filter(post => !post.sex || post.sex === 'M'), 'name')
                        .map(post => {
                            return {
                                id: post.id,
                                name: this.$t(`database.post_${post.name}`),
                                posts: this.accountPosts
                                    .filter(p => p.name === post.name ||
                                        p.name === `${post.name}_w` ||
                                        (p.name === 'delivery_woman' && post.name === 'delivery_man'))
                                    .map(p => p.id),
                            };
                        })
                        .filter(post => activePosts.some(activePost => post.posts.includes(activePost))),
                    ['name', 'id'],
                );
            },

            formatedActiveUsers() {
                return this.activeUsers.map(user => {
                    return {
                        id: user.id,
                        name: this.getFullName(user),
                        role_id: user.role_id,
                        post_id: user.post_id,
                    };
                });
            },

            disabled() {
                if (!this.title) {
                    return true;
                }

                return isEmpty(this.users);
            },
        },

        watch: {
            accountGuestGroup(group) {
                this.title = group.name;
                this.roles = this.activeRoles.filter(role => group.roles.includes(role.id));
                this.users = this.activeUsers.filter(user => group.users.includes(user.id));
                this.posts = this.activePosts.filter(post => group.posts.includes(post.id));
            },
        },

        methods: {
            ...mapActions(useContextStore, ['reloadContextAccount']),

            close() {
                this.$emit('update:opened', false);
            },

            onClosed() {
                this.$emit('closed');
            },

            cancel() {
                this.close();
            },

            updateRoles(roles) {
                this.users = uniqBy(this.getActiveUsersBySelectedRole(roles).concat(this.getActiveUsersBySelectedPost(this.posts)), 'id');
                this.roles = roles;
            },

            updatePosts(posts) {
                this.users = uniqBy(this.getActiveUsersBySelectedPost(posts).concat(this.getActiveUsersBySelectedRole(this.roles)), 'id');
                this.posts = posts;
            },

            updateUsers(users) {
                this.users = users;

                const completedRoles = this.getCompletedRole(users);
                const completedPosts = this.getCompletedPost(users);

                this.roles = this.activeRoles.filter(role => completedRoles.includes(role.id));
                this.posts = this.activePosts.filter(post => completedPosts.includes(post.id));
            },

            getCompletedRole(users) {
                return this.activeRoles.filter(role => {
                    const allActiveUsersPerRole = this.activeUsers.filter(user => user.role_id === role.id);
                    const selectedUsersPerRole = users.filter(user => user.role_id === role.id);

                    return allActiveUsersPerRole.length === selectedUsersPerRole.length;
                }).map(role => role.id);
            },

            getCompletedPost(users) {
                return this.activePosts.filter(post => {
                    const allActiveUsersPerPost = this.activeUsers.filter(user => post.posts.includes(user.post_id));
                    const selectedUsersPerPost = users.filter(user => post.posts.includes(user.post_id));

                    return allActiveUsersPerPost.length === selectedUsersPerPost.length;
                }).map(post => post.id);
            },

            getActiveUsersBySelectedRole(roles) {
                const rolesId = roles.map(role => role.id);

                return this.activeUsers.filter(user => rolesId.includes(user.role_id));
            },

            getActiveUsersBySelectedPost(posts) {
                const postsId = posts.map(post => post.posts).flat();

                return this.activeUsers.filter(user => postsId.includes(user.post_id));
            },

            async save() {
                const userIdList = this.users.map(user => user.id);
                const roleIdList = this.roles.map(role => role.id);
                const postIdList = this.posts.map(post => post.posts).flat();

                const payload = {
                    name: this.title,
                    accountId: this.contextAccount.id,
                    userIds: userIdList,
                    roleIds: roleIdList,
                    postIds: postIdList,
                };

                if (!this.editMode) {
                    await this.$api.accountGuestGroup.store(this.contextAccount.id, payload);
                } else {
                    await this.$api.accountGuestGroup.update(this.accountGuestGroup.id, payload);
                }

                this.$emit('saved');

                this.close();
            },
        },
    };
</script>
