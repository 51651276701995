<template>
    <div>
        <div class="flex items-center mb-2">
            <label class="m-0">
                {{ $tc('accounts.edit.imapExcludedDomains', currentForm.imap_ignored_domains.length) }}
            </label>
            <activix-tooltip :content="$t('accounts.edit.imapExcludedDomainsTooltip')">
                <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
            </activix-tooltip>
            <button
                class="inline-flex items-center link-grey-light ml-2"
                :disabled="hasInvalidImapExclusions"
                @click="addExcludedImapDomain"
            >
                <icon name="bold/add" class="text-sm" />
            </button>
        </div>

        <div class="space-y-4">
            <imap-exclusion
                :domain="domain"
                :key="index"
                @add="addExcludedImapDomain"
                @update="updateExcludedDomain(index, $event)"
                @delete="removeExcludedDomain(index)"
                v-for="(domain, index) in currentForm.imap_ignored_domains"
            />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import ImapExclusion from './ImapExclusion.vue';
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';

    export default {
        components: { ImapExclusion },

        computed: {
            ...mapState(useAccountCardStore, ['currentForm']),

            hasInvalidImapExclusions() {
                return this.currentForm.imap_ignored_domains.some(domain => {
                    return !domain || !domain.match(/\S+\.\S+/);
                });
            },
        },

        methods: {
            ...mapActions(useAccountCardStore, ['updateAccountProperties']),

            addExcludedImapDomain() {
                if (this.hasInvalidImapExclusions) {
                    return;
                }

                const newIgnoredDomains = [...this.currentForm.imap_ignored_domains];
                newIgnoredDomains.push('');

                this.updateAccountProperties({
                    imap_ignored_domains: newIgnoredDomains,
                });
            },

            updateExcludedDomain(index, value) {
                const newIgnoredDomains = [...this.currentForm.imap_ignored_domains];
                newIgnoredDomains[index] = value;

                this.updateAccountProperties({
                    imap_ignored_domains: newIgnoredDomains,
                });
            },

            removeExcludedDomain(index) {
                const newIgnoredDomains = [...this.currentForm.imap_ignored_domains];
                newIgnoredDomains.splice(index, 1);

                this.updateAccountProperties({
                    imap_ignored_domains: newIgnoredDomains,
                });
            },
        },
    };
</script>
