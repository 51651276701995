<template>
    <div class="account-card">
        <div class="flex items-center justify-center absolute h-full w-full top-0" v-if="$wait.is('fetching.account')">
            <activix-spinner :message="$t('general.loading')" />
        </div>
        <div v-else>
            <portal to="content-header-left">
                <router-link class="ml-2 link-muted" :to="{ name: 'users.index' }">
                    <activix-tooltip :content="$t('accounts.global.goToUsers')">
                        <icon class="mr-2 text-grey-650" name="regular/multiple-neutral-2" />
                    </activix-tooltip>
                </router-link>
            </portal>

            <div class="alert alert-danger | mb-4" v-if="errors.length">
                <div class="font-bold">
                    {{ $t('toastr.error') }}
                </div>
                <ul>
                    <li :key="error" v-html="error" v-for="error in errors" />
                </ul>
            </div>
            <div class="alert alert-info | mb-4" v-if="warnings.length">
                <div class="font-bold">
                    {{ $t('error.warning') }}
                </div>
                <ul>
                    <li :key="index" v-for="(warning, index) in warnings">
                        {{ warning }}
                    </li>
                </ul>
            </div>

            <div class="flex justify-between items-center | mb-6">
                <ul class="nav nav-pills">
                    <li :class="{ active: selectedTab == 'general' }">
                        <a @click="setTab('general')">
                            {{ $t('accounts.edit.general') }}
                        </a>
                    </li>
                    <li :class="{ active: selectedTab == 'preferences' }">
                        <a @click="setTab('preferences')">
                            {{ $t('accounts.edit.preferences') }}
                        </a>
                    </li>
                </ul>

                <account-buttons @save="save" />
            </div>

            <general-settings :related-data="relatedData" v-if="selectedTab == 'general'" />

            <sync-child-account-and-create-user
                :opened="syncChildUserAndCreateOpened"
                :account-attached="accountAttached"
                :account-changes="accountChanges"
                @closed="syncChildUserAndCreateOpened=false"
            />

            <preference-settings :related-data="relatedData" v-if="selectedTab == 'preferences'" />

            <div class="flex justify-end">
                <account-buttons @save="save" />
            </div>
        </div>
    </div>
</template>

<script>
    import { cloneDeep, get, isArray, sortBy } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';

    import GeneralSettings from '../../components/accounts/GeneralSettings.vue';
    import PreferenceSettings from '../../components/accounts/PreferenceSettings.vue';
    import AccountButtons from '../../components/accounts/AccountButtons.vue';
    import TrackView from '../../mixins/TrackView.js';
    import SyncChildAccountAndCreateUser from '../../components/modals/SyncChildAccountAndCreateUser.vue';

    // Pinia
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'AccountsEdit',

        components: {
            SyncChildAccountAndCreateUser,
            GeneralSettings,
            PreferenceSettings,
            AccountButtons,
        },

        mixins: [TrackView],

        data() {
            return {
                syncChildUser: false,
                syncChildUserAndCreateOpened: false,
                createChildUser: false,
                selectedTab: 'general',
                errors: [],
                warnings: [],
                accountAttached: [],
                accountChanges: {},
                relatedData: {
                    accounts: [],
                    communicationTypes: [],
                    guestGroups: [],
                    roles: [],
                    divisions: [],
                    sources: [],
                    suppliers: [],
                    users: [],
                    posts: [],
                },
                files: [
                    'logo',
                    'logo_en',
                    'recording_message_audio_file',
                    'ivr_audio_welcome_file',
                    'ivr_audio_menu_fr_file',
                    'ivr_audio_menu_en_file',
                    'ivr_audio_invalid_fr_file',
                    'ivr_audio_invalid_en_file',
                    'ivr_audio_transfer_fr_file',
                    'ivr_audio_transfer_en_file',
                ],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useAccountCardStore, {
                currentForm: 'currentForm',
                defaultForm: 'defaultForm',
            }),

            // @TODO Refactor
            authorized() {
                if (this.authUser.isAdmin()) {
                    return true;
                }

                return (
                    this.authUser.hasAccessTo('accounts.update') &&
                    (this.$route.params.id == this.authUser.account_id ||
                        this.authUser.children.some(child => child.account_id == this.$route.params.id))
                );
            },
        },

        watch: {
            '$route.params.id': {
                immediate: true,
                handler(newId, oldId) {
                    if (!newId && this.$route.name == 'accounts.store') {
                        /*
                         *  This mutator should not be set to null via the mutator anywhere else.
                         *  It is an exception for the creation of an account
                         */
                        this.setContextAccount(null);
                    }

                    if (newId == oldId) {
                        return;
                    }

                    this.reset();
                    this.fetchData();
                },
            },

            errors(newValue) {
                if (!newValue.length) {
                    return;
                }

                window.scrollTo(0, 0);
            },

            warnings(newValue) {
                if (!newValue.length) {
                    return;
                }

                window.scrollTo(0, 0);
            },
        },

        methods: {
            ...mapActions(useAccountCardStore, ['updateAccountProperties']),
            ...mapActions(useContextStore, ['reloadContextAccount', 'setContextAccountAction', 'setContextAccount']),
            ...mapActions(useGlobalStore, ['appendNewError']),

            async fetchData() {
                if (!this.authorized || !this.$route.params.id) {
                    return;
                }

                this.$wait.start('fetching.account');

                try {
                    const response = await this.$axios.get('v1/legacy/accounts/data', {
                        params: {
                            accountId: this.$route.params.id,
                            referer: 'accountCard',
                        },
                    });

                    const data = response.data.data;

                    if (this.contextAccount.id != data.account.id) {
                        this.setContextAccountAction(data.account.id);
                    }

                    Object.keys(this.relatedData).forEach(propName => {
                        this.relatedData[propName] = data[propName];
                    });

                    this.applyData(data.account);

                    this.$wait.end('fetching.account');
                } catch (error) {
                    this.$wait.end('fetching.account');
                    this.$notify.error(this.$t('accounts.alerts.show.error'));

                    throw error;
                }
            },

            applyData(account) {
                if (!account) {
                    return;
                }

                const temporaryData = cloneDeep(this.defaultForm);

                for (const property in account) {
                    if (!account.hasOwnProperty(property) || !temporaryData.hasOwnProperty(property)) {
                        continue;
                    }

                    if (property == 'teams') {
                        temporaryData[property] = account[property].filter(t => t.active).length || null;

                        continue;
                    } else if (property == 'logo') {
                        if (account.logo) {
                            temporaryData.logoFrPath = account.logo;
                        } else {
                            temporaryData.logoFrPath = '/img/logo-placeholder.jpg';
                        }

                        temporaryData[property] = account[property];

                        continue;
                    } else if (property == 'logo_en') {
                        if (account.logo_en) {
                            temporaryData.logoEnPath = account.logo_en;
                        } else {
                            temporaryData.logoEnPath = '/img/logo-placeholder.jpg';
                        }

                        temporaryData[property] = account[property];

                        continue;
                    } else if (property == 'business_hours' && account[property].length == 0) {
                        continue;
                    } else if (property == 'service_products') {
                        temporaryData[property] = account[property];

                        for (const product of this.configs.serviceProducts) {
                            if (!temporaryData[property].some(el => el.product_id == product.id)) {
                                temporaryData[property].push({
                                    product_id: product.id,
                                    account_id: this.contextAccount.id,
                                    visible: false,
                                    default_price: null,
                                    default_minutes: null,
                                    name: product.name,
                                });
                            }
                        }

                        temporaryData[property] = sortBy(temporaryData[property], el => el.order);

                        continue;
                    }

                    temporaryData[property] = account[property];
                }

                useAccountCardStore().currentForm = temporaryData;
            },

            clearAudioFiles() {
                this.updateAccountProperties({
                    recording_message_audio_file: null,
                    ivr_audio_welcome_file: null,
                    ivr_audio_menu_fr_file: null,
                    ivr_audio_menu_en_file: null,
                    ivr_audio_invalid_fr_file: null,
                    ivr_audio_invalid_en_file: null,
                    ivr_audio_transfer_fr_file: null,
                    ivr_audio_transfer_en_file: null,
                });

                this.$eventBus.$emit('refresh-file-input');
            },

            validateAudioFile(account) {
                this.updateAccountProperties({
                    recording_message_audio_file: get(account, 'recording_message_audio', null),
                    ivr_audio_welcome_file: get(account, 'ivr_audio_welcome', null),
                    ivr_audio_menu_fr_file: get(account, 'ivr_audio_menu_fr', null),
                    ivr_audio_menu_en_file: get(account, 'ivr_audio_menu_en', null),
                    ivr_audio_invalid_fr_file: get(account, 'ivr_audio_invalid_fr', null),
                    ivr_audio_invalid_en_file: get(account, 'ivr_audio_invalid_en', null),
                    ivr_audio_transfer_fr_file: get(account, 'ivr_audio_transfer_fr', null),
                    ivr_audio_transfer_en_file: get(account, 'ivr_audio_transfer_en', null),
                });
            },

            async save(method = 'default') {
                this.errors = [];
                this.warnings = [];

                const temporaryData = cloneDeep(this.currentForm);
                const payload = new FormData();

                if (this.$route.params.id) {
                    this.$wait.start(`updating.account.${method}`);
                } else {
                    this.$wait.start(`creating.account.${method}`);
                }

                try {
                    for await (const property of Object.keys(temporaryData)) {
                        if (!temporaryData.hasOwnProperty(property)) {
                            continue;
                        }

                        if (property === 'phone_providers' && temporaryData[property] !== null && temporaryData[property].length > 0) {
                            for await (const phone of temporaryData[property]) {
                                if (typeof phone.id === 'string' && phone.phone.length > 0) {
                                    const i = temporaryData[property].indexOf(phone);
                                    this.removePhone(phone.id);
                                    phone.number = phone.phone;
                                    temporaryData[property][i] = await this.createPhoneProvider(phone);
                                    this.updatePhoneProviders(temporaryData[property][i]);
                                }
                            }
                        }

                        if (
                            property != 'logo' &&
                            property != 'logo_en' &&
                            this.files.indexOf(property) === -1 &&
                            (typeof temporaryData[property] === 'object' || isArray(temporaryData[property]))
                        ) {
                            payload.append(property, JSON.stringify(temporaryData[property]));

                            continue;
                        }

                        payload.append(property, temporaryData[property]);
                    }
                } catch (e) {
                    return;
                }

                if (this.$route.params.id) {
                    this.updateAccount(payload, method);
                } else {
                    this.createAccount(payload, method);
                }
            },

            async createAccount(payload, method) {
                this.$wait.start(`creating.account.${method}`);

                try {
                    const response = await this.$axios.post('v1/legacy/accounts', payload);

                    this.$notify.success(this.$t('accounts.alerts.store.success'));
                    this.$wait.end(`creating.account.${method}`);

                    this.onSaveSuccess(response, method);
                } catch (error) {
                    this.$wait.end(`creating.account.${method}`);

                    const status = get(error.response, 'status');

                    if (status == 422) {
                        Object.values(error.response.data.errors).forEach(e => {
                            if (Array.isArray(e)) {
                                this.errors.push(e[0]);
                            } else {
                                this.errors.push(e);
                            }
                        });
                        return;
                    }

                    if (status == 449) {
                        this.warnings.push(error.response.data.message);
                        return;
                    }

                    this.$notify.error(this.$t('accounts.alerts.store.error'));

                    throw error;
                }
            },

            async updateAccount(payload, method) {
                this.$wait.start(`updating.account.${method}`);

                payload.append('_method', 'PUT'); // Required since put request can't send FormData.

                try {
                    const response = await this.$axios.post(`v1/legacy/accounts/${this.$route.params.id}`, payload);
                    const changes = response.data.changes || {};
                    const children = response.data.children || {};

                    if (this.$feature.isEnabled('parent-and-childs-improvements') && this.currentForm.children.length && (Object.keys(changes).length || children.attached?.length)) {
                        this.accountChanges = changes;
                        this.accountAttached = children.attached;
                        this.syncChildUserAndCreateOpened = true;
                    }

                    this.$notify.success(this.$t('accounts.alerts.update.success'));
                    this.$wait.end(`updating.account.${method}`);

                    await this.reloadContextAccount();

                    this.onSaveSuccess(response, method);
                } catch (error) {
                    this.$wait.end(`updating.account.${method}`);

                    const status = get(error.response, 'status');

                    if (status == 422) {
                        Object.values(error.response.data.errors).forEach(e => {
                            if (Array.isArray(e)) {
                                this.errors.push(e[0]);
                            } else {
                                this.errors.push(e);
                            }
                        });
                        return;
                    }

                    if (status == 449) {
                        this.warnings.push(error.response.data.message);
                        return;
                    }

                    this.$notify.error(this.$t('accounts.alerts.update.error'));

                    throw error;
                }
            },

            onSaveSuccess(response, method) {
                if (method == 'new') {
                    this.$router.push({ name: 'accounts.store' });
                } else if (method == 'close') {
                    this.$router.push({ name: 'accounts.index' });
                } else {
                    this.clearAudioFiles();
                    this.validateAudioFile(response.data.account);

                    this.$router.push({
                        name: 'accounts.update',
                        params: { id: response.data.account.id },
                    });
                }
            },

            updatePhoneProviders(phoneProvider) {
                const phoneProviders = cloneDeep(this.currentForm.phone_providers);

                phoneProviders.push(phoneProvider);

                useAccountCardStore().currentForm.phone_providers = phoneProviders;
            },

            removePhone(id) {
                const tmpPhones = this.currentForm.phone_providers.filter(phone => phone.id != id);

                useAccountCardStore().currentForm.phone_providers = tmpPhones;
            },

            setTab(tab) {
                const validTabs = ['general', 'preferences'];

                if (!validTabs.includes(tab)) {
                    tab = null;
                }

                if (tab) {
                    this.selectedTab = tab;
                }

                if (this.$route.query.tab != tab) {
                    this.updateQuery({ tab });
                }
            },

            reset() {
                useAccountCardStore().currentForm = cloneDeep(this.defaultForm);
            },

            createPhoneProvider(phoneProvider) {
                return new Promise((resolve, reject) => {
                    this.$axios
                        .post('v1/phone-provider/add-number-to-account', phoneProvider)
                        .then(response => {
                            if (response.status === 200 && response.data.success) {
                                resolve(response.data.data);
                            } else {
                                this.appendNewError({
                                    code: '0116',
                                    display: true,
                                    response,
                                    request: phoneProvider,
                                });
                                reject(phoneProvider);
                            }
                        })
                        .catch(error => {
                            if (error.response && error.response.status === 403) {
                                reject(phoneProvider);
                            }
                            this.appendNewError({
                                code: '0023',
                                display: true,
                                error,
                                request: phoneProvider,
                            });
                            reject(phoneProvider);
                        });
                });
            },
        },

        created() {
            this.setTab(this.$route.query.tab);
            this.$eventBus.$on('update-phone-providers', this.updatePhoneProviders);
            this.$eventBus.$on('remove-phone', this.removePhone);
        },

        beforeDestroy() {
            this.reset();
            this.$eventBus.$off('update-phone-providers', this.updatePhoneProviders);
            this.$eventBus.$off('remove-phone', this.removePhone);
        },
    };
</script>

<style lang="less">
    .account-card {
        .highlight {
            position: relative;

            &:after {
                width: 10px;
                height: 10px;
                border-radius: 50px;
                background-color: theme('colors.blue.500');
                content: '';
                display: inline-block;
                position: absolute;
                margin-left: 5px;
                top: 6px;
                vertical-align: middle;
            }
        }
    }
</style>
