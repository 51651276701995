<template>
    <div>
        <activix-modal
            size="sm"
            :closable="false"
            :opened="opened"
            @close="close"
            @closed="onClosed"
        >
            <template slot="header">
                <h4 class="modal-title">
                    {{ $t('accounts.edit.customFields.modalTitle') }}
                </h4>
            </template>

            <template slot="body">
                <div class="flex items-center">
                    <label class="w-20 mb-0">
                        {{ $t('accounts.edit.customFields.clientCardSection') }}
                    </label>
                    <div class="flex-1 px-6">
                        <activix-multiselect
                            identifier="value"
                            :multiple="false"
                            :selected="{
                                value: !empty(currentField.section) ? currentField.section : 'generalInfo',
                                label: $t('clientCard.generalInfo'),
                            }"
                            :placeholder="$t('multiselect.selectOption')"
                            :options="clientCardSectionOptions"
                            @update="updateSectionOption"
                        />
                    </div>
                </div>

                <div class="flex items-center mt-6">
                    <label class="w-20 mb-0">
                        {{ $t('accounts.edit.customFields.fieldTitle') }}
                        <span class="form-star" v-if="!currentField.name">*</span>
                    </label>
                    <div class="flex-1 px-6">
                        <activix-input :error="errorTitle" :maxlength="25" v-model.trim="currentField.name" />
                    </div>
                </div>

                <div class="flex items-center mt-6">
                    <label class="w-20 mb-0">
                        {{ $t('accounts.edit.customFields.fieldType') }}
                        <span class="form-star" v-if="!currentField.type">*</span>
                    </label>
                    <div class="flex-1 px-6">
                        <activix-tooltip :content="disabledTypeFieldTooltip">
                            <activix-select
                                identifier="value"
                                :placeholder="$t('multiselect.selectOption')"
                                :options="typeOptions"
                                :disabled="disabledTypeField"
                                v-model="currentField.type"
                            >
                                <el-option
                                    :label="option.label"
                                    :value="option.value"
                                    :key="option.value"
                                    v-for="option in typeOptions"
                                />
                            </activix-select>
                        </activix-tooltip>
                    </div>
                </div>
                <template v-if="showTextAreaOptions">
                    <div class="flex items-center mt-6">
                        <label class="w-20 mb-0">
                            {{ $t('accounts.edit.customFields.textAreaOption') }}
                        </label>
                        <div class="flex-1 px-6">
                            <activix-select
                                identifier="value"
                                :placeholder="$t('accounts.edit.customFields.textAreaOption')"
                                :options="textAreaOptions"
                                v-model="currentField.display"
                            >
                                <el-option
                                    :label="option.label"
                                    :value="option.value"
                                    :key="option.value"
                                    v-for="option in textAreaOptions"
                                />
                            </activix-select>
                        </div>
                    </div>
                </template>
                <template v-if="showInputOptions">
                    <div class="flex items-center mt-6">
                        <label class="w-20 mb-0">
                            {{ $t('accounts.edit.customFields.fieldData') }}
                            <span
                                class="form-star"
                                v-if="!multiSelectOption.length && !currentField.select_picker_options.length"
                            >*</span>
                        </label>
                        <div class="flex flex-1 px-6">
                            <activix-input
                                class="w-full attached-r"
                                :error="errorMaxOptions || errorDuplicateOption"
                                :maxlength="25"
                                ref="input"
                                @keydown.enter="addOption"
                                v-model.trim="multiSelectOption"
                            />
                            <activix-button
                                class="attached-l"
                                :disabled="!!errorMaxOptions || !multiSelectOption"
                                @click="addOption"
                            >
                                <icon class="text-sm text-grey-600" name="bold/add" />
                            </activix-button>
                        </div>
                    </div>

                    <div class="flex items-center">
                        <div class="w-20" />
                        <div class="flex-1 px-6">
                            <div
                                class="flex items-center justify-between border border-t-0 border-grey-150 rounded-b px-4 py-2 group"
                                :key="index"
                                v-for="(item, index) in sortedPickerOptions"
                            >
                                {{ item }}
                                <icon
                                    class="link-grey-light text-sm ml-4 opacity-0 | group-hover:opacity-100 hover:text-red-500"
                                    :name="$icons.trash"
                                    @click="deleteOption(index)"
                                />
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="currentField.type">
                    <div class="flex items-center mt-6">
                        <label class="w-20 mb-0">
                            {{ $t('accounts.edit.customFields.default') }}
                        </label>
                        <div class="flex-1 px-6">
                            <custom-input :field="currentField" v-model="currentField.default" />
                        </div>
                    </div>
                </template>
            </template>

            <template slot="footer">
                <div class="flex items-center justify-center">
                    <activix-button @click="cancel">
                        {{ $t('general.cancel') }}
                    </activix-button>
                    <activix-button
                        class="ml-2"
                        type="primary"
                        :disabled="disabled"
                        @click="saveCustomField"
                    >
                        {{ $t('general.save') }}
                    </activix-button>
                </div>
            </template>
        </activix-modal>

        <activix-confirm-modal
            type="warning"
            portal="modal-2"
            :title="$t('error.warning')"
            :content="$t('accounts.edit.customFields.unsavedModal.description')"
            :approve-text="$t('accounts.edit.customFields.unsavedModal.confirm')"
            :deny-text="$t('general.no')"
            :opened.sync="unsavedOption"
            @approve="$emit('update-custom-field', currentField)"
        />
    </div>
</template>

<script>
    import { kebabCase, cloneDeep } from 'lodash-es';

    import { mapState } from 'pinia';
    import ClientCardSection from '../../entities/ClientCardSection.js';
    import AccountType from '../../entities/AccountType.js';
    import CustomInput from '../CustomInput.vue';

    // Pinia
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            CustomInput,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            field: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                maxOptions: 100,
                multiSelectOption: '',
                currentField: {},
                unsavedOption: false,
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useAccountCardStore, {
                customFields: store => store.currentForm.custom_fields,
            }),

            sortedPickerOptions() {
                const pickerOptions = this.currentField.select_picker_options;
                return pickerOptions.sort((a, b) => a.localeCompare(b));
            },

            otherCustomFields() {
                return this.customFields.filter(field => {
                    return (
                        !field.deleted_at &&
                        (field.id != this.currentField.id || field.tempId != this.currentField.tempId)
                    );
                });
            },

            textAreaOptions() {
                return [
                    {
                        value: 'line',
                        label: this.$t('accounts.edit.customFields.textAreaLine'),
                    },
                    {
                        value: 'box',
                        label: this.$t('accounts.edit.customFields.textAreaBox'),
                    },
                ];
            },

            typeOptions() {
                return [
                    {
                        value: 'string',
                        label: this.$t('accounts.edit.customFields.typeString'),
                    },
                    {
                        value: 'textarea',
                        label: this.$t('accounts.edit.customFields.typeTextArea'),
                    },
                    {
                        value: 'datetime',
                        label: this.$t('accounts.edit.customFields.typeDatetime'),
                    },
                    {
                        value: 'array',
                        label: this.$t('accounts.edit.customFields.typeArray'),
                    },
                    {
                        value: 'currency',
                        label: this.$t('accounts.edit.customFields.typeCurrency'),
                    },
                    {
                        value: 'boolean',
                        label: this.$t('accounts.edit.customFields.typeBoolean'),
                    },
                    {
                        value: 'url',
                        label: this.$t('accounts.edit.customFields.typeUrl'),
                    },
                ];
            },

            clientCardSectionOptions() {
                if (this.contextAccount.type == AccountType.CUSTOM) {
                    return ClientCardSection.selectOptions('value');
                }

                return ClientCardSection.selectOptions('value')
                    .map(section => {
                        const fieldIsInUse = this.currentField && this.currentField.customables_count > 0;
                        const currentFieldIsVehicle = [ClientCardSection.VEHICLE_WANTED, ClientCardSection.VEHICLE_EXCHANGE].includes(this.currentField?.section);
                        const isVehicleSection = [ClientCardSection.VEHICLE_WANTED, ClientCardSection.VEHICLE_EXCHANGE].includes(section.value);

                        return {
                            ...section,
                            disabled: fieldIsInUse && currentFieldIsVehicle !== isVehicleSection,
                        };
                    })
                    .filter(section => {
                        const isCustomSection = [ClientCardSection.FUNNEL, ClientCardSection.DOLLAR_AND_LOST].includes(section.value);
                        return !isCustomSection;
                    });
            },

            disabled() {
                if (empty(this.currentField.type) || !this.currentField.name) {
                    return true;
                }

                if (this.errorTitle) {
                    return true;
                }

                if (
                    this.currentField.type == 'array' &&
                    (!this.currentField.name ||
                        (this.multiSelectOption && this.multiSelectOption.length < 1) ||
                        this.multiSelectOption.length > this.maxOptions)
                ) {
                    return true;
                }

                if (
                    this.currentField.type == 'array' &&
                    this.currentField.select_picker_options &&
                    this.currentField.select_picker_options.length < 1
                ) {
                    return true;
                }

                if (
                    this.currentField.select_picker_options &&
                    this.currentField.select_picker_options.length > this.maxOptions
                ) {
                    return true;
                }

                return false;
            },

            showTextAreaOptions() {
                return this.currentField.type == 'textarea';
            },

            showInputOptions() {
                return this.currentField.type == 'array';
            },

            errorMaxOptions() {
                if (
                    this.currentField.select_picker_options &&
                    this.currentField.select_picker_options.length >= this.maxOptions
                ) {
                    return this.$t('accounts.edit.customFields.errors.maxOptions', [this.maxOptions]);
                }

                return '';
            },

            errorDuplicateOption() {
                if (this.multiSelectOption && this.checkDuplicatedOption(this.multiSelectOption)) {
                    return this.$t('accounts.edit.customFields.errors.duplicateOption');
                }

                return '';
            },

            errorTitle() {
                if (!this.currentField.name) {
                    return '';
                }

                const inputTitle = kebabCase(this.currentField.name);
                const alreadyExist = this.otherCustomFields.some(field => {
                    return kebabCase(field.name) === inputTitle;
                });

                if (alreadyExist) {
                    return this.$t('accounts.edit.customFields.errors.duplicateTitle');
                }

                return '';
            },

            disabledTypeFieldTooltip() {
                if (!this.disabledTypeField) {
                    return '';
                }

                return this.$t('accounts.edit.customFields.fieldTypeDisabled', [this.currentField.customables_count]);
            },

            disabledTypeField() {
                return this.currentField && this.currentField.customables_count > 0;
            },
        },

        watch: {
            'currentField.name'(newName) {
                if (!newName) {
                    return;
                }

                this.currentField.field = `custom_${kebabCase(this.currentField.name)}`;
            },

            field: {
                immediate: true,
                handler() {
                    this.currentField = cloneDeep(this.field);
                },
            },
        },

        methods: {
            updateSectionOption(option) {
                this.currentField.section = option.value;
            },

            close() {
                this.$emit('update:opened', false);
            },

            onClosed() {
                this.multiSelectOption = '';
                this.$emit('closed');
            },

            cancel() {
                this.currentField = this.field;
                this.close();
            },

            addOption() {
                if (
                    this.multiSelectOption &&
                    !this.checkDuplicatedOption(this.multiSelectOption.trim()) &&
                    this.currentField.select_picker_options.length < this.maxOptions
                ) {
                    this.currentField.select_picker_options.push(this.multiSelectOption.trim());
                    this.multiSelectOption = '';
                    this.$refs.input.focus();
                }
            },

            checkDuplicatedOption(option) {
                if (!this.currentField.select_picker_options.length) {
                    return false;
                }

                const lowercaseOptions = this.currentField.select_picker_options.map(item => item.toLowerCase());
                return lowercaseOptions.includes(option.toLowerCase());
            },

            deleteOption(index) {
                this.currentField.select_picker_options.splice(index, 1);
            },

            saveCustomField() {
                if (this.multiSelectOption) {
                    this.unsavedOption = true;
                } else {
                    this.$emit('update-custom-field', this.currentField);
                }
            },
        },
    };
</script>
