<template>
    <div class="box | mb-6">
        <div class="box-header | border-b">
            <h4 class="box-title">
                {{ $t('clientCard.services.soldServices') }}
            </h4>
        </div>
        <div class="box-body">
            <div class="row">
                <div class="col-xs-12 col-lg-6" :key="index" v-for="(products, index) in chunkedProducts">
                    <service-settings-product
                        :product-id="product.product_id"
                        :key="product.product_id"
                        v-for="product in products"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { chunk } from 'lodash-es';
    import { mapState } from 'pinia';
    import { useAccountCardStore } from '../../store/modules/accountCard/store.js';
    import ServiceSettingsProduct from './ServiceSettingsProduct.vue';

    export default {
        components: { ServiceSettingsProduct },

        computed: {
            ...mapState(useAccountCardStore, {
                currentForm: 'currentForm',
                serviceProducts: store => store.currentForm.service_products,
            }),

            chunkedProducts() {
                return chunk(this.serviceProducts, Math.ceil(this.serviceProducts.length / 2));
            },

            hasActiveProducts() {
                return this.serviceProducts.some(product => product.visible);
            },
        },

        watch: {
            service(newValue) {
                if (newValue && !this.$wait.is('fetching.account')) {
                    this.activateBaseServiceProducts();
                }
            },
        },

        methods: {
            activateBaseServiceProducts() {
                if (this.hasActiveProducts) {
                    const products = this.serviceProducts.map(product => {
                        return { ...product, visible: true };
                    });

                    useAccountCardStore().currentForm.service_products = products;
                }
            },
        },
    };
</script>
