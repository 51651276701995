<template>
    <activix-modal scrollable :opened="opened" @close="closeModal">
        <template slot="header">
            <h4 class="modal-title" v-text="$t('accounts.edit.syncChanges')" />
        </template>

        <template slot="body">
            <div class="flex flex-col items-center space-y-3 py-2" v-if="hasAccountChanges">
                <div class="text-xl font-medium" v-text="$t('accounts.edit.copyModifs')" />

                <div class="w-2/3">
                    <activix-multiselect
                        multiple="multiple"
                        :options="accounts"
                        :searchable="false"
                        :selected="accountSelected"
                        v-model="accountSelected"
                    />
                </div>
            </div>

            <hr v-if="hasAccountChanges && hasAccountAttached" />

            <div class="flex flex-col items-center space-y-3 py-2" v-if="hasAccountAttached">
                <div class="text-xl font-medium" v-text="$t('accounts.edit.createChildModal')" />

                <div class="w-2/3">
                    <activix-multiselect
                        multiple="multiple"
                        :options="childUsers"
                        :searchable="false"
                        :selected="userSelected"
                        v-model="userSelected"
                    />
                </div>
            </div>
        </template>

        <template slot="footer">
            <div class="flex items-center justify-end space-x-2">
                <activix-button @click="closeModal" v-text="$t('users.edit.ignore')" />

                <activix-tooltip>
                    <activix-button
                        :loading="loading"
                        type="primary"
                        :disabled="hasSelectedLength"
                        @click="update"
                    >
                        {{ $t('users.edit.confirm') }}
                    </activix-button>
                </activix-tooltip>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import { mapState } from 'pinia';
    import axios from '@/plugins/axios.js';
    import { useAccountCardStore } from '@/store/modules/accountCard/store.js';
    import { useContextStore } from '@/store/modules/context/store.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                default: false,
            },
            accountChanges: {
                type: Object,
                default: () => {},
            },
            accountAttached: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                userSelected: [],
                accountSelected: [],
                loading: false,
            };
        },

        computed: {
            ...mapState(useAccountCardStore, ['currentForm']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            hasAccountChanges() {
                return Object.keys(this.accountChanges).length;
            },

            hasAccountAttached() {
                return this.accountAttached.length;
            },

            hasSelectedLength() {
                return this.userSelected.length + this.accountSelected.length === 0;
            },

            childUsers() {
                return this.contextAccount.users.map(child => {
                    return { label: `${child.first_name} ${child.last_name}`, id: child.id };
                });
            },

            accounts() {
                return this.currentForm.children.map(child => {
                    return { label: child.name, id: child.id };
                });
            },
        },

        methods: {
            async update() {
                this.loading = true;

                if (this.userSelected.length) {
                    await axios.post('v1/users/child', {
                        attached_ids: this.accountAttached,
                        parent_user_ids: this.userSelected.map(user => user.id),
                    });
                }

                if (this.accountSelected.length) {
                    await axios.put('v1/accounts/child', {
                        modifications: this.accountChanges,
                        account_ids: this.accountSelected.map(account => account.id),
                    });
                }

                this.closeModal();
            },

            closeModal() {
                this.userSelected = [];
                this.accountSelected = [];
                this.loading = false;

                this.$emit('closed');
            },
        },
    };
</script>
